import { FormattedMessage } from 'react-intl';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { SEARCH_STATE_PARAMS } from '@xing-com/crate-jobs-constants';
import { DeleteTag } from '@xing-com/tag';

export const CompanyTag: React.FC = () => {
  const { deleteSearchParameter } = useSearchParameter();

  const handleOnClickCompany = (): void => {
    deleteSearchParameter(SEARCH_STATE_PARAMS.company, { replace: false });
  };

  return (
    <DeleteTag
      onClick={handleOnClickCompany}
      onDelete={handleOnClickCompany}
      selected
    >
      <FormattedMessage id="JOBS_SEARCH_COMPANY_CULTURE_FILTER_VALUE" />
    </DeleteTag>
  );
};
