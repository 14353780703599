import { useLocation } from '@reach/router';
import type React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import {
  ANY,
  CITY,
  EMPLOYMENT_TYPE,
  ROLE,
  TRANSLATIONS_MAP,
} from '@xing-com/crate-jobs-domain-serps-constants';
import {
  capitalizeFirstLetter,
  getSeoSerpsMetaDescriptionValues,
  getCategoryTranslation,
  getSeoSerpsTitleTag,
  getSerpsRouteData,
} from '@xing-com/crate-jobs-domain-serps-helpers';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';
import { useHost } from '@xing-com/crate-xinglet';

const getTitleOverride = (values: Record<string, string>): string => {
  return `${
    values.employmentType === 'Studierende'
      ? 'Studentenjobs'
      : 'Freelancer Jobs'
  } in ${values.city} - ${values.month} ${values.year}`;
};

const getDescriptionOverride = (values: Record<string, string>): string =>
  `${values.postings} ${
    values.employmentType === 'Studierende'
      ? 'Studentenjobs'
      : 'Freelancer Jobs'
  } in ${values.city} im XING Jobs-Netzwerk. Finde den Job, der zu Dir passt.`;

interface SerpsMetadataProps extends SerpsRouteParams {
  jobCount?: number;
}

export const SerpsMetadata: React.FC<SerpsMetadataProps> = ({
  category,
  subcategory,
  categoryValue,
  subcategoryValue,
  jobCount = 0,
}: SerpsMetadataProps) => {
  const { pathname } = useLocation();
  const { path } = getSerpsRouteData(pathname) || {};

  const { getHostname } = useHost();
  const hostname = getHostname();

  const { formatMessage, messages, formatDate, locale } = useIntl();

  const isSerpsMetadataToEdit = [ANY, CITY, EMPLOYMENT_TYPE, ROLE].includes(
    category
  );
  const hasSubcategory = subcategory && subcategoryValue;

  const isEmploymentTypeSingleCategoryFreiberuflich =
    !hasSubcategory &&
    category === EMPLOYMENT_TYPE &&
    categoryValue === 'freiberuflich';

  const {
    metaTitle,
    metaDescription: description,
    paramName,
    subparamName,
  } = TRANSLATIONS_MAP[
    hasSubcategory ? `${category}-${subcategory}` : category
  ];

  const capitalizedCategoryTranslation = capitalizeFirstLetter(
    getCategoryTranslation(formatMessage, category, categoryValue)
  );
  const values: Record<string, string> = {
    [paramName]: capitalizedCategoryTranslation,
    ...(hasSubcategory &&
      subparamName && {
        [subparamName]: capitalizeFirstLetter(
          getCategoryTranslation(formatMessage, subcategory, subcategoryValue)
        ),
      }),
    ...getSeoSerpsTitleTag(
      category,
      capitalizedCategoryTranslation,
      subcategory,
      hasSubcategory
        ? getCategoryTranslation(formatMessage, subcategory, subcategoryValue)
        : subcategory
    ),
    category: isEmploymentTypeSingleCategoryFreiberuflich
      ? formatMessage({ id: 'EMPLOYMENT_TYPE_CONTRACTOR_LOCAL_SERPS' })
      : capitalizedCategoryTranslation,
    postings: jobCount.toString(),
    date: formatDate(new Date(), { year: 'numeric', month: 'short' }),
    month: formatDate(new Date(), { month: 'short' }),
    year: formatDate(new Date(), { year: 'numeric' }),
  };

  // We also have the need to check for specific description values for the given
  // category and subcategory. Such combinations have specific descriptions for the SEO
  const specificSubcategory = subcategoryValue
    ? `${subcategoryValue.toUpperCase()}_`
    : '';
  const specificValue = `${categoryValue.toUpperCase()}_${specificSubcategory}`;
  // Cast into string so Head's description does not complain
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const specificDescription = messages[
    `XJM_SEO_FOOTER_TEXT_${specificValue}DESCRIPTION`
  ] as string | undefined;

  // to conditionally render a new description if the page is the local serps
  const metaDescription = isSerpsMetadataToEdit
    ? formatMessage(
        { id: description },
        getSeoSerpsMetaDescriptionValues(
          category,
          getCategoryTranslation(formatMessage, category, categoryValue),
          jobCount,
          subcategory,
          hasSubcategory
            ? getCategoryTranslation(
                formatMessage,
                subcategory,
                subcategoryValue
              )
            : subcategory
        )
      )
    : (specificDescription ?? formatMessage({ id: description }, values));

  // as this is just for german and just for two pages,
  // we change it just here, so we don't have to refactor everything just for a specific scenario
  let titleOverride = '';
  let descriptionOverride = '';

  const isEmploymentTypeCitySerp =
    metaTitle === 'XJM_META_TITLE_EMPLOYMENT_TYPE_CITY_WITH_DATE';
  const isEmploymentTypeAushilfeSerp =
    metaTitle === 'XJM_META_TITLE_JOB_TYPE_TERM';

  const isStudentOrFreelancerEmploymentType = [
    'Studierende',
    'Selbstständig',
  ].includes(values.employmentType);
  const isAushilfeEmploymentType = ['Aushilfe'].includes(values.employmentType);
  const isSkillSerp = metaTitle === 'XJM_META_TITLE_SKILL';

  if (
    isEmploymentTypeCitySerp &&
    isStudentOrFreelancerEmploymentType &&
    locale === 'de'
  ) {
    titleOverride = getTitleOverride(values);
    descriptionOverride = getDescriptionOverride(values);
  }

  if (
    isEmploymentTypeAushilfeSerp &&
    isAushilfeEmploymentType &&
    locale === 'de'
  ) {
    titleOverride = formatMessage(
      { id: 'XJM_META_TITLE_JOB_TYPE_TERM_MINIJOBS' },
      values
    );
    descriptionOverride = formatMessage(
      { id: 'XJM_META_DESCRIPTION_JOB_TYPE_TERM_MINIJOBS' },
      values
    );
  }

  if (isSkillSerp && locale === 'de') {
    titleOverride = `Aktuelle ${capitalizeFirstLetter(values.term)} Jobs - ${
      values.date
    }`;
  }

  const serpsTitle = titleOverride
    ? titleOverride
    : formatMessage({ id: metaTitle }, values);
  const serpsDescription = descriptionOverride
    ? descriptionOverride
    : metaDescription;

  let seoUrl = (path ?? '').replace(':categoryValue', categoryValue);
  if (subcategoryValue) {
    seoUrl = seoUrl.replace(':subcategoryValue', subcategoryValue);
  }

  return (
    <>
      <Helmet>
        <title>{serpsTitle}</title>
        <meta name="title" content={serpsTitle} />
        <meta name="description" content={serpsDescription} />
        <meta name="theme-color" content="#C6F16D" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://${hostname}${seoUrl}`} />
      </Helmet>
    </>
  );
};
