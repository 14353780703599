import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceM, spaceS } from '@xing-com/tokens';

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceS};

  @media ${mediaWideNavless} {
    gap: ${spaceM} ${spaceS};
  }
`;

export const SliderWrapper = styled.div`
  margin-left: ${spaceM};
  margin-right: ${spaceM};
`;
