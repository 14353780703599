import type { JobItemResultVisibleJob } from '../../helpers';

const FIRST_BANNER_INDEX = 2;
const SECOND_BANNER_INDEX = 4;

export type ItemWithBanners =
  | JobItemResultVisibleJob
  | 'ProJobsUpsellBanner'
  | 'AISearchFeedbackBanner'
  | 'Ad';

export const getItemsWithBanner = (
  jobs: JobItemResultVisibleJob[],
  {
    projobs,
    aiSearch,
    adExperiment,
  }: { projobs?: boolean; aiSearch?: boolean; adExperiment?: string } = {}
): ItemWithBanners[] => {
  let itemsWithBanner: ItemWithBanners[] = [...jobs];

  const firstPart = itemsWithBanner.slice(0, FIRST_BANNER_INDEX);
  const secondPart = itemsWithBanner.slice(
    FIRST_BANNER_INDEX,
    SECOND_BANNER_INDEX
  );
  const lastPart = itemsWithBanner.slice(SECOND_BANNER_INDEX);
  const projobsBanner = projobs ? ['ProJobsUpsellBanner' as const] : [];
  const aiSearchBanner = aiSearch ? ['AISearchFeedbackBanner' as const] : [];
  const ad = ['Ad' as const];

  if (adExperiment === 'A') {
    itemsWithBanner = [
      ...firstPart,
      ...projobsBanner,
      ...secondPart,
      ...aiSearchBanner,
      ...lastPart,
    ];
  } else if (adExperiment === 'B') {
    itemsWithBanner = [
      ...firstPart,
      ...projobsBanner,
      ...secondPart,
      ...ad,
      ...aiSearchBanner,
      ...lastPart,
    ];
  } else if (adExperiment === 'C') {
    itemsWithBanner = [
      ...firstPart,
      ...ad,
      ...secondPart,
      ...projobsBanner,
      ...aiSearchBanner,
      ...lastPart,
    ];
  }

  return itemsWithBanner;
};
