import { FormattedMessage } from 'react-intl';

import { useSearchBar } from '@xing-com/crate-jobs-hooks';
import { useExperiment } from '@xing-com/hub';
import { IconAIFilled } from '@xing-com/icons';
import { Marker } from '@xing-com/marker';

import * as S from './conversational-search-bar.styles';
import { SearchBar } from './search-bar';
import { cachedSuggestionQuery } from './suggestions';

type Props = { isLanding?: boolean };

export const ConversationalSearchBar: React.FC<Props> = ({ isLanding }) => {
  const { keywords, location, onSubmit, ...result } = useSearchBar({
    isAiLanding: isLanding,
  });

  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  const handleOnSubmit = (): void => {
    onSubmit({ keywords: cachedSuggestionQuery() ?? undefined });
    // Clear the variable, so that on going back from the browser, the cache is
    // cleared
    cachedSuggestionQuery(null);
  };

  return (
    <S.Container>
      <S.FlagsContainer $isLanding={isLanding}>
        <Marker icon={IconAIFilled} variant="ai">
          <FormattedMessage id="JOBS_SEARCH_AI_FLAG" />
        </Marker>
        {isAiSearchForced ? null : <Marker variant="onColor">Beta</Marker>}
      </S.FlagsContainer>
      <S.Title $isLanding={isLanding}>
        <FormattedMessage id="JOBS_SEARCH_AI_HEADLINE" />
        <S.Icon $isLanding={isLanding} />
      </S.Title>
      <SearchBar
        keywordsInput={keywords}
        locationInput={location}
        onSubmit={handleOnSubmit}
        {...result}
      />
    </S.Container>
  );
};
