import { FormattedMessage } from 'react-intl';

import { IconArrowDown, IconArrowRight, IconArrowUp } from '@xing-com/icons';

import * as S from './filter-set.styles';

type Props = {
  isExpanded?: boolean;
  onClick: () => void;
};

export const ShowMoreButton: React.FC<Props> = ({ isExpanded, onClick }) => {
  return (
    <>
      <S.ShowMoreButtonSmall
        icon={IconArrowRight}
        iconAfter
        size="small"
        fontWeight="regular"
        onClick={onClick}
      >
        <FormattedMessage id="JOBS_SEARCH_FILTERS_SHOW_ALL" />
      </S.ShowMoreButtonSmall>
      <S.ShowMoreButtonConfined
        icon={isExpanded ? IconArrowUp : IconArrowDown}
        iconAfter
        size="small"
        fontWeight="regular"
        onClick={onClick}
      >
        <FormattedMessage
          id={
            isExpanded
              ? 'JOBS_SEARCH_FILTERS_SHOW_LESS'
              : 'JOBS_SEARCH_FILTERS_SHOW_MORE'
          }
        />
      </S.ShowMoreButtonConfined>
    </>
  );
};
