import { FormattedMessage } from 'react-intl';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { SMALL_SCREEN_BOTTOM_SHEET_PARAM } from '@xing-com/crate-jobs-constants';
import { FILTER_TYPENAME_TO_PARAM } from '@xing-com/crate-jobs-domain-search-maps';
import type {
  FilterPropertyName,
  FilterResult,
} from '@xing-com/crate-jobs-domain-search-types';
import type { ExtractedFiltersEnabledFragment } from '@xing-com/crate-jobs-graphql';
import { useSearchState } from '@xing-com/crate-jobs-hooks';
import { Tag } from '@xing-com/tag';

import { useSearch } from '../../../hooks';

const hasExtractedFilters = (
  extractedFilters: (ExtractedFiltersEnabledFragment | null)[],
  filter: 'salary' | FilterResult
): boolean => {
  return extractedFilters.some((extractedFilter) => {
    if (filter === 'salary') {
      return (
        extractedFilter?.__typename === 'JobFilterSalary' &&
        (typeof extractedFilter.min === 'number' ||
          typeof extractedFilter.max === 'number')
      );
    }

    return (
      extractedFilter?.__typename &&
      'entityId' in extractedFilter &&
      FILTER_TYPENAME_TO_PARAM[extractedFilter.__typename] === filter?.paramName
    );
  });
};

type Props = {
  filter: FilterResult | 'salary';
  trackClick: (name: string) => void;
};

export const FilterBarItem: React.FC<Props> = ({ filter, trackClick }) => {
  const { searchQuery } = useSearchState();
  const { extractedFilters } = useSearch();
  const { setSearchParameter } = useSearchParameter();

  const isExtractedFilterSelected = hasExtractedFilters(
    extractedFilters,
    filter
  );

  const handleOnFilterItemClick =
    (view: FilterPropertyName | 'salary') => () => {
      setSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM, view);
      trackClick(view);
    };

  if (!filter) {
    return null;
  }

  if (filter === 'salary') {
    return (
      <Tag
        hideBorder
        selected={!!searchQuery.filter?.salary || isExtractedFilterSelected}
        onClick={handleOnFilterItemClick('salary')}
        data-testid="filter-bar-filter"
      >
        <FormattedMessage id="JOBS_SEARCH_FILTER_SALARY" />
      </Tag>
    );
  }

  const isSelected =
    searchQuery.filter?.[filter.paramName]?.id.length ||
    isExtractedFilterSelected;

  return (
    <Tag
      hideBorder
      selected={!!isSelected}
      onClick={handleOnFilterItemClick(filter.propertyName)}
      data-testid="filter-bar-filter"
      disabled={!filter.options}
    >
      <FormattedMessage id={filter.titleKey} />
    </Tag>
  );
};
