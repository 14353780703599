import { FormattedCurrency } from '@xing-com/crate-jobs-components-salary';

type Props = {
  min: number;
  max: number;
};

export const SalaryValue: React.FC<Props> = ({ min, max }) => (
  <>
    <FormattedCurrency value={min} currency="EUR" />
    {' – '}
    <FormattedCurrency value={max} currency="EUR" />
  </>
);
