import type {
  CreateSearchAlertMutation,
  DeleteSearchAlertMutation,
} from '@xing-com/crate-jobs-graphql';

import { useCreateSearchAlert } from './use-create-search-alert';
import { useCurrentSearchAlertStatus } from './use-current-search-alert-id';
import { useDeleteSearchAlert } from './use-delete-search-alert';
import { useEditSearchAlert } from './use-edit-search-alert';
import { useSearchAlerts } from './use-search-alerts';
import { useSearchQueryId } from './use-search-query-id';
import { useShowSearchAlertErrorBanner } from './use-show-search-alert-error-banner';

type OnToggleAlertParams = {
  onCreateCompleted?: (data: CreateSearchAlertMutation) => void;
  onDeleteCompleted?: (data: DeleteSearchAlertMutation) => void;
  PropTrackActionData: Record<string, string>;
};

type UseToggleSearchAlertResult = {
  isAlertActive: boolean;
  isLoading: boolean;
  isEditMode: boolean;
  onToggleAlert: (params?: OnToggleAlertParams) => void;
};

export const useToggleSearchAlert = (): UseToggleSearchAlertResult => {
  const { loading: isLoading } = useSearchAlerts();
  const createSearchAlert = useCreateSearchAlert({
    onError: useShowSearchAlertErrorBanner('CREATE'),
  });
  const deleteSearchAlert = useDeleteSearchAlert({
    onError: useShowSearchAlertErrorBanner('DELETE'),
  });
  const searchQueryId = useSearchQueryId();
  const { searchAlertId: maybeSearchAlertId } = useCurrentSearchAlertStatus();
  const {
    alertId: editAlertId,
    isEditMode,
    onQuitEditMode,
    trackSave,
  } = useEditSearchAlert();

  const onToggleAlert: UseToggleSearchAlertResult['onToggleAlert'] = (
    params
  ) => {
    const { PropTrackActionData = {} } = params ?? {};
    const { onCreateCompleted, onDeleteCompleted } = params ?? {};

    const PropTrackAction = maybeSearchAlertId
      ? 'jobs_delete_search_alert_completed'
      : isEditMode
        ? 'jobs_search_alert_edit_confirm'
        : 'jobs_search_alert_create_confirm';

    const propTrackActionData = PropTrackActionData
      ? {
          PropTrackAction,
          ...PropTrackActionData,
        }
      : undefined;

    if (maybeSearchAlertId) {
      deleteSearchAlert({
        alertId: maybeSearchAlertId,
        onCompleted: onDeleteCompleted,
        propTrackActionData,
      });

      if (isEditMode) {
        onQuitEditMode();
      }
    } else if (searchQueryId) {
      createSearchAlert({
        queryId: searchQueryId,
        propTrackActionData,
        onCompleted: (data) => {
          if (
            editAlertId &&
            data.createSearchAlert?.__typename === 'SearchAlertCreationSuccess'
          ) {
            deleteSearchAlert({
              alertId: editAlertId,
              onCompleted: onDeleteCompleted,
            });
            onQuitEditMode();
          }
          onCreateCompleted?.(data);
        },
      });

      if (editAlertId) {
        trackSave();
      }
    }
  };

  return {
    isAlertActive: !!maybeSearchAlertId,
    isLoading,
    isEditMode,
    onToggleAlert,
  };
};
