/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationStickerRocket = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationStickerRocket"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="var(--xdlColorIllustrationBackground)"
        d="m62.235 11.866 1.825-8.7-8.7 1.824-1.307.272-4.492.941a29.7 29.7 0 0 0-10.748 4.598l-2.871-1.595a1.35 1.35 0 0 1-.639-.812L33.301.941l-2.006 7.457c-.09.336-.329.642-.638.812l-3.272 1.817 3.272 1.818c.31.17.547.475.638.812l.355 1.318-.581.582-1.036-1.035-6.547 6.547 1.036 1.035-.386.385a19.446 19.446 0 0 0-13.868 3.087l-3.763 2.523 5.255 5.255a6.77 6.77 0 0 0 4.817 1.995 7 7 0 0 0 1.364-.136q.135.289.306.589c-.287.026-.575.06-.862.11-6.856 1.178-11.968 8.553-12.183 8.866l-2.637 3.86s4.719-.637 4.764-.645l-4 4.397 6.218.238-5.826 11.05 11.84-7.03 1.092 10.29 4.397-7.393.106 1.153 3.056-2.61c2.61-2.23 5.172-6.015 5.795-10.035.657.458 1.31.866 1.938 1.187a7 7 0 0 0-.132 1.34c0 1.822.706 3.533 1.994 4.817l5.255 5.255 2.524-3.762a19.43 19.43 0 0 0 3.109-13.706l.793.442a3.43 3.43 0 0 1 1.636 2.085l2.006 7.454 2.006-7.45a3.43 3.43 0 0 1 1.636-2.09l3.268-1.816-3.268-1.817a3.43 3.43 0 0 1-1.636-2.086l-.744-2.758 2.55-2.55a29.66 29.66 0 0 0 8.08-14.93l.934-4.442z"
      />
      <path
        fill="#FE813C"
        d="M51.76 41.744a5.49 5.49 0 0 0-2.634 3.363 5.48 5.48 0 0 0-2.633-3.363 5.49 5.49 0 0 0 2.633-3.362 5.49 5.49 0 0 0 2.633 3.362M34.937 10.967a3.4 3.4 0 0 0-1.636 2.089 3.4 3.4 0 0 0-1.636-2.09 3.41 3.41 0 0 0 1.636-2.088 3.4 3.4 0 0 0 1.636 2.089"
      />
      <path
        fill="#00D296"
        fillRule="evenodd"
        d="M19.339 32.455 36.1 15.693c3.8-3.8 8.625-6.411 13.888-7.514L61.37 5.79l-2.387 11.383a27.66 27.66 0 0 1-7.514 13.887L34.71 47.827M26.732 25.013l-2.35-.442a17.49 17.49 0 0 0-12.958 2.663l-1.666 1.118 3.468 3.468a4.733 4.733 0 0 0 6.698 0z"
        clipRule="evenodd"
      />
      <path
        fill="#00D296"
        fillRule="evenodd"
        d="m42.08 40.362.443 2.35a17.49 17.49 0 0 1-2.664 12.957l-1.118 1.666-3.468-3.468a4.733 4.733 0 0 1 0-6.698z"
        clipRule="evenodd"
      />
      <path
        fill="#FE813C"
        d="M24.582 39.614c6.811 4.968 2.236 13.445-1.726 16.834l-.322-3.533-4.472 7.53-.775-7.3L9 58.069l3.929-7.446-5.066-.196 3.713-4.095s-1.537-.87-4.662-.446c0 0 8.742-12.784 17.669-6.271"
      />
      <path
        fill="#C6F16D"
        d="M26.418 43.531c.725 3.97-3.313 6.853-3.313 6.853l.423-3.51-2.935 3.918.03-3.29-6.668 4.884 4.805-8.424-4.805 1.738s3.517-8.814 8.885-6.117c2.263 1.138 3.332 2.615 3.578 3.948"
      />
      <path
        fill="#1D2124"
        d="M48.945 22.372a4.152 4.152 0 1 0 0-8.304 4.152 4.152 0 0 0 0 8.304"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="M44.585 22.583a6.13 6.13 0 0 0 4.36 1.806 6.13 6.13 0 0 0 4.363-1.81 6.13 6.13 0 0 0 1.806-4.359 6.13 6.13 0 0 0-1.806-4.36 6.17 6.17 0 0 0-8.723 0c-2.402 2.407-2.402 6.32 0 8.723m.265-8.454a5.78 5.78 0 0 1 4.095-1.693c1.48 0 2.965.563 4.095 1.689a5.75 5.75 0 0 1 1.696 4.095 5.76 5.76 0 0 1-1.696 4.095 5.75 5.75 0 0 1-4.095 1.696 5.76 5.76 0 0 1-4.095-1.696 5.8 5.8 0 0 1 0-8.186M18.341 44.313l-4.99 8.75 7.075-5.188-.03 3.491 2.86-3.812-.39 3.234.348-.25c.05-.035 4.125-2.999 3.389-7.037-.166-.918-.869-2.671-3.68-4.084-1.231-.616-2.466-.699-3.68-.238-3.369 1.277-5.382 6.24-5.466 6.452l-.154.39zm-3.785 7.397 4.62-8.1-4.866 1.76c.552-1.208 2.399-4.827 5.074-5.843 1.107-.42 2.24-.344 3.373.222 2.667 1.338 3.325 2.966 3.48 3.816.566 3.113-1.938 5.572-2.887 6.38l.45-3.75-3.015 4.019.027-3.09z"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="m42.297 40.504 9.307-9.307a27.77 27.77 0 0 0 7.567-13.982L61.615 5.55 49.95 7.994a27.8 27.8 0 0 0-13.982 7.566l-9.259 9.26-2.297-.43a17.57 17.57 0 0 0-13.098 2.693L9.46 28.326l3.63 3.63a4.9 4.9 0 0 0 3.483 1.44c.9 0 1.8-.245 2.59-.734-.18.852 1.057 2.959 3.149 5.485a8.6 8.6 0 0 0-4.613-.44c-6.079 1.046-10.895 7.997-10.944 8.068l-.238.352.423-.057c2.357-.317 3.8.117 4.321.325L7.45 50.6l5.171.2-4.098 7.775 8.61-5.116.8 7.571 4.462-7.514.302 3.313.28-.238c2.655-2.266 5.436-6.603 5.277-10.759a8.2 8.2 0 0 0-.287-1.877c2.862 2.522 5.359 4.118 6.41 4.06a4.94 4.94 0 0 0 .76 5.985l3.63 3.63 1.243-1.858a17.57 17.57 0 0 0 2.694-13.098zm-7.394 7.394a4.556 4.556 0 0 0 .502 5.833l3.306 3.306.99-1.473a17.2 17.2 0 0 0 2.637-12.818l-.363-1.92zm-.01-.603q.117-.135.244-.262l7.06-7.06.016.079 9.123-9.124a27.4 27.4 0 0 0 7.465-13.792l.98-4.674A67.445 67.445 0 0 0 54.7 7.38l-4.676.98a27.4 27.4 0 0 0-13.792 7.465l-16.43 16.438c1.441.089 5.036 2.672 8.73 6.366 3.64 3.64 6.201 7.184 6.36 8.666m22.68-37.707c-.797-.8-1.61-1.573-2.416-2.304l5.971-1.251-1.252 5.975a67 67 0 0 0-2.303-2.42m-47.52 18.794 3.305 3.306a4.56 4.56 0 0 0 6.434 0l6.554-6.555-1.998-.374a17.2 17.2 0 0 0-12.818 2.637zm7.393 24.45.744 7.027 4.484-7.54.34 3.74c2.501-2.267 5.017-6.324 4.866-10.212-.095-2.455-1.243-4.5-3.408-6.082-2.089-1.526-4.344-2.09-6.705-1.685-5.15.884-9.396 6.158-10.45 7.567 2.864-.298 4.288.484 4.349.518l.204.117-3.6 3.97 4.96.193-3.755 7.117zM33.301 13.781l-.181-.676a3.24 3.24 0 0 0-1.545-1.972l-.299-.166.299-.166a3.25 3.25 0 0 0 1.545-1.972l.181-.677.181.677a3.2 3.2 0 0 0 1.546 1.972l.298.166-.298.166a3.25 3.25 0 0 0-1.546 1.972zm-1.277-2.814c.56.378 1.001.91 1.277 1.526a3.63 3.63 0 0 1 1.277-1.526A3.64 3.64 0 0 1 33.3 9.44a3.66 3.66 0 0 1-1.277 1.526"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        fillRule="evenodd"
        d="m49.126 45.832-.181-.676a5.31 5.31 0 0 0-2.543-3.245l-.298-.167.298-.166a5.32 5.32 0 0 0 2.543-3.245l.181-.676.181.676a5.32 5.32 0 0 0 2.543 3.245l.298.166-.298.166a5.32 5.32 0 0 0-2.543 3.246zm-2.263-4.088a5.7 5.7 0 0 1 2.263 2.758 5.74 5.74 0 0 1 2.263-2.758 5.7 5.7 0 0 1-2.263-2.754 5.7 5.7 0 0 1-2.263 2.754"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        d="m25.945 23.29 8.118-8.119.267.268-8.118 8.118zM30.035 17.13l-3.88 3.879.268.267 3.879-3.879z"
      />
    </svg>
  );
};
export default IllustrationStickerRocket;
