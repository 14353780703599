import { useLocation } from '@reach/router';
import { useEffect } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { EDIT_SEARCH_ALERT_PARAM } from '@xing-com/crate-jobs-constants';
import {
  getNwtCleanQuery,
  useJobsNwtTracking,
} from '@xing-com/crate-jobs-tracking';

type UseEditAlertResult = {
  alertId: string | null;
  isEditMode: boolean;
  onQuitEditMode: () => void;
  trackSave: () => void;
};

export const useEditSearchAlert = (): UseEditAlertResult => {
  const { isLoggedIn } = useLoginState();
  const { getSearchParameter, deleteSearchParameter } = useSearchParameter();
  const editAlert = getSearchParameter(EDIT_SEARCH_ALERT_PARAM);
  const location = useLocation();
  const nwtTrack = useJobsNwtTracking();

  const onQuitEditMode = (): void => {
    deleteSearchParameter(EDIT_SEARCH_ALERT_PARAM);
  };

  const trackSave = (): void => {
    const query = new URLSearchParams(location.search);

    nwtTrack({
      eventSchema: 'basic',
      event: 'clicked',
      page: 'jobs_search/serp',
      element: 'jobs_search_alert_edit_confirm',
      query: getNwtCleanQuery(query),
    });
  };

  useEffect(() => {
    const onPopState = (): void => {
      if (getSearchParameter(EDIT_SEARCH_ALERT_PARAM)) {
        deleteSearchParameter(EDIT_SEARCH_ALERT_PARAM);
      }
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [deleteSearchParameter, getSearchParameter]);

  return {
    alertId: editAlert,
    isEditMode: !!editAlert && isLoggedIn,
    onQuitEditMode,
    trackSave,
  };
};
