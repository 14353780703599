import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton } from '@xing-com/button';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { LGapCol, XxlGapCol } from '@xing-com/crate-jobs-components-columns';
import { SMALL_SCREEN_BOTTOM_SHEET_PARAM } from '@xing-com/crate-jobs-constants';
import { PROPERTY_NAME_TO_PARAM } from '@xing-com/crate-jobs-domain-search-maps';
import type {
  FilterPropertyName,
  FilterResult,
} from '@xing-com/crate-jobs-domain-search-types';
import { useFilterSet } from '@xing-com/crate-jobs-hooks';
import { useSearchTracking } from '@xing-com/crate-jobs-tracking';
import { IconArrowLeft } from '@xing-com/icons';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { motionTimeNumberL } from '@xing-com/tokens';

import { useSearch } from '../../../hooks';
import { FilterItem } from '../filter-item';
import * as SS from '../shared.styles';
import * as S from './filter-set.styles';
import { ShowMoreButton } from './show-more-button';
import { FilterSetSkeleton } from './skeletons';
import { useExtractedFiltersSet } from './use-extracted-filters-set';

const MIN_DISPLAYED_FILTERS = 3;

const findFilter =
  (propertyName: FilterPropertyName) =>
  (filter: 'salary' | FilterResult): filter is FilterResult =>
    filter !== 'salary' && filter.propertyName === propertyName;

type Props = {
  filter?: FilterResult;
  propertyName: FilterPropertyName;
  isSerps?: boolean;
  loading?: boolean;
  isAllFilters?: boolean;
  isSecondLevel?: boolean;
};

export const FilterSet: React.FC<Props> = ({
  filter,
  propertyName,
  loading,
  isAllFilters,
  isSecondLevel,
  isSerps = false,
}) => {
  const { formatMessage } = useIntl();
  const paramName = PROPERTY_NAME_TO_PARAM[propertyName];
  const { value: activeOptions, onToggleOption } = useFilterSet(paramName);
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    aggregations: { filters },
  } = useSearch();
  const { extractedFilters, onDelete } = useExtractedFiltersSet(propertyName);
  const { setSearchParameter } = useSearchParameter();
  const { trackFilterOpen } = useSearchTracking();
  const isMediaWideNavless = useMatchMedia(mediaWideNavless);

  // tag component doesn't support a dynamic maxWidth, so we calculate the container width as its value
  const tagsContainerRef = useRef<HTMLDivElement>(null);
  const tagsContainerWidth = tagsContainerRef.current?.clientWidth;
  const tagMaxWidth = tagsContainerWidth
    ? `${tagsContainerWidth}px`
    : undefined;

  const displayedFilter = filter ?? filters.find(findFilter(propertyName));

  const { options, titleKey } = displayedFilter || {};

  const isDisciplineSet = paramName === 'discipline';
  const isIndustrySet = paramName === 'industry';
  const asCheckbox = isDisciplineSet || isIndustrySet;

  const [animationContainer] = useAutoAnimate({
    duration: Number(motionTimeNumberL),
  });

  if (loading) {
    return <FilterSetSkeleton asCheckbox={asCheckbox} />;
  }

  if (!options) {
    // Should never happen, but simplifies types
    return null;
  }

  const shouldDisplayAllOptions = isExpanded || !asCheckbox || !isAllFilters;
  const shouldRenderMoreButton =
    options.length > MIN_DISPLAYED_FILTERS && isAllFilters;

  const openSecondLevel = (): void => {
    setSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM, `all-${paramName}`);
  };
  const closeSecondLevel = (): void => {
    setSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM, 'all');

    trackFilterOpen('all_filters', paramName);
  };

  const handleOnMoreClick = (): void => {
    if (isMediaWideNavless) {
      setIsExpanded((prev) => !prev);
    } else {
      openSecondLevel();
    }

    trackFilterOpen(paramName, 'all_filters');
  };

  const renderedOptions = options
    ?.slice(0, shouldDisplayAllOptions ? undefined : MIN_DISPLAYED_FILTERS)
    .map((option) =>
      option ? (
        <FilterItem
          key={option.id}
          asCheckbox={asCheckbox}
          paramName={paramName}
          option={option}
          active={
            activeOptions.has(option.id) || extractedFilters.has(option.id)
          }
          onClick={extractedFilters.has(option.id) ? onDelete : onToggleOption}
          maxWidth={tagMaxWidth}
        />
      ) : null
    );

  const Col = isAllFilters ? LGapCol : XxlGapCol;

  return (
    <Col>
      <S.Headline
        size={isAllFilters ? 'medium' : 'xxlarge'}
        noMargin
        data-testid="all-filters-filter"
        {...(isSerps && { forwardedAs: 'p' })}
      >
        {isSecondLevel ? (
          <IconButton
            icon={IconArrowLeft}
            onClick={closeSecondLevel}
            aria-label={formatMessage({ id: 'JOBS_SEARCH_BUTTON_BACK' })}
          />
        ) : null}
        <FormattedMessage id={titleKey} />
      </S.Headline>
      {asCheckbox ? (
        // Only filters with checkbox have the expand feature
        <>
          <S.CheckboxContainer ref={animationContainer}>
            {renderedOptions}
          </S.CheckboxContainer>
          {shouldRenderMoreButton ? (
            <ShowMoreButton
              onClick={handleOnMoreClick}
              isExpanded={isExpanded}
            />
          ) : null}
        </>
      ) : (
        <SS.TagsContainer ref={tagsContainerRef}>
          {renderedOptions}
        </SS.TagsContainer>
      )}
    </Col>
  );
};
