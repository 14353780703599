import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';
import { DeleteTag } from '@xing-com/tag';

import type { EntityIdFilterOption } from '../applied-filters';

type Props = {
  option: EntityIdFilterOption & { isExtracted?: boolean };
};

export const FilterTag: React.FC<Props> = ({ option }) => {
  const { getSearchParameter, setSearchParameter, deleteSearchParameter } =
    useSearchParameter();

  const handleOnClick = (paramName: string, filterId: string) => (): void => {
    // Extracted filter
    if (option.isExtracted) {
      const disabledParamName = `${paramName}${SEARCH_STATE_DISABLED_SUFFIX}`;
      const current = getSearchParameter(disabledParamName);

      if (!current) {
        setSearchParameter(disabledParamName, filterId, { replace: false });
      } else {
        const values = new Set(current.split(SEARCH_STATE_SEPARATOR));

        if (values.has(filterId)) {
          return;
        }

        values.add(filterId);
        setSearchParameter(
          disabledParamName,
          [...values].join(SEARCH_STATE_SEPARATOR),
          {
            replace: false,
          }
        );
      }

      return;
    }

    // Normal filter
    const current = getSearchParameter(paramName);
    if (!current) {
      return;
    }

    const values = new Set(current.split(SEARCH_STATE_SEPARATOR));
    if (!values.has(filterId)) {
      return;
    }
    values.delete(filterId);

    if (values.size === 0) {
      deleteSearchParameter(paramName, { replace: false });
      return;
    }

    setSearchParameter(paramName, [...values].join(SEARCH_STATE_SEPARATOR), {
      replace: false,
    });
  };

  // Avoid accessing each property in a non-hardcoded way
  // This should always succeed, even though it is not the most type-safe
  // code
  const { paramName, localizationValue } = Object.entries(option).reduce(
    (res, [key, value]) =>
      value && typeof value === 'object' && 'localizationValue' in value
        ? {
            paramName: key.startsWith('benefit') ? 'benefit' : key,
            localizationValue: value.localizationValue,
          }
        : res,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as { localizationValue: string; paramName: string }
  );

  return (
    <DeleteTag
      onClick={handleOnClick(paramName, option.entityId)}
      onDelete={handleOnClick(paramName, option.entityId)}
      selected
    >
      {localizationValue}
    </DeleteTag>
  );
};
