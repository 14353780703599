import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  MAX_SALARY,
  MIN_SALARY,
  SALARY_STEP,
} from '@xing-com/crate-jobs-constants';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { Slider } from '@xing-com/slider';
import { BodyCopy } from '@xing-com/typography';

import { SalaryValue } from '../../shared';
import { SliderWrapper } from '../shared.styles';
import { useSalaryFilter } from './use-salary';

const SalaryText = styled.span`
  font-weight: normal;
`;

export const ListSalaryFilter: React.FC = () => {
  const { value, onChange, onCommit } = useSalaryFilter();

  return (
    <div>
      <BodyCopy fontWeight="bold" data-testid="all-filters-filter">
        <FormattedMessage id="JOBS_SEARCH_FILTER_SALARY" />
        <SalaryText>
          {': '}
          <SalaryValue min={value[0]} max={value[1]} />
        </SalaryText>
      </BodyCopy>
      <NoSSR>
        <SliderWrapper>
          <Slider
            max={MAX_SALARY}
            min={MIN_SALARY}
            step={SALARY_STEP}
            value={value}
            onChange={onChange}
            onChangeCommitted={onCommit}
          />
        </SliderWrapper>
      </NoSSR>
    </div>
  );
};
