import type { NavigateOptions } from '@reach/router';
import { useLocation, useNavigate } from '@reach/router';
import * as React from 'react';

export type GetSearchParameters = (
  name: string[]
) => Record<string, string | null>;

type SetSearchParamsOptions = {
  removeEmpty?: boolean;
};
export type SetSearchParameters = (
  entries: Record<string, string>,
  options?: NavigateOptions<object> & SetSearchParamsOptions
) => void;

export type DeleteSearchParameters = (
  names: string[],
  options?: NavigateOptions<object>
) => void;

export type UseSearchParametersFn = () => {
  getSearchParameters: GetSearchParameters;
  setSearchParameters: SetSearchParameters;
  deleteSearchParameters: DeleteSearchParameters;
};

/**
 *
 * @returns UseSearchParameters
 */
export const useSearchParameters: UseSearchParametersFn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getSearchParameters: GetSearchParameters = React.useCallback(
    (names) => {
      const search = new URLSearchParams(location.search);
      return names.reduce(
        (current, name) => ({ ...current, [name]: search.get(name) }),
        {}
      );
    },
    [location.search]
  );

  const setSearchParameters: SetSearchParameters = React.useCallback(
    (entries, { removeEmpty, ...options } = {}) => {
      const searchParams = new URLSearchParams(location.search);

      Object.entries(entries).forEach(([name, value]) => {
        if (removeEmpty && !value) {
          searchParams.delete(name);
          return;
        }

        searchParams.set(name, value);
      });

      navigate(
        `${location.pathname}?${searchParams
          .toString()
          .replaceAll('+', '%20')}`,
        options
      );
    },
    [location.pathname, location.search, navigate]
  );

  const deleteSearchParameters: DeleteSearchParameters = React.useCallback(
    (names, options) => {
      const searchParams = new URLSearchParams(location.search);
      names.forEach((name) => {
        searchParams.delete(name);
      });
      const searchParamsString =
        searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';

      navigate(`${location.pathname}${searchParamsString}`, options);
    },
    [location.pathname, location.search, navigate]
  );

  return { setSearchParameters, deleteSearchParameters, getSearchParameters };
};
