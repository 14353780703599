import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css, keyframes } from 'styled-components';

import { ActivityCenterWrapper } from '@xing-com/crate-activity-center-wrapper';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  ORIGIN_PARAM,
  ORIGIN_PARAM_VALUES,
} from '@xing-com/crate-jobs-constants';
import { getPrimaryActions } from '@xing-com/crate-jobs-domain-search-primary-actions';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { Header as CrateLayoutHeader } from '@xing-com/crate-layout-header';
import { TopBar } from '@xing-com/crate-layout-top-bar';
import { useLoginAppUrl } from '@xing-com/crate-xinglet';
import { useExperiment } from '@xing-com/hub';
import { IconAIFilled } from '@xing-com/icons';
import {
  mediaTopBarHidden,
  mediaTopBarVisible,
  zIndexElevatedLayer5,
} from '@xing-com/layout-tokens';
import { Marker } from '@xing-com/marker';
import { scale150, spaceL, spaceXS, xdlColorXING } from '@xing-com/tokens';

import { FilterBar } from '../filters';
import { FakeInput } from './fake-input';

export const TopBarTitle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

const FlagsContainer = styled.div`
  display: flex;
  gap: ${spaceXS};
`;

const ActionsPlacebo = styled.div`
  display: inline-flex;
  width: ${scale150};
`;

const animation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const animationContent = css`
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${xdlColorXING};
    animation: ${animation} 0.2s forwards;
    pointer-events: none;
  }
`;

const StyledTopBar = styled(TopBar)<{ $isLoggedOut?: boolean }>`
  ${({ isSticky }) => isSticky && animationContent}

  ${({ $isLoggedOut }) =>
    $isLoggedOut &&
    css`
      @media ${mediaTopBarHidden} {
        margin-top: 84px;
      }

      & a {
        display: none;
      }
    `}
`;

const FixedFilterBar = styled(FilterBar)<{ $isSticky: boolean }>`
  display: none;

  @media ${mediaTopBarVisible} {
    ${({ $isSticky }) =>
      $isSticky &&
      css`
        display: flex;
        position: fixed;
        background-color: ${xdlColorXING};
        z-index: calc(${zIndexElevatedLayer5} - 50);
        width: 100%;

        {animationContent}
    `}
  }
`;

const LoggedOutTopBarContainer = styled.div`
  @media ${mediaTopBarVisible} {
    height: ${spaceL};
  }
`;

type Props = {
  isSticky: boolean;
};

export const Header: React.FC<Props> = ({ isSticky }) => {
  const { formatMessage } = useIntl();
  const { isLoggedIn } = useLoginState();
  const showTitle = useMatchMedia(mediaTopBarVisible);
  const { getSearchParameter } = useSearchParameter();
  const isAiSearch = useIsAiSearch();

  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  const findJobsTitle = formatMessage({ id: 'JOBS_FIND_JOBS_TITLE' });
  const searchAlertsTitle = formatMessage({
    id: 'JOBS_MYJOBS_SEARCH_ALERTS_SECTION_TITLE',
  });

  const subHeaderContent = !showTitle && isSticky ? <FakeInput /> : null;
  const title = showTitle ? (
    isAiSearch ? (
      <>
        <TopBarTitle>
          <FormattedMessage id="JOBS_FIND_JOBS_TITLE" />
          <FlagsContainer>
            <Marker icon={IconAIFilled} variant="ai">
              <FormattedMessage id="JOBS_SEARCH_AI_FLAG" />
            </Marker>
            {isAiSearchForced ? null : <Marker variant="onColor">Beta</Marker>}
          </FlagsContainer>
        </TopBarTitle>
        <ActionsPlacebo />
      </>
    ) : (
      <FormattedMessage id="JOBS_FIND_JOBS_TITLE" />
    )
  ) : null;

  const fromSearchAlerts =
    getSearchParameter(ORIGIN_PARAM) === ORIGIN_PARAM_VALUES.searchAlerts;
  const backButtonUrl = fromSearchAlerts
    ? ROUTES.myJobsSearchAlerts
    : ROUTES.findJobs;
  const backButtonUrlAriaLabelSection = fromSearchAlerts
    ? searchAlertsTitle
    : findJobsTitle;

  const loginAppUrl = useLoginAppUrl();

  return isLoggedIn ? (
    <>
      <StyledTopBar
        type="subheaderSearchResults"
        showOnLargerScreens
        skipAdditionalButtons={isAiSearch}
        skipBackButton={!showTitle}
        backButtonUrl={backButtonUrl}
        backButtonUrlAriaLabelSection={backButtonUrlAriaLabelSection}
        primaryActions={isAiSearch ? undefined : getPrimaryActions()}
        subHeaderContent={subHeaderContent}
        isSticky={isSticky}
        title={title}
      />
      <FixedFilterBar $isSticky={isSticky} />
    </>
  ) : (
    <>
      <ActivityCenterWrapper />
      <CrateLayoutHeader loginLink={loginAppUrl} />
      <LoggedOutTopBarContainer>
        <StyledTopBar
          type="subheaderSearchResults"
          showOnLargerScreens
          skipAdditionalButtons
          skipBackButton
          subHeaderContent={subHeaderContent}
          isSticky={isSticky}
          $isLoggedOut={!isLoggedIn}
        />
      </LoggedOutTopBarContainer>
    </>
  );
};
