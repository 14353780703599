import { FormattedMessage } from 'react-intl';

import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import { SEARCH_STATE_PARAMS } from '@xing-com/crate-jobs-constants';
import { useSearchState } from '@xing-com/crate-jobs-hooks';
import { DeleteTag } from '@xing-com/tag';

import { useSearch } from '../../../../hooks';

export const LocationTag: React.FC = () => {
  const { results } = useSearch();
  const { searchQuery } = useSearchState();
  const { deleteSearchParameters } = useSearchParameters();

  const location = results?.searchQuery.body?.location;
  const locationText =
    location?.city?.name || location?.text || searchQuery.location?.text;
  const radiusText = location?.radius ?? searchQuery.location?.radius;
  const hasLocationParams =
    searchQuery.location?.text || searchQuery.location?.cityId;

  const handleOnClick = (): void => {
    deleteSearchParameters(
      [
        SEARCH_STATE_PARAMS.location,
        SEARCH_STATE_PARAMS.cityId,
        SEARCH_STATE_PARAMS.radius,
      ],
      { replace: false }
    );
  };

  return hasLocationParams && locationText ? (
    <DeleteTag onClick={handleOnClick} onDelete={handleOnClick} selected>
      <FormattedMessage id="JOBS_SEARCH_FILTER_LOCATION_TEXT" />
      {` ${locationText}${radiusText ? ` (${radiusText} km)` : ''}`}
    </DeleteTag>
  ) : null;
};
