import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';

import type { ListType } from '../components/results/results-list';

export const useJDPUrlParams = (type: ListType): string => {
  const isAiSearch = useIsAiSearch();

  const urlParams = {
    SEARCH: isAiSearch ? '?ijt=jb_55' : '?ijt=jb_18',
    EMPTY_STATE_SEARCH: '?ijt=jb_37',
    EMPTY_STATE_RECOS: '?ijt=jb_36',
  };

  return urlParams[type] ?? '';
};
