import type { InMemoryCache, WatchQueryFetchPolicy } from '@apollo/client';
import { useApolloClient, useQuery } from '@apollo/client';

import type { Maybe } from '@xing-com/crate-common-graphql-types';
import { RECOMMENDATIONS_COUNT } from '@xing-com/crate-jobs-constants';
import type {
  GetJobRecommendationsQuery,
  JobRecommendationFragment,
  JobTeaserVisibleJobFragment,
} from '@xing-com/crate-jobs-graphql';
import { GetJobRecommendationsDocument } from '@xing-com/crate-jobs-graphql';

export type JobRecommendationVisibleJob = JobRecommendationFragment & {
  job: JobTeaserVisibleJobFragment;
};

type UseRecommendationsOptions = {
  consumer?: string;
  first?: number;
  exclude?: string[];
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
  onCompleted?: (data: GetJobRecommendationsQuery) => void;
};

const isVisibleJob = (
  item: Maybe<JobRecommendationFragment>
): item is JobRecommendationVisibleJob => {
  return item?.job?.__typename === 'VisibleJob';
};

// Return types are inferred
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useRecommendations = ({
  consumer = 'loggedin.web.jobs.recommendations_box.center',
  first = RECOMMENDATIONS_COUNT,
  exclude,
  fetchPolicy = 'cache-first',
  skip,
  onCompleted,
}: UseRecommendationsOptions) => {
  const { cache } = useApolloClient();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (cache as InMemoryCache).policies.addTypePolicies({
    JobRecommendation: {
      fields: {
        // Local-only field with @client directive
        isDeleted(isDeleted = false) {
          return isDeleted;
        },
      },
    },
  });

  const { loading, data, refetch } = useQuery(GetJobRecommendationsDocument, {
    variables: {
      consumer,
      first,
      exclude,
    },
    fetchPolicy,
    errorPolicy: 'ignore',
    onCompleted,
    skip,
  });

  const recommendations = (data?.viewer?.jobRecommendations?.edges ?? [])
    .map((item) => item.node)
    .filter(isVisibleJob);

  return {
    isLoading: loading,
    isEmpty: recommendations.length === 0,
    hasData: !!data && !loading,
    recommendations,
    refetch,
  };
};
