import type React from 'react';
import styled from 'styled-components';

import { useJoinFilters } from '@xing-com/crate-jobs-hooks';
import { IconSearch } from '@xing-com/icons';
import { InputBar } from '@xing-com/text-field';

import { useSearch } from '../../hooks';

const Button = styled.div`
  cursor: pointer;
  width: 80%;
  max-width: 700px;
`;

const Input = styled(InputBar)`
  pointer-events: none;

  & input {
    text-overflow: ellipsis;
  }
`;

export const FakeInput: React.FC = () => {
  const { filterCollection, results } = useSearch();

  const { keywords, location } = results?.searchQuery.body || {};
  const joinedFilters = useJoinFilters(filterCollection, {
    keywords: keywords,
    location: location?.city?.name || location?.text,
    radius: location?.radius,
  });

  const handleOnClick = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleOnKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      handleOnClick();
    }
  };

  return (
    <Button
      role="button"
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      tabIndex={0}
    >
      <Input
        value=""
        placeholder={joinedFilters}
        variant="plain"
        icon={IconSearch}
        tabIndex={-1}
        // @ts-expect-error FIXME: SC6
        ellipsis
      />
    </Button>
  );
};
