import { useEffect, useState } from 'react';

import {
  MAX_SALARY,
  MIN_SALARY,
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_PARAMS,
} from '@xing-com/crate-jobs-constants';
import type { ExtractedFiltersEnabledFragment } from '@xing-com/crate-jobs-graphql';
import { useFilterRange } from '@xing-com/crate-jobs-hooks';
import type { SliderProps } from '@xing-com/slider';

import { useSearch } from '../../../hooks';

type SliderChangeHandler = SliderProps['onChange'];
type SliderCommitHandler = SliderProps['onChangeCommitted'];

type UseSalaryFilterResult = {
  value: number[];
  onChange: SliderChangeHandler;
  onCommit: SliderCommitHandler;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getSalaryFromExtractedFilters = (
  salary: string[],
  extractedFilters: (ExtractedFiltersEnabledFragment | null)[]
) => {
  let min = Number.parseInt(salary[0]);
  let max = Number.parseInt(salary[1]);
  let hasExtractedSalary = false;

  extractedFilters.forEach((extractedFilter) => {
    if (
      extractedFilter?.__typename === 'JobFilterSalary' &&
      (typeof extractedFilter.min === 'number' ||
        typeof extractedFilter.max === 'number')
    ) {
      hasExtractedSalary = true;
      if (extractedFilter.min) min = extractedFilter.min;
      if (extractedFilter.max) max = extractedFilter.max;
    }
  });

  return { min, max, hasExtractedSalary };
};

export const useSalaryFilter = (): UseSalaryFilterResult => {
  const { value: salary, onRangeChange } = useFilterRange(
    SEARCH_STATE_PARAMS.salary,
    {
      min: `${MIN_SALARY}`,
      max: `${MAX_SALARY}`,
    }
  );
  const { extractedFilters } = useSearch();

  const { min, max, hasExtractedSalary } = getSalaryFromExtractedFilters(
    salary,
    extractedFilters
  );
  const [internalValue, setInternalValues] = useState([min, max]);

  useEffect(() => {
    // Check if the URL params have been changed (i.e. by resetting the filters)
    setInternalValues([min, max]);
  }, [min, max]);

  const handleOnChange: SliderChangeHandler = (_, value) => {
    if (typeof value === 'number') {
      return;
    }

    setInternalValues(value);
  };

  const handleOnCommit: SliderCommitHandler = (_, value) => {
    if (typeof value === 'number') {
      return;
    }

    const disabledParamName = `${SEARCH_STATE_PARAMS.salary}${SEARCH_STATE_DISABLED_SUFFIX}`;

    onRangeChange(
      {
        min: `${value[0]}`,
        max: `${value[1]}`,
      },
      hasExtractedSalary ? { [disabledParamName]: 'true' } : undefined
    );
  };

  return {
    value: internalValue,
    onChange: handleOnChange,
    onCommit: handleOnCommit,
  };
};
