import { useIntl } from 'react-intl';

import { useSearchState } from '@xing-com/crate-jobs-hooks';
import { EmptyState, ErrorState as ErrorStateXDS } from '@xing-com/empty-state';
import { IllustrationSpotJobSearch } from '@xing-com/illustrations';

type Props = {
  refetch: () => void;
};

export const ErrorState: React.FC<Props> = ({ refetch }) => {
  const { formatMessage } = useIntl();
  const { searchQuery } = useSearchState();

  const retry = (): void => {
    refetch();
  };

  const isEmptySearch = !searchQuery.keywords && !searchQuery.location;

  return isEmptySearch ? (
    <EmptyState
      variant="engage"
      illustration={IllustrationSpotJobSearch}
      headlineText={formatMessage({ id: 'JOBS_SEARCH_EMPTY_ERROR_HEADLINE' })}
      bodyCopyText={formatMessage({ id: 'JOBS_SEARCH_EMPTY_ERROR_TEXT' })}
    />
  ) : (
    <ErrorStateXDS buttonProps={{ onClick: retry }} />
  );
};
