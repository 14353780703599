import { useLocation, useMatch } from '@reach/router';

import type { Maybe } from '@xing-com/crate-common-graphql-types';
import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import {
  AI_SWIMP_TRACKING_TOKEN_PARAM,
  DEFAULT_SORT,
} from '@xing-com/crate-jobs-constants';
import type {
  JobItemResultFragment,
  JobSearchByQueryQuery,
  JobTeaserVisibleJobFragment,
} from '@xing-com/crate-jobs-graphql';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { unscrambleId } from '@xing-com/crate-utils-x1-data';

import {
  getNwtCleanQuery,
  getTrackingFilters,
} from '../helpers/search-tracking-filters';
import { useJobsNwtTracking } from './use-jobs-nwt-tracking';
import { usePageTracking } from './use-page-tracking';

type TrackPageview = {
  results: JobSearchByQueryQuery['jobSearchByQuery'];
  sort?: string | null;
  page: number;
  filterCount: number;
  shouldTrackNwt?: boolean;
};

type JobItemResultVisibleJob = JobItemResultFragment & {
  jobDetail: JobTeaserVisibleJobFragment;
};

type AdobeTrackingProps = {
  PropTrackAction: string;
  PropActionOrigin?: string;
  PropPagination?: number;
  PropJobsSearchIds?: string;
};

export const trackingFilterName: Record<string, string> = {
  remoteOption: 'remote',
  employmentType: 'type',
  careerLevel: 'level',
  benefitWorkingCulture: 'atmosphere',
};

const isVisibleJob = (
  item: Maybe<JobItemResultFragment>
): item is JobItemResultVisibleJob => {
  return item?.jobDetail?.__typename === 'VisibleJob';
};

// Inferring
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSearchTracking = () => {
  const { trackPageview: baseTrackPageview } = usePageTracking({
    repeatTracking: true,
  });
  const trackNwt = useJobsNwtTracking();
  const { track: trackAdobe } =
    useTracking<InteractionTrackingEvent<AdobeTrackingProps>>();
  const location = useLocation();

  // Not using useIsAiSearch to avoid circular dependencies
  const isAiSearch = !!useMatch(ROUTES.searchAi);

  const trackPageview = ({
    results,
    sort,
    page,
    filterCount,
    shouldTrackNwt,
  }: TrackPageview): void => {
    if (!results) {
      baseTrackPageview({
        channel: 'wbm/Search_Stellenmarkt',
        page: 'wbm/Search_Stellenmarkt/error',
      });
      trackNwt({
        eventSchema: 'basic',
        event: 'viewed_screen',
        page: 'jobs_search/error',
      });

      return;
    }

    const { collection, searchQuery, total } = results;

    const visibleJobs = collection.filter(isVisibleJob);
    const jobIds = visibleJobs.map((item) => unscrambleId(item.jobDetail.id));
    const filters = getTrackingFilters(searchQuery.body?.filterCollection);
    const keywords = searchQuery.body?.keywords || undefined;
    const locationText =
      searchQuery.body?.location?.city?.name ||
      searchQuery.body?.location?.text ||
      undefined;
    const radius = searchQuery.body?.location?.radius || 'no_radius';

    baseTrackPageview({
      channel: 'wbm/Search_Stellenmarkt',
      page: 'wbm/Search_Stellenmarkt/serp',
      PropJobsSearchIds: jobIds.join(','),
      PropSearchTerm: keywords,
      PropSearchCity: locationText,
      PropSearchResultAmount: total,
      PropSearchListFilter: filters,
      PropSortOrder: `jobs_${sort || DEFAULT_SORT}`,
      PropPagination: page,
      PropContextDimension1: `jobs_search_filter_radius_${radius}`,
      PropContextDimension2: `jobs_search_filter_count_${filterCount}`,
      PropContextDimension3: `jobs_search_ai_search_${isAiSearch}`,
    });

    if (shouldTrackNwt) {
      const token = visibleJobs[0]?.trackingToken;
      const tokenCommon = token?.split('.')[0];
      const query = new URLSearchParams(location.search);
      const trackingToken = query.get(AI_SWIMP_TRACKING_TOKEN_PARAM);

      trackNwt({
        event: 'search_performed',
        eventSchema: 'searchresult',
        element: 'job_posting',
        page: 'jobs_search/serp',
        query: getNwtCleanQuery(query),
        itemCount: total,
        trackingToken: tokenCommon,
        flags: `jobs_search_ai_search_${isAiSearch}`,
        ...(trackingToken ? { originalTrackingToken: trackingToken } : {}),
      });
    }
  };

  const trackFetchMore = ({
    results,
    page,
  }: Pick<TrackPageview, 'page' | 'results'>): void => {
    if (!results) {
      return;
    }

    const { collection } = results;
    const visibleJobs = collection.filter(isVisibleJob);
    const jobIds = visibleJobs.map((item) => unscrambleId(item.jobDetail.id));

    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_search_show_more_results',
      PropPagination: page,
      PropJobsSearchIds: jobIds.join(','),
    });
  };

  const trackFilterOpen = (filter = 'all_filters', context = 'serp'): void => {
    const filterName = trackingFilterName[filter] || filter;

    const origin =
      context === 'serp' ? 'jobs_search_serp' : `jobs_search_filter_${context}`;
    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: `jobs_search_filter_${filterName}_open`,
      PropActionOrigin: origin,
    });

    const contextString =
      context === 'serp'
        ? 'serp'
        : context === 'all_filters'
          ? 'serp_all_filters'
          : `serp_filter_${context}`;
    trackNwt({
      eventSchema: 'basic',
      event: 'clicked',
      page: 'jobs_search/serp',
      elementDetail: 'jobs_search_filter',
      element: `${filterName}_open`,
      context: contextString,
    });
  };

  const trackFilterClose = (
    filter: string,
    action: 'apply' | 'close' | 'cancel'
  ): void => {
    const filterName = trackingFilterName[filter] || filter;

    // Adobe tracking performed in pageview with URL params

    const element =
      filter === 'all_filters'
        ? `all_filters_${action}`
        : `filter_${filterName}_${action}`;
    const context =
      filter === 'all_filters'
        ? 'serp_all_filters'
        : `serp_filter_${filterName}`;
    trackNwt({
      eventSchema: 'basic',
      event: 'clicked',
      page: 'jobs_search/serp',
      elementDetail: 'jobs_search_filter',
      element,
      context,
    });
  };

  const trackClickBoostMeUp = (): void => {
    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_serp_boost_me_up_now_click',
      PropActionOrigin: 'jobs_search_serp',
    });

    trackNwt({
      event: 'visited',
      eventSchema: 'searchresult',
      element: 'boost_me_up_now',
      page: 'jobs_search/serp',
    });
  };

  return {
    trackPageview,
    trackFetchMore,
    trackFilterOpen,
    trackFilterClose,
    trackClickBoostMeUp,
  };
};
