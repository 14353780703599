import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';
import {
  FILTER_TYPENAME_TO_PROPERTY_NAME,
  PROPERTY_NAME_TO_PARAM,
} from '@xing-com/crate-jobs-domain-search-maps';
import type { FilterPropertyName } from '@xing-com/crate-jobs-domain-search-types';

import { useSearch } from '../../../hooks';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useExtractedFiltersSet = (propertyName: FilterPropertyName) => {
  const { extractedFilters } = useSearch();
  const { getSearchParameter, setSearchParameter } = useSearchParameter();

  const set = new Set<string>();
  extractedFilters.forEach((extractedFilter) => {
    if (
      !extractedFilter?.__typename ||
      !('entityId' in extractedFilter) ||
      FILTER_TYPENAME_TO_PROPERTY_NAME[extractedFilter.__typename] !==
        propertyName
    ) {
      return;
    }

    set.add(extractedFilter.entityId);
  });

  const onDelete = (value: string): void => {
    const disabledParamName = `${PROPERTY_NAME_TO_PARAM[propertyName]}${SEARCH_STATE_DISABLED_SUFFIX}`;
    const current = getSearchParameter(disabledParamName);

    if (!current) {
      setSearchParameter(disabledParamName, value, { replace: false });
    } else {
      const values = new Set(current.split(SEARCH_STATE_SEPARATOR));

      if (values.has(value)) {
        return;
      }

      values.add(value);
      setSearchParameter(
        disabledParamName,
        [...values].join(SEARCH_STATE_SEPARATOR),
        {
          replace: false,
        }
      );
    }
  };

  return { extractedFilters: set, onDelete };
};
