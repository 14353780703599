/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type JobItemResultFragment = {
  __typename?: 'JobItemResult';
  trackingToken: string;
  position: number;
  descriptionHighlight?: string | null;
  jobDetail?:
    | { __typename?: 'UnauthorizedJob' }
    | {
        __typename?: 'VisibleJob';
        id: string;
        slug: string;
        url: string;
        title: string;
        activatedAt?: string | null;
        refreshedAt: string;
        globalId: string;
        userInteractions?: {
          __typename?: 'JobUserInteractions';
          bookmark?: {
            __typename?: 'JobBookmark';
            state: Types.JobBookmarkState;
          } | null;
        } | null;
        location?: { __typename?: 'JobLocation'; city?: string | null } | null;
        employmentType?: {
          __typename?: 'EmploymentType';
          localizationValue: string;
        } | null;
        companyInfo: {
          __typename?: 'JobCompanyInfo';
          companyNameOverride: string;
          company?: {
            __typename?: 'Company';
            id: string;
            logos?: { __typename?: 'CompanyLogos'; x1?: string | null } | null;
            kununuData?: {
              __typename?: 'CompanyKununuData';
              ratingAverage?: number | null;
            } | null;
          } | null;
        };
        salary?:
          | {
              __typename?: 'Salary';
              currency?: Types.Currency | null;
              amount?: number | null;
            }
          | {
              __typename?: 'SalaryEstimate';
              currency?: Types.Currency | null;
              minimum?: number | null;
              maximum?: number | null;
              median?: number | null;
            }
          | {
              __typename?: 'SalaryRange';
              currency?: Types.Currency | null;
              minimum?: number | null;
              maximum?: number | null;
            }
          | { __typename?: 'SalaryUnauthorized' }
          | null;
      }
    | null;
  matchingHighlightsV2?: {
    __typename?: 'JobMatchingHighlightsV2';
    token: string;
    highlight?: {
      __typename?: 'JobMatchingHighlightsJobHighlightV2';
      type: string;
      localization: {
        __typename?: 'JobMatchingHighlightsLocalizableV2';
        localizationValue: string;
      };
      localizationA11y?: {
        __typename?: 'JobMatchingHighlightsLocalizableV2';
        localizationValue: string;
      } | null;
    } | null;
    matchingFacts: Array<
      | {
          __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
          type: string;
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        }
      | {
          __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
          type: string;
          value:
            | {
                __typename?: 'Salary';
                currency?: Types.Currency | null;
                amount?: number | null;
              }
            | {
                __typename?: 'SalaryEstimate';
                currency?: Types.Currency | null;
                minimum?: number | null;
                maximum?: number | null;
                median?: number | null;
              }
            | {
                __typename?: 'SalaryRange';
                currency?: Types.Currency | null;
                minimum?: number | null;
                maximum?: number | null;
              }
            | { __typename?: 'SalaryUnauthorized' };
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        }
      | {
          __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
          type: string;
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        }
    >;
    nonMatchingFacts: Array<
      | {
          __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
          type: string;
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        }
      | {
          __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
          type: string;
          value:
            | {
                __typename?: 'Salary';
                currency?: Types.Currency | null;
                amount?: number | null;
              }
            | {
                __typename?: 'SalaryEstimate';
                currency?: Types.Currency | null;
                minimum?: number | null;
                maximum?: number | null;
                median?: number | null;
              }
            | {
                __typename?: 'SalaryRange';
                currency?: Types.Currency | null;
                minimum?: number | null;
                maximum?: number | null;
              }
            | { __typename?: 'SalaryUnauthorized' };
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        }
      | {
          __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
          type: string;
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        }
    >;
  } | null;
};

export type JobSearchByQueryQueryVariables = Types.Exact<{
  query: Types.JobSearchQueryInput;
  consumer: Types.Scalars['String']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  trackRecent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  searchMode?: Types.InputMaybe<Types.SearchMode>;
}>;

export type JobSearchByQueryQuery = {
  __typename?: 'Query';
  jobSearchByQuery?: {
    __typename?: 'JobSearchResult';
    total: number;
    searchQuery: {
      __typename?: 'JobSearchQuery';
      guid: string;
      body?: {
        __typename?: 'JobSearchQueryBody';
        keywords?: string | null;
        location?: {
          __typename?: 'JobSearchQueryLocation';
          text?: string | null;
          radius?: number | null;
          city?: {
            __typename?: 'City';
            id: string;
            name?: string | null;
          } | null;
        } | null;
        filterCollection?: Array<
          | { __typename?: 'JobFilterBenefit' }
          | {
              __typename?: 'JobFilterBenefitEmployeePerk';
              entityId: string;
              benefitEmployeePerk?: {
                __typename?: 'KununuBenefit';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterBenefitWorkingCulture';
              entityId: string;
              benefitWorkingCulture?: {
                __typename?: 'KununuBenefit';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterCareerLevel';
              entityId: string;
              careerLevel?: {
                __typename?: 'CareerLevel';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterCity';
              entityId: string;
              city?: {
                __typename?: 'City';
                localizationValue?: string | null;
              } | null;
            }
          | {
              __typename?: 'JobFilterCompany';
              entityId: string;
              company?: {
                __typename?: 'Company';
                companyName?: string | null;
              } | null;
            }
          | {
              __typename?: 'JobFilterCountry';
              entityId: string;
              country?: {
                __typename?: 'Country';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterDiscipline';
              entityId: string;
              discipline?: {
                __typename?: 'Discipline';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterEmploymentType';
              entityId: string;
              employmentType?: {
                __typename?: 'EmploymentType';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterIndustry';
              entityId: string;
              industry?: {
                __typename?: 'Industry';
                localizationValue: string;
              } | null;
            }
          | { __typename?: 'JobFilterProjob' }
          | { __typename?: 'JobFilterPublishToCompany' }
          | { __typename?: 'JobFilterRemote' }
          | {
              __typename?: 'JobFilterRemoteOption';
              entityId: string;
              remoteOption?: {
                __typename?: 'JobRemoteOption';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterSalary';
              min?: number | null;
              max?: number | null;
            }
          | null
        > | null;
      } | null;
    };
    appliedFilters?: {
      __typename?: 'JobAppliedFilterResult';
      extractedFilters?: {
        __typename?: 'JobAppliedExtractedFilter';
        enabled?: Array<
          | { __typename?: 'JobFilterBenefit' }
          | { __typename?: 'JobFilterBenefitEmployeePerk' }
          | { __typename?: 'JobFilterBenefitWorkingCulture' }
          | {
              __typename?: 'JobFilterCareerLevel';
              entityId: string;
              careerLevel?: {
                __typename?: 'CareerLevel';
                localizationValue: string;
              } | null;
            }
          | { __typename?: 'JobFilterCity' }
          | { __typename?: 'JobFilterCompany' }
          | { __typename?: 'JobFilterCountry' }
          | { __typename?: 'JobFilterDiscipline' }
          | {
              __typename?: 'JobFilterEmploymentType';
              entityId: string;
              employmentType?: {
                __typename?: 'EmploymentType';
                localizationValue: string;
              } | null;
            }
          | { __typename?: 'JobFilterIndustry' }
          | {
              __typename?: 'JobFilterLocation';
              location?: {
                __typename?: 'Location';
                localizationValue: string;
                radius?: number | null;
              } | null;
            }
          | {
              __typename?: 'JobFilterRemoteOption';
              entityId: string;
              remoteOption?: {
                __typename?: 'JobRemoteOption';
                localizationValue: string;
              } | null;
            }
          | {
              __typename?: 'JobFilterSalary';
              min?: number | null;
              max?: number | null;
            }
          | null
        > | null;
        disabled?: Array<
          | { __typename?: 'JobFilterBenefit' }
          | { __typename?: 'JobFilterBenefitEmployeePerk' }
          | { __typename?: 'JobFilterBenefitWorkingCulture' }
          | {
              __typename?: 'JobFilterCareerLevel';
              entityId: string;
              careerLevel?: {
                __typename?: 'CareerLevel';
                localizationValue: string;
              } | null;
            }
          | { __typename?: 'JobFilterCity' }
          | { __typename?: 'JobFilterCompany' }
          | { __typename?: 'JobFilterCountry' }
          | { __typename?: 'JobFilterDisabledSalary'; salary?: boolean | null }
          | { __typename?: 'JobFilterDiscipline' }
          | {
              __typename?: 'JobFilterEmploymentType';
              entityId: string;
              employmentType?: {
                __typename?: 'EmploymentType';
                localizationValue: string;
              } | null;
            }
          | { __typename?: 'JobFilterIndustry' }
          | {
              __typename?: 'JobFilterLocation';
              location?: {
                __typename?: 'Location';
                localizationValue: string;
                radius?: number | null;
              } | null;
            }
          | {
              __typename?: 'JobFilterRemoteOption';
              entityId: string;
              remoteOption?: {
                __typename?: 'JobRemoteOption';
                localizationValue: string;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
    collection: Array<{
      __typename?: 'JobItemResult';
      trackingToken: string;
      position: number;
      descriptionHighlight?: string | null;
      jobDetail?:
        | { __typename?: 'UnauthorizedJob' }
        | {
            __typename?: 'VisibleJob';
            id: string;
            slug: string;
            url: string;
            title: string;
            activatedAt?: string | null;
            refreshedAt: string;
            globalId: string;
            userInteractions?: {
              __typename?: 'JobUserInteractions';
              bookmark?: {
                __typename?: 'JobBookmark';
                state: Types.JobBookmarkState;
              } | null;
            } | null;
            location?: {
              __typename?: 'JobLocation';
              city?: string | null;
            } | null;
            employmentType?: {
              __typename?: 'EmploymentType';
              localizationValue: string;
            } | null;
            companyInfo: {
              __typename?: 'JobCompanyInfo';
              companyNameOverride: string;
              company?: {
                __typename?: 'Company';
                id: string;
                logos?: {
                  __typename?: 'CompanyLogos';
                  x1?: string | null;
                } | null;
                kununuData?: {
                  __typename?: 'CompanyKununuData';
                  ratingAverage?: number | null;
                } | null;
              } | null;
            };
            salary?:
              | {
                  __typename?: 'Salary';
                  currency?: Types.Currency | null;
                  amount?: number | null;
                }
              | {
                  __typename?: 'SalaryEstimate';
                  currency?: Types.Currency | null;
                  minimum?: number | null;
                  maximum?: number | null;
                  median?: number | null;
                }
              | {
                  __typename?: 'SalaryRange';
                  currency?: Types.Currency | null;
                  minimum?: number | null;
                  maximum?: number | null;
                }
              | { __typename?: 'SalaryUnauthorized' }
              | null;
          }
        | null;
      matchingHighlightsV2?: {
        __typename?: 'JobMatchingHighlightsV2';
        token: string;
        highlight?: {
          __typename?: 'JobMatchingHighlightsJobHighlightV2';
          type: string;
          localization: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          };
          localizationA11y?: {
            __typename?: 'JobMatchingHighlightsLocalizableV2';
            localizationValue: string;
          } | null;
        } | null;
        matchingFacts: Array<
          | {
              __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
              type: string;
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            }
          | {
              __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
              type: string;
              value:
                | {
                    __typename?: 'Salary';
                    currency?: Types.Currency | null;
                    amount?: number | null;
                  }
                | {
                    __typename?: 'SalaryEstimate';
                    currency?: Types.Currency | null;
                    minimum?: number | null;
                    maximum?: number | null;
                    median?: number | null;
                  }
                | {
                    __typename?: 'SalaryRange';
                    currency?: Types.Currency | null;
                    minimum?: number | null;
                    maximum?: number | null;
                  }
                | { __typename?: 'SalaryUnauthorized' };
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            }
          | {
              __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
              type: string;
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            }
        >;
        nonMatchingFacts: Array<
          | {
              __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
              type: string;
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            }
          | {
              __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
              type: string;
              value:
                | {
                    __typename?: 'Salary';
                    currency?: Types.Currency | null;
                    amount?: number | null;
                  }
                | {
                    __typename?: 'SalaryEstimate';
                    currency?: Types.Currency | null;
                    minimum?: number | null;
                    maximum?: number | null;
                    median?: number | null;
                  }
                | {
                    __typename?: 'SalaryRange';
                    currency?: Types.Currency | null;
                    minimum?: number | null;
                    maximum?: number | null;
                  }
                | { __typename?: 'SalaryUnauthorized' };
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            }
          | {
              __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
              type: string;
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            }
        >;
      } | null;
    } | null>;
    aggregations?: {
      __typename?: 'JobAggregationResult';
      employmentTypes?: Array<{
        __typename?: 'JobAggregationEmploymentType';
        count: number;
        id: string;
        employmentType?: {
          __typename?: 'EmploymentType';
          localizationValue: string;
        } | null;
      } | null> | null;
      careerLevels?: Array<{
        __typename?: 'JobAggregationCareerLevels';
        count: number;
        id: string;
        careerLevel?: {
          __typename?: 'CareerLevel';
          localizationValue: string;
        } | null;
      } | null> | null;
      disciplines?: Array<{
        __typename?: 'JobAggregationDiscipline';
        count: number;
        id: string;
        discipline?: {
          __typename?: 'Discipline';
          localizationValue: string;
        } | null;
      } | null> | null;
      industries?: Array<{
        __typename?: 'JobAggregationIndustry';
        count: number;
        id: string;
        industry?: {
          __typename?: 'Industry';
          localizationValue: string;
        } | null;
      } | null> | null;
      benefitsEmployeePerk?: Array<{
        __typename?: 'JobAggregationBenefit';
        count: number;
        id: string;
        benefit?: {
          __typename?: 'KununuBenefit';
          localizationValue: string;
        } | null;
      } | null> | null;
      benefitsWorkingCulture?: Array<{
        __typename?: 'JobAggregationBenefit';
        count: number;
        id: string;
        benefit?: {
          __typename?: 'KununuBenefit';
          localizationValue: string;
        } | null;
      } | null> | null;
      countries?: Array<{
        __typename?: 'JobAggregationCountry';
        count: number;
        id: string;
        country?: { __typename?: 'Country'; localizationValue: string } | null;
      } | null> | null;
      cities?: Array<{
        __typename?: 'JobAggregationCity';
        count: number;
        id: string;
        city?: {
          __typename?: 'City';
          localizationValue?: string | null;
        } | null;
      } | null> | null;
      remoteOptions?: Array<{
        __typename?: 'JobAggregationRemoteOption';
        id: string;
        remoteOption?: {
          __typename?: 'JobRemoteOption';
          localizationValue: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export const JobItemResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobItemResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobItemResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptionHighlight' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingHighlightsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Salary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Salary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryRange' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryRange' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryEstimate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryEstimate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'median' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisibleJobCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameOverride' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'x1' },
                              name: { kind: 'Name', value: 'logo96px' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Salary' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryRange' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryEstimate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobKeyfactV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsJobKeyfactV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localizationA11y' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'JobMatchingHighlightsJobKeyfactSalaryV2',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Salary' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryRange' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryEstimate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'VisibleJobCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userInteractions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmark' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationA11y' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMatchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobItemResultFragment, unknown>;
export const JobSearchByQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JobSearchByQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'JobSearchQueryInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consumer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackRecent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchMode' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchMode' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobSearchByQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'consumer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchMode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchMode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trackRecent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'trackRecent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'returnAggregations' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'splitBenefit' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchQuery' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'body' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'JobSearchQueryBodySplitBenefits',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extractedFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enabled' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ExtractedFiltersEnabled',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'disabled' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ExtractedFiltersDisabled',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobItemResult' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employmentTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'EmploymentTypeAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'careerLevels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CareerLevelAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disciplines' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DisciplineAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'IndustryAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'benefitsEmployeePerk' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'BenefitAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'benefitsWorkingCulture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'BenefitAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CountryAggregation',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CityAggregation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remoteOptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RemoteOptionAggregation',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCompany' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmploymentTypeFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterEmploymentType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CareerLevelFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCareerLevel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careerLevel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DisciplineFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterDiscipline' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discipline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndustryFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterIndustry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BenefitEmployeePerkFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterBenefitEmployeePerk' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'benefitEmployeePerk' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BenefitWorkingCultureFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterBenefitWorkingCulture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'benefitWorkingCulture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCountry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CityFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'city' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'localizationValue' },
                  name: { kind: 'Name', value: 'name' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterSalary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RemoteOptionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterRemoteOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remoteOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobSearchFilterCollectionSplitBenefits' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCollection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CompanyFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmploymentTypeFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CareerLevelFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DisciplineFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndustryFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BenefitEmployeePerkFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BenefitWorkingCultureFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CountryFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CityFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SalaryFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RemoteOptionFilter' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Salary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Salary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryRange' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryRange' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryEstimate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryEstimate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'median' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisibleJobCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameOverride' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'x1' },
                              name: { kind: 'Name', value: 'logo96px' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Salary' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryRange' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryEstimate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'VisibleJobCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userInteractions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmark' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobKeyfactV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsJobKeyfactV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localizationA11y' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'JobMatchingHighlightsJobKeyfactSalaryV2',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Salary' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryRange' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryEstimate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationA11y' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMatchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobSearchQueryBodySplitBenefits' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobSearchQueryBody' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'radius' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'city' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobSearchFilterCollectionSplitBenefits',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExtractedFiltersEnabled' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobExtractedFilterEnabledCollection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CareerLevelFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmploymentTypeFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SalaryFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RemoteOptionFilter' },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'JobFilterLocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExtractedFiltersDisabled' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobExtractedFilterDisabledCollection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CareerLevelFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmploymentTypeFilter' },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'JobFilterDisabledSalary' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salary' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RemoteOptionFilter' },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'JobFilterLocation' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radius' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobItemResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobItemResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptionHighlight' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingHighlightsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmploymentTypeAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationEmploymentType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CareerLevelAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationCareerLevels' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careerLevel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DisciplineAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationDiscipline' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discipline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndustryAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationIndustry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BenefitAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationBenefit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'benefit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationCountry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CityAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationCity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'city' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'localizationValue' },
                  name: { kind: 'Name', value: 'name' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RemoteOptionAggregation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobAggregationRemoteOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remoteOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobSearchByQueryQuery,
  JobSearchByQueryQueryVariables
>;
