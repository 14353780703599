import type { AutoAnimationPlugin } from '@formkit/auto-animate';

import { motionEasingEnter, motionTimeNumberM } from '@xing-com/tokens';

export const createKeyframeEffect =
  (level: 'first' | 'second'): AutoAnimationPlugin =>
  (el, action) => {
    const addFrom = level === 'first' ? '-100%' : '100%';
    const removeTo = level === 'first' ? '100%' : '-100%';

    const keyframes =
      action === 'add'
        ? [
            { transform: `translateX(${addFrom})` },
            { transform: 'translateX(0)' },
          ]
        : action === 'remove'
          ? [
              { transform: 'translateX(0)' },
              { transform: `translateX(${removeTo})` },
            ]
          : [];

    const options = {
      duration: Number(motionTimeNumberM),
      easing: motionEasingEnter,
    };

    return new KeyframeEffect(el, keyframes, options);
  };
