import styled from 'styled-components';

import {
  SkeletonTag,
  SkeletonHeadline,
  SkeletonCheckbox,
} from '@xing-com/skeleton';
import { scale280, spaceL, spaceS } from '@xing-com/tokens';

import * as SS from '../shared.styles';
import * as S from './filter-set.styles';

const TitleSkeleton = styled(SkeletonHeadline)`
  margin-bottom: ${spaceL};
`;

const CheckboxSkeletonContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: ${spaceS};
`;

const CheckboxInputSkeleton = styled(SkeletonCheckbox)`
  flex-shrink: 0;
`;

const CheckboxTextSkeleton = styled(SkeletonHeadline)`
  width: 100%;
  & > div {
    height: auto;
  }
`;

const LargerTagSkeleton = styled(SkeletonTag)`
  width: ${scale280};
`;

const CheckboxSkeleton: React.FC = () => (
  <CheckboxSkeletonContainer>
    <CheckboxInputSkeleton />
    <CheckboxTextSkeleton amountRows={1} size="small" />
  </CheckboxSkeletonContainer>
);

type Props = { asCheckbox: boolean };

export const FilterSetSkeleton: React.FC<Props> = ({ asCheckbox }) => (
  <div>
    <TitleSkeleton amountRows={1} />
    {asCheckbox ? (
      <S.CheckboxContainer>
        <CheckboxSkeleton />
        <CheckboxSkeleton />
        <CheckboxSkeleton />
      </S.CheckboxContainer>
    ) : (
      <SS.TagsContainer>
        <LargerTagSkeleton />
        <SkeletonTag />
        <SkeletonTag />
      </SS.TagsContainer>
    )}
  </div>
);
