import { useMemo } from 'react';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import { SEARCH_STATE_DISABLED_SUFFIX } from '@xing-com/crate-jobs-constants';

type Range = { min: string; max: string };
type FilterRangeOptions = Range;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFilterRange = (
  filter: string,
  { min, max }: FilterRangeOptions
) => {
  const { getSearchParameter, deleteSearchParameter } = useSearchParameter();
  const { setSearchParameters } = useSearchParameters();

  const [minParam, maxParam] = getSearchParameter(filter)?.split('_') ?? [
    min,
    max,
  ];
  const value = useMemo(() => [minParam, maxParam], [minParam, maxParam]);

  const valueDisabled =
    getSearchParameter(`${filter}${SEARCH_STATE_DISABLED_SUFFIX}`) ?? '';
  const disabled = valueDisabled === 'true';

  const handleOnRangeChange = (
    { min: newMin, max: newMax }: Range,
    extraParams?: Record<string, string>
  ): void => {
    if (newMin === min && newMax === max && !extraParams?.salary_off) {
      // Range has been cleared
      deleteSearchParameter(filter, { replace: false });
      return;
    }

    setSearchParameters(
      {
        [filter]: `${newMin ?? min}_${newMax ?? max}`,
        ...extraParams,
      },
      { replace: false }
    );
  };

  return { value, disabled, onRangeChange: handleOnRangeChange };
};
