import styled from 'styled-components';

import type { AdFields } from '@xing-com/crate-ads-native-ads';
import { Ad as NativeAd, SiteSections } from '@xing-com/crate-ads-native-ads';
import type {
  WebsiteAdFieldsFragment,
  VideoAdFieldsFragment,
} from '@xing-com/crate-ads-native-ads/src/auction-ads.gql-types';
import type { AdTypes } from '@xing-com/crate-common-graphql-types';
import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceL, spaceM, spaceXL } from '@xing-com/tokens';

type Props = {
  ad: AdFields;
  position: number;
};

type JobsSearchAd = Extract<
  AdFields,
  WebsiteAdFieldsFragment | VideoAdFieldsFragment
>;

type TrackingProps = {
  PropTrackAction: string;
};

const TrackingAdType: Partial<Record<AdTypes, string>> = {
  VideoAd: 'video',
  WebsiteAd: 'text',
};

const XING_DOMAIN = /https:\/\/www.xing.com/;

export const Ad: React.FC<Props> = ({ ad, position }) => {
  const trackNwt = useJobsNwtTracking();
  const { track: trackAdobe } =
    useTracking<InteractionTrackingEvent<TrackingProps>>();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const isInternalAd = XING_DOMAIN.test((ad as JobsSearchAd).targetUrl);

  const nwtTrackingOptions = {
    eventSchema: 'searchresult' as const,
    page: 'jobs_search/serp',
    itemUrn: ad.surn,
    position,
    element: 'xing_ad',
    elementDetail: `${TrackingAdType[ad.type]}_ad`,
  };

  const handleOnImpression = (): void => {
    trackNwt({
      ...nwtTrackingOptions,
      event: 'entered_viewport',
    });
  };

  const handleOnClick = (): void => {
    trackNwt({
      ...nwtTrackingOptions,
      event: isInternalAd ? 'opened' : 'visited',
    });

    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_serp_ad_click',
    });
  };

  return (
    <Container>
      <NativeAd
        sectionId={SiteSections.JobsSearch}
        onClickCallback={handleOnClick}
        onImpressionCallback={handleOnImpression}
        {...ad}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: ${spaceL} 0;

  @media ${mediaWideNavless} {
    margin: ${spaceXL} ${spaceM};
  }
`;
