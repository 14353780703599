import { FormattedMessage, FormattedPlural } from 'react-intl';
import styled from 'styled-components';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { PageHeader as SerpsPageHeader } from '@xing-com/crate-jobs-domain-serps-components-page-header';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';
import { SearchAlertsSearchAnimatedButton } from '@xing-com/crate-jobs-xinglets';
import { useFeatureSwitch } from '@xing-com/hub';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { scale130, spaceL, spaceM } from '@xing-com/tokens';
import { Headline as BaseHeadline } from '@xing-com/typography';

import { LimitedJobCount } from '../../shared';

type HeaderContentProps = { jobCount: number };
const HeaderContent: React.FC<HeaderContentProps> = ({ jobCount }) => {
  const isAiSearch = useIsAiSearch();

  if (jobCount < 0) {
    return <FormattedMessage id="JOBS_SEARCH_AI_RESULTS_HEADLINE" />;
  }

  return (
    <FormattedPlural
      one={
        <FormattedMessage
          id="JOBS_SEARCH_HEADLINE.one"
          values={{ variable_2: jobCount }}
        />
      }
      other={
        <FormattedMessage
          id={
            jobCount > 1
              ? 'JOBS_SEARCH_HEADLINE.other'
              : isAiSearch
                ? 'JOBS_SEARCH_AI_NO_RESULTS_HEADLINE'
                : 'JOBS_SEARCH_HEADLINE.zero'
          }
          values={{ variable_2: <LimitedJobCount count={jobCount} /> }}
        />
      }
      value={jobCount}
    />
  );
};

type ResultsHeaderProps = HeaderContentProps & {
  serps: SerpsRouteParams | null;
  isStickyHeader?: boolean;
};
export const ResultsHeader: React.FC<ResultsHeaderProps> = ({
  jobCount,
  serps,
  isStickyHeader,
}) => {
  const isAiSearch = useIsAiSearch();
  const { isLoggedOut } = useLoginState();
  const isCSSAEnabled = useFeatureSwitch('enabled_jobs_sa_cs');

  return (
    <Container>
      <Headline forwardedAs="h1" noMargin>
        {serps ? (
          <SerpsPageHeader {...serps} jobCount={jobCount} />
        ) : (
          <HeaderContent jobCount={jobCount} />
        )}
      </Headline>
      {(isAiSearch && isLoggedOut) || (isAiSearch && !isCSSAEnabled) ? null : (
        <SearchAlertsSearchAnimatedButton
          isStickyHeader={!!isStickyHeader}
          empty={jobCount === 0}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaceM};
  /* Fixed height required so title is center aligned with animated SA button */
  height: ${scale130};

  @media ${mediaWideNavless} {
    margin-bottom: ${spaceL};
    height: auto;
  }
`;

// For SEO reasons, we need only one h1 on the page, therefore we force the Hero specs on the Headline
const Headline = styled(BaseHeadline)`
  @media ${mediaWideNavless} {
    width: 100%;
    padding: 0px;
    font-size: ${scale130};
    line-height: 1.2;
  }
`;
