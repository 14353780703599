import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceS, space3XL, spaceXL } from '@xing-com/tokens';

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceS};

  @media ${mediaWideNavless} {
    /* On desktop it is displayed under the search hero */
    margin-top: ${spaceXL};
    margin-bottom: ${space3XL};
  }
`;

export const ResetButton = styled(Button)`
  flex-shrink: 0;
`;
