import { useMatch } from '@reach/router';
import type React from 'react';
import { useState } from 'react';

import { Observer } from '@xing-com/crate-communication-in-view';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { ConversationalSearchBar } from '@xing-com/crate-jobs-domain-search-conversational-search-bar';
import { useSerpsMatch } from '@xing-com/crate-jobs-domain-serps-hooks';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';
import { SearchSearchBar } from '@xing-com/crate-jobs-pages-search-search-bar';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { usePageTracking } from '@xing-com/crate-jobs-tracking';
import { AISearchErrorBanner } from '@xing-com/crate-jobs-xinglets';
import { ScrollMemo } from '@xing-com/crate-layout-scroll-memo-module';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { XingletLoader } from '@xing-com/crate-xinglet';
import { LegalFooter } from '@xing-com/platform-layout-footer';

import {
  FilterBottomSheet,
  AllFilters,
  FilterBar,
  Results,
  Header,
  BackButton,
  BackToClassicSearchButton,
} from './components';
import * as S from './container.styles';
import { useAddTypePolicies } from './hooks';

export const Container: React.FC = () => {
  const [isStickyHeader, setIsStickyHeader] = useState(false);
  const isAiSearch = useIsAiSearch();
  const { isLoggedOut } = useLoginState();
  const serps = useSerpsMatch();
  const isSerps = Boolean(isLoggedOut && serps);

  const isJobsDetailsXinglet = useMatch(ROUTES.jobDetails);
  const isSearchXinglet = useMatch(ROUTES.search);
  const isJobDetailsLayout = isJobsDetailsXinglet && !isSearchXinglet;

  useAddTypePolicies();

  usePageTracking({
    nwtOptions: {
      page: 'jobs_search/serp',
      flags: `jobs_search_ai_search_${isAiSearch}`,
    },
  });

  const setHeaderStickiness = (isSticky: boolean) => () => {
    setIsStickyHeader(isSticky);
  };

  return (
    <>
      <ScrollMemo />
      <Observer>
        <S.Background spacious={isAiSearch}>
          <Header isSticky={isStickyHeader} />
          <S.SearchBarContainer variant="regular">
            <div>
              {isAiSearch ? <ConversationalSearchBar /> : <SearchSearchBar />}
              <S.Observed
                onEnter={setHeaderStickiness(false)}
                onLeave={setHeaderStickiness(true)}
              />
            </div>
            <FilterBar />
          </S.SearchBarContainer>
        </S.Background>
        <S.ContentContainer variant="wide">
          <S.Aside>
            <BackButton />
            <AllFilters isSerps={isSerps} />
          </S.Aside>
          <main>
            <Results isStickyHeader={isStickyHeader} />
            {isAiSearch ? <BackToClassicSearchButton /> : null}
          </main>
        </S.ContentContainer>
        {isSerps ? (
          <XingletLoader name="@xing-com/crate-jobs-domain-serps-container" />
        ) : null}
        <NoSSR>
          <FilterBottomSheet />
        </NoSSR>
      </Observer>
      <AISearchErrorBanner />
      {isJobDetailsLayout ? null : (
        <S.FooterWrapper>
          <LegalFooter />
        </S.FooterWrapper>
      )}
    </>
  );
};
