import styled from 'styled-components';

import { JobTeaserListItemSkeleton } from '@xing-com/crate-jobs-xinglets';
import { mediaConfined } from '@xing-com/layout-tokens';
import { SkeletonHeadline } from '@xing-com/skeleton';
import { spaceL, spaceM } from '@xing-com/tokens';

const Headline = styled(SkeletonHeadline)`
  margin-bottom: ${spaceM};
  width: 150px;

  @media ${mediaConfined} {
    margin-bottom: ${spaceL};
    width: 250px;
  }
`;

export const ResultsSkeleton: React.FC = () => {
  return (
    <>
      <Headline size="small" sizeConfined="medium" amountRows={1} />
      {Array.from({ length: 20 }, (_, i) => (
        <JobTeaserListItemSkeleton key={i} />
      ))}
    </>
  );
};
