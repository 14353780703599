import type {
  JobSearchQueryExtractedFilterDisabledInput,
  JobSearchQueryFilterInput,
  JobSearchQueryLocationInput,
} from '@xing-com/crate-common-graphql-types';
import {
  DEFAULT_SORT,
  MAX_SALARY,
  MIN_SALARY,
  SEARCH_STATE_PARAMS,
} from '@xing-com/crate-jobs-constants';
import type { JobSearchByQueryQueryVariables } from '@xing-com/crate-jobs-graphql';

import { useFilterSet, useFilter, useFilterRange } from './query-params';

type UseSearchStateResult = {
  searchQuery: JobSearchByQueryQueryVariables['query'];
  page: Exclude<JobSearchByQueryQueryVariables['offset'], undefined | null>;
  sort?: JobSearchByQueryQueryVariables['sort'];
  filterCount: number;
};

const FULL_REMOTE_FILTER_NAME = 'FULL_REMOTE';

export const isFullRemoteOnlyActive = (options: Set<string>): boolean => {
  if (options.size !== 1) {
    return false;
  }

  // There's only one element, but to avoid hardcoding the scrambled id
  return [...options].some((option) =>
    option.includes(FULL_REMOTE_FILTER_NAME)
  );
};

export const useSearchState = (): UseSearchStateResult => {
  // Inputs
  const { value: keywords } = useFilter(SEARCH_STATE_PARAMS.keywords);
  const { value: location, disabled: disabledLocation } = useFilter(
    SEARCH_STATE_PARAMS.location
  );
  const { value: cityId } = useFilter(SEARCH_STATE_PARAMS.cityId);
  const { value: radius } = useFilter(SEARCH_STATE_PARAMS.radius);
  // Filters
  const { value: benefit } = useFilterSet(SEARCH_STATE_PARAMS.benefit);
  const { value: careerLevel, disabled: disabledCareerLevel } = useFilterSet(
    SEARCH_STATE_PARAMS.careerLevel
  );
  const { value: city } = useFilterSet(SEARCH_STATE_PARAMS.city);
  const { value: company } = useFilterSet(SEARCH_STATE_PARAMS.company);
  const { value: country } = useFilterSet(SEARCH_STATE_PARAMS.country);
  const { value: discipline } = useFilterSet(SEARCH_STATE_PARAMS.discipline);
  const { value: employmentType, disabled: disabledEmploymentType } =
    useFilterSet(SEARCH_STATE_PARAMS.employmentType);
  const { value: industry } = useFilterSet(SEARCH_STATE_PARAMS.industry);
  const { value: remoteOption, disabled: disabledRemoteOption } = useFilterSet(
    SEARCH_STATE_PARAMS.remoteOption
  );
  const { value: salary, disabled: disabledSalary } = useFilterRange(
    SEARCH_STATE_PARAMS.salary,
    { min: `${MIN_SALARY}`, max: `${MAX_SALARY}` }
  );
  // Ordering
  const { value: page } = useFilter(SEARCH_STATE_PARAMS.page);
  const { value: sort } = useFilter(SEARCH_STATE_PARAMS.sort);

  const locationFilter: JobSearchQueryLocationInput = {
    ...(cityId && { cityId }),
    ...(location && { text: location }),
    ...(radius && { radius: Number(radius) }),
  };

  const isDefaultSalary =
    salary[0] === `${MIN_SALARY}` && salary[1] === `${MAX_SALARY}`;
  const isDefaultSort = !sort || sort === DEFAULT_SORT;

  const filters: JobSearchQueryFilterInput = {
    ...(benefit.size > 0 && { benefit: { id: [...benefit] } }),
    ...(careerLevel.size > 0 && { careerLevel: { id: [...careerLevel] } }),
    ...(city.size > 0 && { city: { id: [...city] } }),
    ...(company.size > 0 && { company: { id: [...company] } }),
    ...(country.size > 0 && { country: { id: [...country] } }),
    ...(discipline.size > 0 && { discipline: { id: [...discipline] } }),
    ...(employmentType.size > 0 && {
      employmentType: { id: [...employmentType] },
    }),
    ...(industry.size > 0 && { industry: { id: [...industry] } }),
    ...(remoteOption.size > 0 && { remoteOption: { id: [...remoteOption] } }),
    ...(!isDefaultSalary && {
      salary: { min: Number(salary[0]), max: Number(salary[1]) },
    }),
  };

  const disabledFilters: JobSearchQueryExtractedFilterDisabledInput = {
    ...(disabledRemoteOption.size > 0 && {
      remoteOption: { id: [...disabledRemoteOption] },
    }),
    ...(disabledCareerLevel.size > 0 && {
      careerLevel: { id: [...disabledCareerLevel] },
    }),
    ...(disabledEmploymentType.size > 0 && {
      employmentType: { id: [...disabledEmploymentType] },
    }),
    ...(disabledLocation.size > 0 && {
      location: { name: [...disabledLocation] },
    }),
    ...(disabledSalary && { salary: true }),
  };

  const searchQuery: JobSearchByQueryQueryVariables['query'] = {
    ...(keywords && { keywords }),
    ...(Object.keys(locationFilter).length > 0 && { location: locationFilter }),
    ...(Object.keys(filters).length > 0 && { filter: filters }),
    ...(Object.keys(disabledFilters).length > 0 && {
      extractedFilter: { disabled: disabledFilters },
    }),
  };

  // Special case for FULL_REMOTE filter, which disables the location when active
  if (isFullRemoteOnlyActive(remoteOption)) {
    delete searchQuery.location;
  }

  const filterCount =
    employmentType.size +
    careerLevel.size +
    discipline.size +
    industry.size +
    benefit.size +
    country.size +
    city.size +
    (isDefaultSalary ? 0 : 1) +
    remoteOption.size +
    (company.size > 0 ? 1 : 0) +
    (location ? 1 : 0) +
    (isDefaultSort ? 0 : 1);

  return {
    searchQuery,
    page: page ? +page : 1,
    ...(sort && { sort }),
    filterCount,
  };
};
