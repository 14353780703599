import { FormattedMessage, FormattedPlural } from 'react-intl';
import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import { SMALL_SCREEN_BOTTOM_SHEET_PARAM } from '@xing-com/crate-jobs-constants';
import {
  trackingFilterName,
  useSearchTracking,
} from '@xing-com/crate-jobs-tracking';
import { dropShadowS, spaceXL } from '@xing-com/tokens';

import { useSearch } from '../../../hooks';
import { LimitedJobCount } from '../../shared';
import type { Views } from '../filter-bottom-sheet/filter-switcher';

const StyledButton = styled(Button)<{ $withShadow?: boolean }>`
  position: sticky;
  margin: 0 auto;
  bottom: ${spaceXL};

  ${({ $withShadow }) =>
    $withShadow &&
    css`
      box-shadow: ${dropShadowS};
    `}
`;

type Props = {
  activeView: Views;
  isSecondLevel: boolean;
  trackPage: () => void;
  isNewSearch: boolean;
};

export const ShowResultsButton: React.FC<Props> = ({
  activeView,
  isSecondLevel,
  trackPage,
  isNewSearch,
}) => {
  const { setSearchParameters } = useSearchParameters();
  const { isLoading, results } = useSearch();
  const { trackFilterClose } = useSearchTracking();

  const action = isNewSearch ? 'apply' : 'close';
  const withShadow =
    activeView === 'discipline' || activeView === 'all' || isSecondLevel;

  const handleOnClick = (): void => {
    const filter = activeView.startsWith('all') ? 'all_filters' : activeView;
    const filterName = trackingFilterName[filter] || filter;

    // Nwt
    trackFilterClose(filter, action);

    // Adobe
    setSearchParameters(
      {
        [SMALL_SCREEN_BOTTOM_SHEET_PARAM]: '',
        sc_o: `jobs_search_filter_${filterName}_${action}`,
        sc_o_PropActionOrigin: `jobs_search_filter_${filterName}`,
      },
      { removeEmpty: true }
    );
    trackPage();
  };

  return (
    <>
      <StyledButton
        loading={isLoading}
        variant={isNewSearch ? 'primary' : 'tertiary'}
        onClick={handleOnClick}
        $withShadow={withShadow}
        data-testid={`show-results-button-${action}`}
      >
        {results?.total === -1 ? (
          <FormattedMessage id="JOBS_SEARCH_AI_FILTERS_SHOW_RESULTS_BUTTON" />
        ) : (
          <FormattedPlural
            value={results?.total ?? 0}
            one={
              <FormattedMessage id="JOBS_SEARCH_FILTERS_SHOW_RESULTS_BUTTON.one" />
            }
            other={
              <FormattedMessage
                id="JOBS_SEARCH_FILTERS_SHOW_RESULTS_BUTTON.other"
                values={{
                  variable_2: <LimitedJobCount count={results?.total ?? 0} />,
                }}
              />
            }
          />
        )}
      </StyledButton>
    </>
  );
};
