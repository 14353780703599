import styled from 'styled-components';

import { mediaTopBarVisible, mediaWide } from '@xing-com/layout-tokens';
import { spaceS, spaceL, spaceXL } from '@xing-com/tokens';

import { ProJobsUpsellBanner as ProJobsUpsellBannerComponent } from './projobs-upsell-banner';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const MediaConfined = styled.div`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const ProJobsUpsellBanner = styled(ProJobsUpsellBannerComponent)`
  margin: ${spaceS} 0;
  padding: ${spaceL};

  @media ${mediaWide} {
    margin: ${spaceXL} 0;
    padding: ${spaceL} ${spaceXL};
  }
`;
