import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_ALERTS_ERROR_TYPE,
  SEARCH_ALERTS_ERROR_BANNER,
} from '@xing-com/crate-jobs-constants';

export const useShowSearchAlertErrorBanner = (
  type: keyof typeof SEARCH_ALERTS_ERROR_TYPE
): (() => void) => {
  const { setSearchParameter } = useSearchParameter();

  return () => {
    setSearchParameter(
      SEARCH_ALERTS_ERROR_BANNER,
      SEARCH_ALERTS_ERROR_TYPE[type]
    );
  };
};
