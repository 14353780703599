import { FormattedMessage } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useRecommendations } from '@xing-com/crate-jobs-hooks';
import { Headline } from '@xing-com/typography';

import { useSearchConsumer } from '../../../hooks/use-search-consumer';
import { ResultsList } from '../results-list';
import { MoreResultsSkeleton } from './more-results-skeleton';
import * as S from './more-results.styles';

export const MoreRecommendations: React.FC = () => {
  const consumer = useSearchConsumer();
  const { isLoggedIn } = useLoginState();
  const { recommendations, isLoading, hasData, isEmpty } = useRecommendations({
    consumer,
    skip: !isLoggedIn,
  });

  if (!isLoggedIn) return null;
  if (isLoading) return <MoreResultsSkeleton />;
  if (!hasData || isEmpty) return null;

  const slugList = recommendations.map((item) => item.job.slug);

  return (
    <S.Container>
      <Headline size="medium" sizeWide="large">
        <FormattedMessage id="JOBS_SEARCH_EMPTY_STATE_RECOMMENDATIONS_HEADLINE" />
      </Headline>
      <ResultsList
        items={recommendations}
        type="EMPTY_STATE_RECOS"
        slugList={slugList}
      />
    </S.Container>
  );
};
