/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const SingleSizeIllustrationFeedbackDefaultNeutral = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 42"
    data-xds="SingleSizeIllustrationFeedbackDefaultNeutral"
    role="presentation"
    {...props}
  >
    <g clipPath="url(#clip0_10198_366)">
      <path
        fill="var(--xdlColorIllustrationFillTertiaryGrayPlain)"
        fillRule="evenodd"
        d="M42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        d="M27.115 13.309a.75.75 0 0 1 .744.648l.006.102v1.833a.75.75 0 0 1-1.493.101l-.007-.101v-1.833a.75.75 0 0 1 .75-.75M15.308 2.137l.454.003c3.871.1 6.345 2.125 6.412 6.487l.02 1.726.01 1.237c.008 1.4.009 2.861.004 4.328l-.025 4.038-.009.862a.75.75 0 0 1-1.5-.018l.018-1.964.016-2.921c.003-.976.004-1.949.002-2.902l-.01-2.039-.01-1.197-.016-1.127c-.053-3.48-1.823-4.93-4.95-5.01-1.202-.032-2.507.152-3.81.479q-.477.12-.894.248l-.488.157-.205.073a.75.75 0 0 1-.533-1.402l.362-.127.13-.043c.381-.123.806-.246 1.263-.361 1.266-.318 2.54-.513 3.759-.527m.453 11.172a.75.75 0 0 1 .743.648l.006.102v1.833a.75.75 0 0 1-1.493.101l-.006-.101v-1.833a.75.75 0 0 1 .75-.75m7.577 12.298a.75.75 0 0 1 .102 1.494l-.102.006h-3.8a.75.75 0 0 1-.102-1.493l.101-.007z"
      />
    </g>
    <defs>
      <clipPath id="clip0_10198_366">
        <path fill="#fff" d="M0 0h42v42H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SingleSizeIllustrationFeedbackDefaultNeutral;
