import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { mediaTopBarVisible, mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';
import { Headline as BaseHeadline } from '@xing-com/typography';

export const Headline = styled(BaseHeadline)`
  display: flex;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: column;
  gap: ${spaceL};
`;

export const ShowMoreButtonSmall = styled(TextButton)`
  width: fit-content;

  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const ShowMoreButtonConfined = styled(TextButton)`
  width: fit-content;

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;
