import { useLocation } from '@reach/router';
import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  CITY,
  EMPLOYMENT_TYPE,
  ROLE,
  SKILL,
  TRANSLATIONS_MAP,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';
import {
  capitalizeFirstLetter,
  employmentTypeSlugToLocalizedKey,
  getCategoryTranslation,
  slugify,
} from '@xing-com/crate-jobs-domain-serps-helpers';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';
import { Link as LinkComponent } from '@xing-com/link';
import { xdlColorText } from '@xing-com/tokens';

export const Link = styled(LinkComponent)`
  color: ${xdlColorText};
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
  }
`;

interface PageHeaderProps extends SerpsRouteParams {
  jobCount?: number;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  category,
  categoryValue,
  subcategory,
  subcategoryValue,
  jobCount = 0,
}: PageHeaderProps) => {
  const { search } = useLocation();
  const radius = new URLSearchParams(search).get('radius');
  const { formatMessage, locale } = useIntl();

  const hasSubcategory = subcategory && subcategoryValue;
  const isEmploymentTypeSingleCategory =
    !hasSubcategory && category === EMPLOYMENT_TYPE;
  const isSkillCategory = category === SKILL;

  const { title, titles, paramName, subparamName } =
    TRANSLATIONS_MAP[hasSubcategory ? `${category}-${subcategory}` : category];

  const titleKey =
    isEmploymentTypeSingleCategory && titles
      ? titles[employmentTypeSlugToLocalizedKey(categoryValue)]
      : title;

  const categoryTranslation = getCategoryTranslation(
    formatMessage,
    category,
    categoryValue
  );

  const values = {
    [paramName]: isSkillCategory
      ? capitalizeFirstLetter(categoryTranslation)
      : categoryTranslation,
    ...(hasSubcategory &&
      subparamName && {
        [subparamName]: getCategoryTranslation(
          formatMessage,
          subcategory,
          subcategoryValue
        ),
      }),
  };

  const isLocalJobRoleSerp =
    category === ROLE && subcategory === CITY && hasSubcategory;
  const localJobRoleSerpsValues = isLocalJobRoleSerp
    ? {
        role: (
          <Link
            to={`/jobs/${SERP_ROUTES_IDENTIFIERS.role}-${slugify(
              categoryValue
            )}`}
          >
            {capitalizeFirstLetter(categoryValue)}
          </Link>
        ),
        city: (
          <Link
            to={`/jobs/${SERP_ROUTES_IDENTIFIERS.city}-${slugify(
              subcategoryValue
            )}`}
          >
            {capitalizeFirstLetter(subcategoryValue)}
          </Link>
        ),
      }
    : {};

  const isLocalEmploymentTypeSerp =
    category === EMPLOYMENT_TYPE && subcategory === CITY && hasSubcategory;
  const localEmploymentTypeSerpsValues = isLocalEmploymentTypeSerp
    ? {
        employmentType: capitalizeFirstLetter(
          getCategoryTranslation(formatMessage, category, categoryValue)
        ),
        city: capitalizeFirstLetter(
          getCategoryTranslation(formatMessage, subcategory, subcategoryValue)
        ),
      }
    : {};

  let titleKeyOverride = '';
  const isAushilfeEmploymentTypeSerp =
    category === EMPLOYMENT_TYPE &&
    subcategory === ROLE &&
    values.job_type.includes('Aushilfe');
  const aushilfeEmploymentTypeSerpValues =
    isAushilfeEmploymentTypeSerp && subcategoryValue
      ? {
          category: capitalizeFirstLetter(
            getCategoryTranslation(formatMessage, subcategory, subcategoryValue)
          ),
        }
      : {};
  const isStudentEmploymentType =
    categoryValue === 'student' && subcategory === CITY;
  const isFreelancerEmploymentType =
    categoryValue === 'freiberuflich' && subcategory === CITY;

  if (isStudentEmploymentType && locale === 'de') {
    titleKeyOverride = 'XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY_STUDENTEN';
  }

  if (isFreelancerEmploymentType && locale === 'de') {
    titleKeyOverride = 'XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY_FREELANCER';
  }

  if (isAushilfeEmploymentTypeSerp && locale === 'de') {
    titleKeyOverride = 'XJM_SEARCH_TITLE_JOB_TYPE_CATEGORY_MINIJOBS';
  }

  const HeaderValues: React.FC = () => (
    <>
      <FormattedMessage
        id={titleKeyOverride ? titleKeyOverride : titleKey}
        values={{
          ...values,
          ...localJobRoleSerpsValues,
          ...localEmploymentTypeSerpsValues,
          ...aushilfeEmploymentTypeSerpValues,
        }}
      />{' '}
      {radius ? (
        <FormattedMessage id="XJM_SEO_RADIUS" values={{ radius }} />
      ) : null}
    </>
  );

  return (
    <>
      {jobCount ? (
        <>
          <span>{jobCount} </span>
          <HeaderValues />
        </>
      ) : (
        <>
          <FormattedMessage id="JOBS_SEARCH_EMPTY_STATE_NO_RESULTS_TITLE" />{' '}
          <FormattedMessage id="JOBS_SEARCH_EMPTY_STATE_NO_RESULTS_FOR_LABEL" />{' '}
          <HeaderValues />
        </>
      )}
    </>
  );
};
