import { Helmet } from 'react-helmet-async';

export const SearchMetadata = (): JSX.Element => (
  <>
    <Helmet>
      <meta name="robots" content="noindex, follow" />
      <meta name="theme-color" content="#C6F16D" />
      {/* TODO: Check with CoP, missing PWA support, splash screen */}
      {/* <meta name="apple-mobile-web-app-capable" content="yes" /> */}
    </Helmet>
  </>
);
