import { useLocation, useNavigate } from '@reach/router';
import { useEffect, useRef, useState } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import {
  AI_SEARCH_ERROR_BANNER,
  AI_SEARCH_ERROR_TYPE,
  SEARCH_STATE_FILTER_PARAMS,
  SEARCH_STATE_PARAMS,
  SEARCH_QUERY_ID_PARAM,
  SEARCH_STATE_DISABLED_FILTER_PARAMS,
  SEARCH_STATE_DISABLED_SUFFIX,
  AI_SWIMP_TRACKING_TOKEN_PARAM,
} from '@xing-com/crate-jobs-constants';
import { useSerpsMatch } from '@xing-com/crate-jobs-domain-serps-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

import { useFilter, useFilterSet } from '../query-params';
import { useIsAiSearch } from '../use-is-ai-search';
import { useSearchLocation } from '../use-search-location';
import { isFullRemoteOnlyActive } from '../use-search-state';

/**
 * Optional values that you can pass to the onSubmit function that will
 * override the actual values
 */
type ForcedSubmitValues = {
  keywords?: string;
};

const getFilteredSearchParams = (
  params: Record<string, string>
): URLSearchParams => {
  const searchParams = new URLSearchParams(params);

  Object.entries(params).forEach(([name, value]) => {
    if (!value) {
      searchParams.delete(name);
    }
  });

  return searchParams;
};

// inferred return types
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSearchBar = ({ isAiLanding = false } = {}) => {
  const isAiSearch = useIsAiSearch();
  const trackNwt = useJobsNwtTracking();
  const navigate = useNavigate();
  const routerLocation = useLocation();

  const { setSearchParameters } = useSearchParameters();

  const { isLoggedOut } = useLoginState();
  const serps = useSerpsMatch();
  const isSerps = serps && isLoggedOut;

  const { value: keywordsParam } = useFilter(SEARCH_STATE_PARAMS.keywords);
  const { value: locationParam } = useFilter(SEARCH_STATE_PARAMS.location);
  const { value: cityIdParam } = useFilter(SEARCH_STATE_PARAMS.cityId);
  const { value: remoteOptionParam } = useFilterSet(
    SEARCH_STATE_PARAMS.remoteOption
  );

  // SERPs-related params
  const { value: disciplineParam } = useFilter(SEARCH_STATE_PARAMS.discipline);
  const { value: employmentTypeParam } = useFilter(
    SEARCH_STATE_PARAMS.employmentType
  );
  const { value: searchQueryIdParam } = useFilter(SEARCH_QUERY_ID_PARAM);

  const [keywords, setKeywords] = useState(keywordsParam ?? '');
  const shouldResetFilters = useRef(false);

  const disableResetFilters = (): void => {
    shouldResetFilters.current = false;
  };

  useEffect(() => {
    // while on a SERP page, keywordsParam isn't available on first render
    if (!keywords && keywordsParam) {
      setKeywords(keywordsParam);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsParam]);

  const { location, cityId, onChangeLocation, ...useSearchLocationResult } =
    useSearchLocation({
      initialLocation: locationParam,
      initialCityId: cityIdParam,
      skipBusinessCityQuery: true,
      onChange: disableResetFilters,
    });

  // on every location change, we reset the query dirtiness
  useEffect(() => {
    disableResetFilters();
  }, [routerLocation]);

  const handleOnChangeKeywords = (value: string): void => {
    shouldResetFilters.current = true;
    setKeywords(value);
  };

  const handleOnSubmit = (force: ForcedSubmitValues = {}): void => {
    // In case the keywords is empty, we display an error banner
    if (!keywords && (isAiLanding || isAiSearch)) {
      setSearchParameters(
        { [AI_SEARCH_ERROR_BANNER]: AI_SEARCH_ERROR_TYPE.EMPTY },
        { replace: true }
      );

      return;
    }

    const shouldResetAllFilters =
      isAiSearch &&
      (shouldResetFilters.current ||
        routerLocation.search.includes(SEARCH_STATE_DISABLED_SUFFIX));

    const params = {
      [SEARCH_STATE_PARAMS.keywords]: force.keywords ?? keywords,
      [SEARCH_STATE_PARAMS.location]: location,
      [SEARCH_STATE_PARAMS.cityId]: cityId,
      // If we are in an AI search and we change the query, we need to reset all the filters
      ...(shouldResetAllFilters && {
        ...Object.fromEntries(
          Object.values(SEARCH_STATE_FILTER_PARAMS).map((param) => [param, ''])
        ),
        ...Object.fromEntries(
          Object.values(SEARCH_STATE_DISABLED_FILTER_PARAMS).map((param) => [
            `${param}${SEARCH_STATE_DISABLED_SUFFIX}`,
            '',
          ])
        ),
      }),
    };
    const paramsWithTracking = {
      ...params,
      sc_o: 'jobs_search_button',
    };

    if (isAiLanding) {
      trackNwt({
        event: 'opened',
        page: 'jobs/ai_search',
        eventSchema: 'aicomponent',
        componentName: 'ai_search',
        element: 'search_button',
      });

      // As we are not in a search page, we need to change the route
      const searchParams = getFilteredSearchParams(paramsWithTracking);

      searchParams.set('sc_o', 'jobs_search_button');
      searchParams.set('sc_o_PropActionOrigin', 'jobs_ai_search');

      // Check if there's a suggestion tracking token
      const suggestionTrackingToken = new URLSearchParams(
        routerLocation.search
      ).get(AI_SWIMP_TRACKING_TOKEN_PARAM);
      if (suggestionTrackingToken) {
        searchParams.set(
          AI_SWIMP_TRACKING_TOKEN_PARAM,
          suggestionTrackingToken
        );
      }

      navigate(`${ROUTES.searchAi}?${searchParams.toString()}`);
      return;
    }

    setSearchParameters(paramsWithTracking, { removeEmpty: true });

    // Manually reset the location
    if (!location) {
      onChangeLocation('');
    }

    // if it's a SERP page and "submit" was clicked, redirect to the search page
    if (isSerps) {
      const serpsParams = {
        ...paramsWithTracking,
        ...(disciplineParam && {
          [SEARCH_STATE_PARAMS.discipline]: disciplineParam,
        }),
        ...(employmentTypeParam && {
          [SEARCH_STATE_PARAMS.employmentType]: employmentTypeParam,
        }),
        ...(searchQueryIdParam && {
          [SEARCH_QUERY_ID_PARAM]: searchQueryIdParam,
        }),
      };

      navigate(
        `${ROUTES.search}?${getFilteredSearchParams(serpsParams).toString()}`,
        {
          replace: true,
        }
      );
    }
  };

  return {
    keywords,
    location,
    isLocationDisabled: isFullRemoteOnlyActive(remoteOptionParam),
    onChangeKeywords: handleOnChangeKeywords,
    onChangeLocation,
    ...useSearchLocationResult,
    onSubmit: handleOnSubmit,
  };
};
