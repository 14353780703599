/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateSearchAlertMutationVariables = Types.Exact<{
  queryId: Types.Scalars['SlugOrID']['input'];
}>;

export type CreateSearchAlertMutation = {
  __typename?: 'Mutation';
  createSearchAlert?:
    | {
        __typename: 'SearchAlertCreationSuccess';
        alert: {
          __typename?: 'SearchAlert';
          id: string;
          globalId: string;
          queryId: string;
          name: string;
          newJobCount?: number | null;
          hasNewJobs?: boolean | null;
          createdAt?: string | null;
          query?: {
            __typename?: 'JobSearchQuery';
            body?: {
              __typename?: 'JobSearchQueryBody';
              keywords?: string | null;
              location?: {
                __typename?: 'JobSearchQueryLocation';
                text?: string | null;
                radius?: number | null;
                city?: {
                  __typename?: 'City';
                  id: string;
                  name?: string | null;
                } | null;
              } | null;
              filterCollection?: Array<
                | {
                    __typename?: 'JobFilterBenefit';
                    entityId: string;
                    benefit?: {
                      __typename?: 'KununuBenefit';
                      localizationValue: string;
                    } | null;
                  }
                | { __typename?: 'JobFilterBenefitEmployeePerk' }
                | { __typename?: 'JobFilterBenefitWorkingCulture' }
                | {
                    __typename?: 'JobFilterCareerLevel';
                    entityId: string;
                    careerLevel?: {
                      __typename?: 'CareerLevel';
                      localizationValue: string;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterCity';
                    entityId: string;
                    city?: {
                      __typename?: 'City';
                      localizationValue?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterCompany';
                    entityId: string;
                    company?: {
                      __typename?: 'Company';
                      companyName?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterCountry';
                    entityId: string;
                    country?: {
                      __typename?: 'Country';
                      localizationValue: string;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterDiscipline';
                    entityId: string;
                    discipline?: {
                      __typename?: 'Discipline';
                      localizationValue: string;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterEmploymentType';
                    entityId: string;
                    employmentType?: {
                      __typename?: 'EmploymentType';
                      localizationValue: string;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterIndustry';
                    entityId: string;
                    industry?: {
                      __typename?: 'Industry';
                      localizationValue: string;
                    } | null;
                  }
                | { __typename?: 'JobFilterProjob' }
                | { __typename?: 'JobFilterPublishToCompany' }
                | { __typename?: 'JobFilterRemote' }
                | {
                    __typename?: 'JobFilterRemoteOption';
                    entityId: string;
                    remoteOption?: {
                      __typename?: 'JobRemoteOption';
                      localizationValue: string;
                    } | null;
                  }
                | {
                    __typename?: 'JobFilterSalary';
                    min?: number | null;
                    max?: number | null;
                  }
                | null
              > | null;
            } | null;
          } | null;
        };
      }
    | {
        __typename: 'SearchAlertErrorResponse';
        code?: Types.JobSearchAlertsDomainError | null;
      }
    | null;
};

export const CreateSearchAlertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSearchAlert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'queryId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSearchAlert' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'queryId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'queryId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SearchAlertCreationSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'alert' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SearchAlert' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SearchAlertErrorResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RemoteOptionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterRemoteOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remoteOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCompany' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmploymentTypeFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterEmploymentType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CareerLevelFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCareerLevel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careerLevel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DisciplineFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterDiscipline' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discipline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndustryFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterIndustry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BenefitFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterBenefit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'benefit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCountry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CityFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'city' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'localizationValue' },
                  name: { kind: 'Name', value: 'name' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterSalary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobSearchFilterCollection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobFilterCollection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'RemoteOptionFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CompanyFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'EmploymentTypeFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CareerLevelFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'DisciplineFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndustryFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BenefitFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CountryFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CityFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SalaryFilter' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobSearchQueryBody' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobSearchQueryBody' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'radius' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'city' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filterCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobSearchFilterCollection' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchAlert' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchAlert' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'queryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'query' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'body' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobSearchQueryBody' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newJobCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNewJobs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSearchAlertMutation,
  CreateSearchAlertMutationVariables
>;
