import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import {
  ORIGIN_PARAM,
  ORIGIN_PARAM_VALUES,
  REFERRER_ALERT_ID_PARAM,
  SMALL_SCREEN_BOTTOM_SHEET_PARAM,
} from '@xing-com/crate-jobs-constants';
import { VisitSearchAlertDocument } from '@xing-com/crate-jobs-graphql';

import { zeroifySearchAlertsNewJobCount } from './apollo-helpers';

export const useMarkSearchAlertAsVisited = (
  searchAlertId: string | null
): void => {
  const { getSearchParameters } = useSearchParameters();
  const [visitSearchAlertMutation] = useMutation(VisitSearchAlertDocument);

  const { [ORIGIN_PARAM]: origin, [REFERRER_ALERT_ID_PARAM]: referrerAlertId } =
    getSearchParameters([SMALL_SCREEN_BOTTOM_SHEET_PARAM, ORIGIN_PARAM]);
  const markAlertAsVisited =
    origin === ORIGIN_PARAM_VALUES.searchAlerts ||
    origin === ORIGIN_PARAM_VALUES.searchAlertsFindJobs ||
    !!referrerAlertId;

  useEffect(() => {
    if (searchAlertId && markAlertAsVisited) {
      visitSearchAlertMutation({
        variables: { id: searchAlertId },
        update: zeroifySearchAlertsNewJobCount(searchAlertId),
      });
    }
  }, [searchAlertId, markAlertAsVisited, visitSearchAlertMutation]);
};
