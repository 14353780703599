import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { AI_SEARCH_FEEDBACK_SURVERY_URL } from '@xing-com/crate-jobs-constants';
import { InViewTracking } from '@xing-com/crate-jobs-tracking';
import { useViewerData } from '@xing-com/hub';
import { BodyCopy } from '@xing-com/typography';

import * as S from './ai-feedback.styles';
import type { FeedbackVariant } from './color-feedback';
import { FeedbackContent } from './feedback-content';

type Props = {
  searchId: string;
  trackingToken?: string;
};

export const AIFeedback: React.FC<Props> = ({ searchId, trackingToken }) => {
  const [showSurvey, setShowSurvey] = useState(false);
  const { data } = useViewerData();
  const hashedId = data?.hashedUserId ?? '';
  const urlParams = new URLSearchParams({
    h: hashedId,
    search_id: searchId,
  });
  const surveyUrl = hashedId
    ? `${AI_SEARCH_FEEDBACK_SURVERY_URL}?${urlParams.toString()}`
    : AI_SEARCH_FEEDBACK_SURVERY_URL;

  const handleOnClick = (feedback: FeedbackVariant): void => {
    if (feedback === 'dislike') {
      setShowSurvey(true);
    }
  };

  const nwtTrackingOptions = {
    eventSchema: 'aicomponent',
    page: 'jobs_search/serp',
    event: 'entered_viewport',
    componentName: 'ai_search',
    componentFeedbackLevel: '2',
    trackingToken,
  } as const;

  return (
    <InViewTracking nwtTrackingOptions={nwtTrackingOptions}>
      <S.Card>
        <S.CardContent>
          {!showSurvey ? (
            <FeedbackContent
              onClick={handleOnClick}
              trackingToken={trackingToken}
            />
          ) : (
            <S.SadFeedbackContainer>
              <BodyCopy noMargin>
                <FormattedMessage id="JOBS_SEARCH_AI_FEEDBACK_BAD_COPY" />
              </BodyCopy>
              <Button
                variant="secondary"
                href={surveyUrl}
                // @ts-expect-error FIXME: SC6
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage id="JOBS_SEARCH_AI_FEEDBACK_BAD_BUTTON" />
              </Button>
            </S.SadFeedbackContainer>
          )}
        </S.CardContent>
      </S.Card>
    </InViewTracking>
  );
};
