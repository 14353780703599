import styled from 'styled-components';

import { mediaConfined, mediaTopBarHidden } from '@xing-com/layout-tokens';
import { SkeletonHeadline } from '@xing-com/skeleton';
import { space3XL, space5XL, spaceL, spaceM } from '@xing-com/tokens';

export const Container = styled.div`
  margin-top: ${space3XL};

  @media ${mediaTopBarHidden} {
    margin-top: ${space5XL};
  }
`;

export const HeadlineSkeleton = styled(SkeletonHeadline)`
  margin-bottom: ${spaceM};
  width: 150px;

  @media ${mediaConfined} {
    margin-bottom: ${spaceL};
    width: 250px;
  }
`;
