import { FormattedMessage } from 'react-intl';

import {
  DEFAULT_SORT,
  SEARCH_STATE_PARAMS,
} from '@xing-com/crate-jobs-constants';
import { useFilter } from '@xing-com/crate-jobs-hooks';
import { Tag } from '@xing-com/tag';
import { BodyCopy } from '@xing-com/typography';

import * as S from '../shared.styles';

const SORT_OPTIONS = {
  relevance: <FormattedMessage id="JOBS_SEARCH_SORT_ORDER_RELEVANCE" />,
  date: <FormattedMessage id="JOBS_SEARCH_SORT_ORDER_NEWEST" />,
  rating: <FormattedMessage id="JOBS_SEARCH_SORT_ORDER_TOP_RATED" />,
};

export const SortFilter: React.FC = () => {
  const { value: sort, onChange } = useFilter(SEARCH_STATE_PARAMS.sort);

  const handleOnClick = (option: string) => (): void => {
    onChange(option);
  };

  return (
    <div>
      <BodyCopy fontWeight="bold" data-testid="all-filters-filter">
        <FormattedMessage id="JOBS_SEARCH_SORT_TITLE" />
      </BodyCopy>
      <S.TagsContainer>
        {Object.entries(SORT_OPTIONS).map(([option, text]) => (
          <Tag
            key={option}
            selected={option === (sort ?? DEFAULT_SORT)}
            onClick={handleOnClick(option)}
          >
            {text}
          </Tag>
        ))}
      </S.TagsContainer>
    </div>
  );
};
