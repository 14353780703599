import { useLocation, useNavigate } from '@reach/router';
import { useCallback } from 'react';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_FILTER_PARAMS,
  SEARCH_STATE_PARAMS,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';
import { FILTER_TYPENAME_TO_PARAM } from '@xing-com/crate-jobs-domain-search-maps';
import type { ExtractedFiltersEnabledFragment } from '@xing-com/crate-jobs-graphql';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

type UseResetFiltersResult = (opts: {
  origin: string;
  context: string;
  trackWithAdobeAction?: boolean;
  extractedFilters?: (ExtractedFiltersEnabledFragment | null)[];
}) => () => void;

type AdobeTrackingProps = {
  PropTrackAction: string;
  PropActionOrigin: string;
};

export const useResetFilters: UseResetFiltersResult = ({
  origin,
  context,
  trackWithAdobeAction = false,
  extractedFilters,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const trackNwt = useJobsNwtTracking();
  const { track: trackAdobe } =
    useTracking<InteractionTrackingEvent<AdobeTrackingProps>>();
  const { getSearchParameter } = useSearchParameter();

  const resetUrlTracking = {
    sc_o: 'jobs_search_filter_reset',
    sc_o_PropActionOrigin: origin,
  };

  const resetSearchFilters = useCallback(() => {
    const search = new URLSearchParams(location.search);
    Object.values(SEARCH_STATE_FILTER_PARAMS).forEach((param) => {
      search.delete(param);
    });
    search.delete(SEARCH_STATE_PARAMS.location);
    search.delete(SEARCH_STATE_PARAMS.radius);
    search.delete(SEARCH_STATE_PARAMS.cityId);

    extractedFilters?.forEach((filter) => {
      if (!filter || !filter.__typename) return;

      if (filter.__typename === 'JobFilterSalary') {
        if (typeof filter.min === 'number' || typeof filter.max === 'number') {
          const disabledParamName = `${SEARCH_STATE_PARAMS.salary}${SEARCH_STATE_DISABLED_SUFFIX}`;
          search.set(disabledParamName, 'true');
        }
        return;
      }

      const paramName =
        filter.__typename === 'JobFilterLocation'
          ? SEARCH_STATE_PARAMS.location
          : FILTER_TYPENAME_TO_PARAM[filter.__typename];
      const disabledParamName = `${paramName}${SEARCH_STATE_DISABLED_SUFFIX}`;
      const currentParam = getSearchParameter(disabledParamName);
      const currentSearch = search.get(disabledParamName);
      const current = new Set([
        ...(currentParam ? currentParam.split(SEARCH_STATE_SEPARATOR) : []),
        ...(currentSearch ? currentSearch.split(SEARCH_STATE_SEPARATOR) : []),
      ]);

      const value =
        filter.__typename === 'JobFilterLocation'
          ? filter.location?.localizationValue
          : // @ts-expect-error entityId is not in JobFilterLocation
            filter?.entityId;

      if (value) {
        if (!current) {
          search.set(disabledParamName, value);
        } else {
          current.add(value);
          search.set(
            disabledParamName,
            [...current].join(SEARCH_STATE_SEPARATOR)
          );
        }
      }
    });

    if (trackWithAdobeAction) {
      trackAdobe({
        type: 'interaction',
        event: 'PropTrackAction',
        PropTrackAction: resetUrlTracking.sc_o,
        PropActionOrigin: origin,
      });
    } else {
      Object.entries(resetUrlTracking).forEach(([key, value]) => {
        search.set(key, value);
      });
    }

    // Send tracking
    trackNwt({
      eventSchema: 'basic',
      event: 'clicked',
      page: 'jobs_search/serp',
      element: 'filter_reset',
      elementDetail: 'jobs_search_filter',
      context,
    });

    navigate(`${location.pathname}?${search.toString()}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname, location.search]);

  return resetSearchFilters;
};
