import { useSearchAlerts } from './use-search-alerts';
import { useSearchQueryId } from './use-search-query-id';

type UseCurrentSearchAlertStatusResult = {
  searchAlertId: string | null;
  isLoading: boolean;
  isSearchIdAnAlert: (id: string) => boolean;
};

export const useCurrentSearchAlertStatus =
  (): UseCurrentSearchAlertStatusResult => {
    const { data, loading: isLoading } = useSearchAlerts();
    const searchQueryId = useSearchQueryId();

    let searchAlertId: string | null = null;
    if (
      !isLoading &&
      searchQueryId &&
      data?.viewer?.searchAlerts?.__typename === 'SearchAlertResultResponse'
    ) {
      const { collection } = data.viewer.searchAlerts;
      searchAlertId =
        collection?.find((alert) => alert?.queryId === searchQueryId)?.id ??
        null;
    }

    const isSearchIdAnAlert = (id: string): boolean => {
      if (
        data?.viewer?.searchAlerts?.__typename !== 'SearchAlertResultResponse'
      ) {
        return false;
      }

      const { collection } = data.viewer.searchAlerts;
      return !!(collection?.find((alert) => alert?.queryId === id)?.id ?? null);
    };

    return { searchAlertId, isLoading, isSearchIdAnAlert };
  };
