import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_ALERTS_MODAL_PARAM,
  SEARCH_ALERTS_MODAL_TYPES,
} from '@xing-com/crate-jobs-constants';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';
import {
  useEditSearchAlert,
  useIsAiSearch,
  useToggleSearchAlert,
} from '@xing-com/crate-jobs-hooks';
import { SearchAlertsSearchModal } from '@xing-com/crate-jobs-xinglets';
import {
  IconNotificationCenter,
  IconNotificationFilled,
} from '@xing-com/icons';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import { spaceL, spaceS, spaceXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import { AppliedFilters } from '../../shared';
import { MoreResults } from '../more-results';
import { ResultsHeader } from '../results-header';

type Props = {
  serps: SerpsRouteParams | null;
};

export const EmptyState: React.FC<Props> = ({ serps }: Props) => {
  const { isAlertActive, isLoading, onToggleAlert } = useToggleSearchAlert();
  const { isEditMode } = useEditSearchAlert();
  const { isLoggedIn } = useLoginState();
  const { setSearchParameter } = useSearchParameter();
  const isAiSearch = useIsAiSearch();

  const handleOnToggle = (): void => {
    if (!isLoggedIn) {
      setSearchParameter(
        SEARCH_ALERTS_MODAL_PARAM,
        SEARCH_ALERTS_MODAL_TYPES.CREATE
      );
      return;
    }

    onToggleAlert({
      PropTrackActionData: { PropActionOrigin: 'jobs_serp_empty_state_button' },
    });
  };

  const bodyKey = isAiSearch
    ? 'JOBS_SEARCH_AI_EMPTY_STATE_BANNER_SUBTITLE'
    : isAlertActive
      ? 'JOBS_SEARCH_EMPTY_STATE_BANNER_SEARCH_ALERT_SUBTITLE'
      : isEditMode
        ? 'JOBS_SEARCH_EMPTY_STATE_BANNER_SEARCH_ALERT_EDIT_SUBTITLE'
        : 'JOBS_SEARCH_EMPTY_STATE_BANNER_SUBTITLE';

  const textButtonKey = isAlertActive
    ? 'JOBS_SEARCH_ALERT_CREATED'
    : isEditMode
      ? 'JOBS_SEARCH_EDIT_ALERT_SAVE_BUTTON'
      : 'JOBS_CREATE_SEARCH_ALERT';

  return (
    <>
      <ResultsHeader jobCount={0} serps={serps} />
      <Body>
        <FormattedMessage id={bodyKey} />
        {isAiSearch && !isLoggedIn ? null : (
          <ButtonSA
            id="search-alert-empty-results-button"
            variant="primary"
            icon={
              isAlertActive ? IconNotificationFilled : IconNotificationCenter
            }
            loading={isLoading}
            onClick={handleOnToggle}
          >
            <FormattedMessage id={textButtonKey} />
          </ButtonSA>
        )}
      </Body>
      <MediaConfined>
        <AppliedFilters />
      </MediaConfined>
      <MoreResults />
      <SearchAlertsSearchModal />
    </>
  );
};

const MediaConfined = styled.div`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

const Body = styled(BodyCopy)`
  margin-top: -${spaceS};
  margin-bottom: ${spaceL};
  display: flex;
  gap: ${spaceXL};

  @media ${mediaTopBarVisible} {
    flex-direction: column;
  }
`;

const ButtonSA = styled(Button)`
  flex-shrink: 0;

  @media ${mediaTopBarVisible} {
    width: fit-content;
  }
`;
