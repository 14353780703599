import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';

export const useSearchConsumer = (): string => {
  const isAiSearch = useIsAiSearch();
  const { isLoggedIn } = useLoginState();

  if (isAiSearch) {
    if (isLoggedIn) {
      return 'loggedin.web.jobs.conv_search_results.center';
    }

    return 'loggedout.web.jobs.conv_search_results.center';
  }

  if (isLoggedIn) {
    return 'loggedin.web.jobs.search_results.center';
  }

  return 'loggedout.web.jobs.search_results.center';
};
