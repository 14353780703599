import { SEARCH_STATE_PARAMS } from '@xing-com/crate-jobs-constants';

import { getJobSerpsSearchQueryVariables } from './get-job-serps-search-query-variables';
import type { SerpsRouteParams } from './get-job-serps-search-query-variables';

type Result = {
  [key in (typeof SEARCH_STATE_PARAMS)[keyof typeof SEARCH_STATE_PARAMS]]?: string;
};

export const getJobsSerpsSearchParameters = (
  serps: SerpsRouteParams
): Result => {
  const { keywords, location, filter } = getJobSerpsSearchQueryVariables(serps);

  const keywordsSearchParam = keywords
    ? { [SEARCH_STATE_PARAMS.keywords]: keywords }
    : {};
  const locationSearchParam = location
    ? {
        ...(location.text && {
          [SEARCH_STATE_PARAMS.location]: location.text,
        }),
        ...(location.radius && {
          [SEARCH_STATE_PARAMS.radius]: `${location.radius}`,
        }),
      }
    : {};
  const filterSearchParam = filter
    ? {
        ...(filter.employmentType &&
          filter.employmentType.id &&
          filter.employmentType.id[0] && {
            [SEARCH_STATE_PARAMS.employmentType]: filter.employmentType.id[0],
          }),
        ...(filter.discipline &&
          filter.discipline.id &&
          filter.discipline.id[0] && {
            [SEARCH_STATE_PARAMS.discipline]: filter.discipline.id[0],
          }),
      }
    : {};

  return {
    ...keywordsSearchParam,
    ...locationSearchParam,
    ...filterSearchParam,
  };
};
