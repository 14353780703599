/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type JobSearchQueryGenerationQueryVariables = Types.Exact<{
  input: Types.FabiJobSearchQueryGenerationInput;
}>;

export type JobSearchQueryGenerationQuery = {
  __typename?: 'Query';
  fabiJobSearchQueryGeneration?:
    | { __typename?: 'FabiBadRequest'; message: string }
    | {
        __typename?: 'FabiJobSearchQueryGenerationResult';
        requestTrackingToken: string;
        enoughProfileData: boolean;
        queries: Array<{
          __typename?: 'FabiGeneratedQuery';
          query: string;
          trackingToken: string;
        } | null>;
      }
    | null;
};

export const JobSearchQueryGenerationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JobSearchQueryGeneration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'FabiJobSearchQueryGenerationInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabiJobSearchQueryGeneration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'FabiJobSearchQueryGenerationResult',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestTrackingToken' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'query' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trackingToken' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enoughProfileData' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FabiBadRequest' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobSearchQueryGenerationQuery,
  JobSearchQueryGenerationQueryVariables
>;
