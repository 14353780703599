import { useMutation } from '@apollo/client';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { CreateSearchAlertMutation } from '@xing-com/crate-jobs-graphql';
import { CreateSearchAlertDocument } from '@xing-com/crate-jobs-graphql';
import { useViewerData } from '@xing-com/hub';

import { addAlertToCachedList } from './apollo-helpers';
import type {
  PropTrackActionData,
  SearchAlertHookParams,
  UseCreateSearchAlert,
} from './types';

const createOptimisticUpdate = (
  queryId: string
): CreateSearchAlertMutation => ({
  __typename: 'Mutation',
  createSearchAlert: {
    __typename: 'SearchAlertCreationSuccess',
    alert: {
      __typename: 'SearchAlert',
      id: 'optimistic-response-id',
      globalId: 'optimistic-response-global-id',
      queryId: queryId,
      query: null,
      name: 'optimistic-response-name',
      newJobCount: null,
      hasNewJobs: null,
      createdAt: new Date().toString(),
    },
  },
});

const DEFAULT_PARAMS = {
  disableOptimisticUpdate: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError: (): void => {},
};

export const useCreateSearchAlert = (
  params?: SearchAlertHookParams
): UseCreateSearchAlert => {
  const { disableOptimisticUpdate, onError } = {
    ...DEFAULT_PARAMS,
    ...(params ?? {}),
  };

  const [createSearchAlertX1] = useMutation(CreateSearchAlertDocument);
  const userId = useViewerData().data?.user?.id;
  const { track: trackAdobeEvent } =
    useTracking<InteractionTrackingEvent<PropTrackActionData>>();

  return ({ queryId, propTrackActionData, onCompleted }) => {
    if (propTrackActionData) {
      trackAdobeEvent({
        type: 'interaction',
        event: 'PropTrackAction',
        ...propTrackActionData,
      });
    }

    if (queryId) {
      createSearchAlertX1({
        variables: { queryId },
        update: addAlertToCachedList(userId),
        optimisticResponse: disableOptimisticUpdate
          ? undefined
          : createOptimisticUpdate(queryId),
        onCompleted: (response) => {
          if (
            response.createSearchAlert?.__typename ===
            'SearchAlertErrorResponse'
          ) {
            onError();
          }

          onCompleted?.(response);
        },
        onError,
      });
    }
  };
};
