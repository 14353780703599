import type {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  Reference,
} from '@apollo/client';

import type {
  CreateSearchAlertMutation,
  DeleteSearchAlertMutation,
  CreateSearchAlertMutationVariables,
  DeleteSearchAlertMutationVariables,
  CreateSearchAlertByQueryMutationVariables,
  VisitSearchAlertMutation,
  VisitSearchAlertMutationVariables,
  CreateSearchAlertByQueryMutation,
} from '@xing-com/crate-jobs-graphql';

type DeleteMutationUpdaterFn = MutationUpdaterFunction<
  DeleteSearchAlertMutation,
  DeleteSearchAlertMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

export const deleteAlertFromCache =
  (alertId: string, userId?: string): DeleteMutationUpdaterFn =>
  (cache, { data }) => {
    if (userId && data?.deleteSearchAlert?.__typename === 'Empty') {
      const viewerRef = cache.identify({ __typename: 'Viewer', id: userId });
      const alertRef = cache.identify({
        __typename: 'SearchAlert',
        id: alertId,
      });

      // Usually there is no need to remove the reference from the collection, but it is needed for optimistic response
      cache.modify({
        id: viewerRef,
        fields: {
          searchAlerts: (existingAlertsRefs, { readField }) => {
            return {
              ...existingAlertsRefs,
              collection: existingAlertsRefs?.collection.filter(
                (alertRef: Reference) => alertId !== readField('id', alertRef)
              ),
            };
          },
        },
      });

      // We clean the Search Alert object from the cache
      // https://www.apollographql.com/docs/react/caching/garbage-collection/#dangling-references
      cache.evict({ id: alertRef });
      cache.gc();
    }
  };

type CreateMutationUpdaterFn = MutationUpdaterFunction<
  CreateSearchAlertMutation,
  CreateSearchAlertMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

type CreateByQueryMutationUpdaterFn = MutationUpdaterFunction<
  CreateSearchAlertByQueryMutation,
  CreateSearchAlertByQueryMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

const addAlertToCache = (
  cache: ApolloCache<unknown>,
  userId: string,
  alertRef?: string
): void => {
  const viewerRef = cache.identify({ __typename: 'Viewer', id: userId });
  cache.modify({
    id: viewerRef,
    fields: {
      searchAlerts: (existingAlertsRefs) => ({
        ...existingAlertsRefs,
        collection: [
          { __ref: alertRef },
          ...(existingAlertsRefs ? existingAlertsRefs.collection : []),
        ],
      }),
    },
  });
};

export const addAlertToCachedList =
  (userId?: string): CreateMutationUpdaterFn =>
  (cache, { data }) => {
    if (
      userId &&
      data?.createSearchAlert?.__typename === 'SearchAlertCreationSuccess'
    ) {
      const alertRef = cache.identify({
        __typename: 'SearchAlert',
        id: data.createSearchAlert.alert.id,
      });
      addAlertToCache(cache, userId, alertRef);
    }
  };

export const addAlertByQueryToCachedList =
  (userId?: string): CreateByQueryMutationUpdaterFn =>
  (cache, { data }) => {
    if (
      userId &&
      data?.createSearchAlertByQuery?.__typename ===
        'SearchAlertCreationSuccess'
    ) {
      const alertRef = cache.identify({
        __typename: 'SearchAlert',
        id: data.createSearchAlertByQuery.alert.id,
      });
      addAlertToCache(cache, userId, alertRef);
    }
  };

type VisitSearchAlertMutationUpdaterFn = MutationUpdaterFunction<
  VisitSearchAlertMutation,
  VisitSearchAlertMutationVariables,
  DefaultContext,
  ApolloCache<unknown>
>;

export const zeroifySearchAlertsNewJobCount =
  (alertId: string): VisitSearchAlertMutationUpdaterFn =>
  (cache, { data }) => {
    if (data?.visitSearchAlert?.__typename === 'Empty') {
      const alertRef = cache.identify({
        __typename: 'SearchAlert',
        id: alertId,
      });

      // We directly update the ref as we are storing the sa as references
      cache.modify({
        id: alertRef,
        fields: {
          newJobCount: () => 0,
          hasNewJobs: () => false,
        },
      });
    }
  };
