import type React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { Observed } from '@xing-com/crate-communication-in-view';
import { SEARCH_PAGE_SIZE } from '@xing-com/crate-jobs-constants';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';
import { useSearchTracking } from '@xing-com/crate-jobs-tracking';
import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import { mediaTopBarVisible, mediaWideNavless } from '@xing-com/layout-tokens';
import { ProgressBar } from '@xing-com/progress-bar';
import { space6XL, spaceL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import { useSearch } from '../../../hooks';
import { LimitedJobCount } from '../../shared';

const Container = styled.div`
  margin-bottom: ${space6XL};
`;

const SmallScreenLoadingIndicator = styled(DotLoadingIndicator)`
  justify-content: center;

  @media ${mediaWideNavless} {
    display: none;
  }
`;

const SmallScreenObserved = styled(Observed)`
  @media ${mediaWideNavless} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  margin-top: ${spaceL};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: ${spaceL};
`;

const ConfinedContentContainer = styled(ContentContainer)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

type LazyLoaderProps = {
  isSerps?: boolean;
};

export const LazyLoader: React.FC<LazyLoaderProps> = ({ isSerps = false }) => {
  const isAiSearch = useIsAiSearch();
  const { fetchMore, results, isLoading } = useSearch();
  const { trackFetchMore } = useSearchTracking();
  const [page, setPage] = useState(() => {
    const listLength = results?.collection.length ?? SEARCH_PAGE_SIZE;
    return Math.ceil(listLength / SEARCH_PAGE_SIZE);
  });

  const totalResults = isAiSearch ? Infinity : (results?.total ?? 0);
  const offset = SEARCH_PAGE_SIZE * page;
  const aiNoMoreResults =
    isAiSearch && (results?.collection.length ?? 0) < offset;

  if (totalResults <= offset || aiNoMoreResults) {
    return null;
  }

  const fetchMoreResults = async (): Promise<void> => {
    const results = await fetchMore({
      variables: {
        offset,
      },
      // Can't use a type policy (https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results)
      // because of this bug: https://github.com/apollographql/apollo-client/issues/10711
      // @ts-expect-error generated and fetchMore types are incompatible because of undefined cases
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        ...previousResult,
        jobSearchByQuery: {
          ...previousResult.jobSearchByQuery,
          collection: [
            ...(previousResult.jobSearchByQuery?.collection ?? []),
            ...(fetchMoreResult?.jobSearchByQuery?.collection ?? []),
          ],
        },
      }),
    });

    if (!results || !results.data.jobSearchByQuery) {
      return;
    }

    trackFetchMore({ page: page + 1, results: results.data.jobSearchByQuery });
    setPage(page + 1);
  };

  const handleOnLeave = (): void => {
    // do nothing
  };

  const SmallScreenComponents = isLoading ? (
    <SmallScreenLoadingIndicator />
  ) : (
    <SmallScreenObserved onEnter={fetchMoreResults} onLeave={handleOnLeave} />
  );

  const ContentContainerComponent = isSerps
    ? ContentContainer
    : ConfinedContentContainer;

  return (
    <Container>
      {!isSerps ? SmallScreenComponents : null}
      <ContentContainerComponent>
        {!isAiSearch ? (
          <div>
            <BodyCopy>
              <FormattedMessage
                id="JOBS_SEARCH_PAGINATION_TEXT"
                values={{
                  results: offset,
                  total: <LimitedJobCount count={totalResults} />,
                }}
              />
            </BodyCopy>
            <ProgressBar
              progress={(offset / totalResults) * 100}
              showLabel={false}
            />
          </div>
        ) : null}
        <Button
          onClick={fetchMoreResults}
          variant="secondary"
          loading={isLoading}
        >
          <FormattedMessage id="JOBS_SEARCH_PAGINATION_LOAD_MORE" />
        </Button>
      </ContentContainerComponent>
    </Container>
  );
};
