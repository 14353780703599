import { Checkbox } from '@xing-com/checkbox';
import type {
  CountOptions,
  FilterParamName,
  Option,
} from '@xing-com/crate-jobs-domain-search-types';
import type { RemoteOptionAggregationFragment } from '@xing-com/crate-jobs-graphql';
import { Tag } from '@xing-com/tag';
import { BodyCopy } from '@xing-com/typography';

import { LimitedJobCount } from '../../shared';
import * as S from './filter-item.styles';

type Props = {
  paramName: FilterParamName;
  option: NonNullable<Option>;
  active: boolean;
  onClick: (id: string) => void;
  maxWidth?: string;
};

const isRemoteOption = (
  option: Exclude<Option, undefined | null>
): option is RemoteOptionAggregationFragment =>
  option.__typename === 'JobAggregationRemoteOption';

const FilterCheckbox: React.FC<Props> = ({
  paramName,
  option,
  active,
  onClick,
}) => {
  const handleOnClick = (): void => {
    onClick(option.id);
  };

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const count = (option as CountOptions).count;

  return (
    <S.CheckboxItemContainer>
      <Checkbox on={active} onChange={handleOnClick} />
      <BodyCopy size="small" noMargin>
        {/* @ts-expect-error The paramName is hardcoded to the option type, meaning we know the index is correct */}
        {option[paramName]?.localizationValue}
        {count >= 0 ? (
          <>
            {' '}
            (<LimitedJobCount count={count} />)
          </>
        ) : null}
      </BodyCopy>
    </S.CheckboxItemContainer>
  );
};

const FilterTag: React.FC<Props> = ({
  paramName,
  option,
  active,
  onClick,
  maxWidth,
}) => {
  const isRemoteTag = isRemoteOption(option);

  const handleOnClick = (): void => {
    onClick(option.id);
  };

  return (
    <Tag
      selected={active}
      onClick={handleOnClick}
      role="checkbox"
      aria-checked={active}
      maxWidth={maxWidth}
    >
      {/* @ts-expect-error Providing complete type-safe access is really complex for this case*/}
      {option[paramName]?.localizationValue}
      {isRemoteTag || option.count < 0 ? null : (
        <>
          {' '}
          (<LimitedJobCount count={option.count} />)
        </>
      )}
    </Tag>
  );
};

type FilterItemProps = {
  asCheckbox: boolean;
} & Props;

export const FilterItem: React.FC<FilterItemProps> = ({
  asCheckbox,
  ...props
}) => (asCheckbox ? <FilterCheckbox {...props} /> : <FilterTag {...props} />);
