import type {
  Aggregations,
  FilterResult,
} from '@xing-com/crate-jobs-domain-search-types';

// Conversational search only supports a subset of filters
const removeUnavailable =
  (isAiSearch: boolean) =>
  (filter: FilterResult | 'salary'): boolean =>
    !isAiSearch || filter === 'salary' || !!filter.isAiCompatible;

export const getFiltersFromAggregations = (
  {
    remoteOptions,
    employmentTypes,
    careerLevels,
    disciplines,
    industries,
    benefitsEmployeePerk,
    benefitsWorkingCulture,
    countries,
    cities,
  }: Aggregations,
  isAiSearch: boolean
): Array<'salary' | FilterResult> =>
  (
    [
      {
        options: remoteOptions,
        titleKey: 'JOBS_SEARCH_FILTER_REMOTE_OPTION',
        paramName: 'remoteOption',
        propertyName: 'remoteOption',
        isAiCompatible: true,
      },
      {
        options: employmentTypes,
        titleKey: 'JOBS_SEARCH_FILTER_EMPLOYMENT_TYPE',
        paramName: 'employmentType',
        propertyName: 'employmentType',
        isAiCompatible: true,
      },
      {
        options: careerLevels,
        titleKey: 'JOBS_SEARCH_FILTER_CAREER_LEVEL',
        paramName: 'careerLevel',
        propertyName: 'careerLevel',
        isAiCompatible: true,
      },
      {
        options: benefitsWorkingCulture,
        titleKey: 'JOBS_SEARCH_FILTER_WORKING_ATMOSPHERE',
        paramName: 'benefit',
        propertyName: 'benefitWorkingCulture',
      },
      {
        options: disciplines,
        titleKey: 'JOBS_SEARCH_FILTER_DISCIPLINE',
        paramName: 'discipline',
        propertyName: 'discipline',
      },
      'salary',
      {
        options: industries,
        titleKey: 'JOBS_SEARCH_FILTER_INDUSTRY',
        paramName: 'industry',
        propertyName: 'industry',
      },
      {
        options: benefitsEmployeePerk,
        titleKey: 'JOBS_SEARCH_FILTER_EMPLOYEE_PERKS',
        paramName: 'benefit',
        propertyName: 'benefitEmployeePerk',
      },
      {
        options: countries,
        titleKey: 'JOBS_SEARCH_FILTER_COUNTRY',
        paramName: 'country',
        propertyName: 'country',
      },
      {
        options: cities,
        titleKey: 'JOBS_SEARCH_FILTER_CITY',
        paramName: 'city',
        propertyName: 'city',
      },
    ] satisfies Array<'salary' | FilterResult>
  ).filter(removeUnavailable(isAiSearch));
