import { useQuery } from '@apollo/client';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { GetSearchAlertsDocument } from '@xing-com/crate-jobs-graphql';

import type { UseSearchAlertsValue } from './types';

export const useSearchAlerts = (): UseSearchAlertsValue => {
  const { isLoggedIn } = useLoginState();
  const queryResult = useQuery(GetSearchAlertsDocument, {
    skip: !isLoggedIn,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  return queryResult;
};
