import styled from 'styled-components';

import { Card as BaseCard } from '@xing-com/card';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceXL, spaceXXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Card = styled(BaseCard)`
  margin: ${spaceXL} 0;

  @media ${mediaConfined} {
    margin: ${spaceXXL} 0;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spaceL};

  @media ${mediaConfined} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @media ${mediaConfined} {
    flex-direction: row;
    gap: 62px;
    justify-content: flex-end;
  }
`;

export const Text = styled(BodyCopy)`
  flex-shrink: 0;
`;

export const SadFeedbackContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${spaceL};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: 0;
  }
`;
