import { FormattedMessage } from 'react-intl';

import type { JobFilterSalary } from '@xing-com/crate-common-graphql-types';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  MAX_SALARY,
  MIN_SALARY,
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_PARAMS,
} from '@xing-com/crate-jobs-constants';
import { DeleteTag } from '@xing-com/tag';

import { SalaryValue } from '../../salary-value';

type Props = {
  option: JobFilterSalary & { isExtracted?: boolean };
};

export const SalaryTag: React.FC<Props> = ({ option }) => {
  const { setSearchParameter, deleteSearchParameter } = useSearchParameter();

  const handleOnClickSalary = (): void => {
    // Extracted filter
    if (option.isExtracted) {
      const disabledParamName = `${SEARCH_STATE_PARAMS.salary}${SEARCH_STATE_DISABLED_SUFFIX}`;
      setSearchParameter(disabledParamName, 'true', { replace: false });

      return;
    }

    // Normal filter
    deleteSearchParameter(SEARCH_STATE_PARAMS.salary, { replace: false });
  };

  return (
    <DeleteTag
      onClick={handleOnClickSalary}
      onDelete={handleOnClickSalary}
      selected
    >
      <FormattedMessage id="JOBS_SEARCH_FILTER_SALARY" />{' '}
      <SalaryValue
        min={option.min ?? MIN_SALARY}
        max={option.max ?? MAX_SALARY}
      />
    </DeleteTag>
  );
};
