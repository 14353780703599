import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  ORIGIN_PARAM,
  ORIGIN_PARAM_VALUES,
} from '@xing-com/crate-jobs-constants';
import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useExperiment } from '@xing-com/hub';
import { IconArrowLeft } from '@xing-com/icons';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';

import { useBackButtonTrack } from './back-to-classic-search-button';

const Button = styled(TextButton)`
  justify-content: start;

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const BackButton: React.FC = () => {
  const { isLoggedOut } = useLoginState();
  const { getSearchParameter } = useSearchParameter();
  const fromSearchAlerts =
    getSearchParameter(ORIGIN_PARAM) === ORIGIN_PARAM_VALUES.searchAlerts;
  const isAiSearch = useIsAiSearch();
  const track = useBackButtonTrack();
  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  const startpage = isLoggedOut ? '/' : ROUTES.findJobs;

  if (isAiSearch && !isAiSearchForced) {
    return (
      <Button
        to={`${startpage}?sc_o=jobs_search_serp_classic_search`}
        icon={IconArrowLeft}
        onClick={track}
      >
        <FormattedMessage id="JOBS_SEARCH_AI_BACK_TO_CLASSIC" />
      </Button>
    );
  }

  if (fromSearchAlerts) {
    return (
      <Button to={ROUTES.myJobsSearchAlerts} icon={IconArrowLeft}>
        <FormattedMessage id="JOBS_MYJOBS_SEARCH_ALERTS_SECTION_TITLE" />
      </Button>
    );
  }

  return (
    <Button to={startpage} icon={IconArrowLeft}>
      <FormattedMessage id="JOBS_SEARCH_BUTTON_BACK" />
    </Button>
  );
};
