import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceXXL } from '@xing-com/tokens';

import { AppliedFilters as BaseAppliedFilters } from '../../shared';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  gap: ${spaceXXL};
  width: 100%;
  /** Add extra padding to ensure all filters are visible when one of the bottom banners is visible **/
  padding-bottom: 60px;
`;

export const AppliedFilters = styled(BaseAppliedFilters)`
  && {
    @media ${mediaWideNavless} {
      display: none;
    }
  }
`;
