import type { SVGProps } from 'react';
import styled from 'styled-components';

import { scale150 } from '@xing-com/tokens';

const ColorOnHoverContainer = styled.div<{ $forceActive?: boolean }>`
  height: ${scale150};
  width: ${scale150};
  position: relative;
  cursor: ${({ $forceActive }) => ($forceActive ? 'default' : 'pointer')};
  pointer-events: ${({ $forceActive }) => ($forceActive ? 'none' : 'auto')};

  & .active {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ $forceActive }) => ($forceActive ? 1 : 0)};
  }

  &:hover .active {
    opacity: 1;
  }
`;

type Illustration = (props: SVGProps<SVGSVGElement>) => JSX.Element;
export type FeedbackVariant = 'like' | 'neutral' | 'dislike';
type Props = {
  Default: Illustration;
  Active: Illustration;
  variant: FeedbackVariant;
  forceActive?: boolean;
  onClick: (feedback: FeedbackVariant) => void;
};

export const ColorFeedback: React.FC<Props> = ({
  Default,
  Active,
  variant,
  forceActive,
  onClick,
}) => {
  const handleOnClick = (): void => {
    onClick(variant);
  };

  return (
    <ColorOnHoverContainer
      aria-label={`feedback-${variant}`}
      role="button"
      $forceActive={forceActive}
      onClick={handleOnClick}
    >
      <Default width={42} height={42} />
      <Active width={42} height={42} className="active" />
    </ColorOnHoverContainer>
  );
};
