import { FormattedMessage } from 'react-intl';

import type { JobFilterLocation } from '@xing-com/crate-common-graphql-types';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_PARAMS,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';
import { DeleteTag } from '@xing-com/tag';

type Props = {
  option: JobFilterLocation & { isExtracted?: boolean };
};

export const ExtractedLocationTag: React.FC<Props> = ({ option }) => {
  const { getSearchParameter, setSearchParameter } = useSearchParameter();

  const location = option.location?.localizationValue;
  const radius = option.location?.radius;

  if (!location) return null;

  const handleOnExtractedLocation = (): void => {
    if (option.isExtracted) {
      const disabledParamName = `${SEARCH_STATE_PARAMS.location}${SEARCH_STATE_DISABLED_SUFFIX}`;
      const current = getSearchParameter(disabledParamName);

      if (!current) {
        setSearchParameter(disabledParamName, location, { replace: false });
      } else {
        const values = new Set(current.split(SEARCH_STATE_SEPARATOR));

        if (values.has(location)) {
          return;
        }

        values.add(location);
        setSearchParameter(
          disabledParamName,
          [...values].join(SEARCH_STATE_SEPARATOR),
          {
            replace: false,
          }
        );
      }
    }
  };

  return (
    <DeleteTag
      onClick={handleOnExtractedLocation}
      onDelete={handleOnExtractedLocation}
      selected
    >
      <FormattedMessage id="JOBS_SEARCH_FILTER_LOCATION_TEXT" />
      {` ${location}${radius ? ` (${radius} km)` : ''}`}
    </DeleteTag>
  );
};
