import styled from 'styled-components';

import { ContentBanner as BaseContentBanner } from '@xing-com/banner';
import { Button } from '@xing-com/button';
import { IllustrationStickerRocket } from '@xing-com/illustrations';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWide,
} from '@xing-com/layout-tokens';
import {
  cornerRadiusL,
  scale150,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
  spaceXXL,
  xdlColorCardHero01,
} from '@xing-com/tokens';
import {
  BodyCopy as BodyCopyComponent,
  Headline as HeadlineComponent,
} from '@xing-com/typography';

export const ContentBanner = styled(BaseContentBanner)`
  align-items: normal;
  border-radius: ${cornerRadiusL};
  flex-direction: column;
  padding: ${spaceXL} ${spaceL};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

export const InfoContainer = styled.div`
  flex: 1;
`;

export const Headline = styled(HeadlineComponent)`
  margin-bottom: ${spaceM};
`;

export const RecruiterInfoContainer = styled.div`
  display: flex;
  gap: ${spaceXXL};
  margin-bottom: ${spaceL};

  @media ${mediaWide} {
    margin-bottom: 0;
  }
`;

export const RecruiterInfoCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${mediaWide} {
    align-items: center;
    flex-direction: row;
  }
`;

export const RecruiterInfoCardCopy = styled(BodyCopyComponent)`
  margin: 0;

  @media ${mediaWide} {
    margin-left: ${spaceS};
  }
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;

  @media ${mediaConfined} {
    flex: 0.5;
    justify-content: flex-end;
  }
`;

export const ContainerBoostMeUp = styled.div`
  @media ${mediaConfined} {
    display: flex;
    flex-direction: row;
  }
`;

export const ContentBannerBoostMeUp = styled(BaseContentBanner)`
  align-items: normal;
  border-radius: ${cornerRadiusL};
  flex-direction: column;
  padding: ${spaceXL};

  @media (prefers-color-scheme: light) {
    background-color: ${xdlColorCardHero01};
  }

  @media ${mediaSmallOrTiny} {
    padding: ${spaceXL};
  }
`;

export const ContainerBoostMeUpText = styled.div`
  @media ${mediaConfined} {
    margin-left: ${spaceM};
  }
`;

export const HeadlineBoostMeUp = styled(HeadlineComponent)`
  margin-bottom: ${spaceXS};
`;

export const ParagraphBoostMeUp = styled(BodyCopyComponent)`
  margin-bottom: ${spaceXS};
`;

export const SentenceBoostMeUp = styled(HeadlineComponent)`
  margin-bottom: 0;

  @media ${mediaSmallOrTiny} {
    margin-bottom: ${spaceXL};
  }
`;

export const ActionBoostMeUp = styled(Button)`
  width: 100%;
  @media ${mediaConfined} {
    padding: 0 ${scale150};
    width: fit-content;
    margin-left: ${spaceM};
    align-self: center;
    white-space: nowrap;
  }
`;

export const DesktopIllustration = styled(IllustrationStickerRocket)`
  display: none;
  @media ${mediaConfined} {
    display: block;
  }
`;

export const MobileIllustration = styled(IllustrationStickerRocket)`
  display: block;
  margin-bottom: ${spaceM};
  @media ${mediaConfined} {
    display: none;
  }
`;
