/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const SingleSizeIllustrationFeedbackDefaultHappy = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 42"
    data-xds="SingleSizeIllustrationFeedbackDefaultHappy"
    role="presentation"
    {...props}
  >
    <g clipPath="url(#clip0_10198_359)">
      <path
        fill="var(--xdlColorIllustrationFillTertiaryGrayPlain)"
        fillRule="evenodd"
        d="M42.001 21c0 11.598-9.403 21-21 21C9.404 42 0 32.598 0 21S9.403 0 21.002 0C32.597 0 42 9.402 42 21"
        clipRule="evenodd"
      />
      <path
        fill="#1D2124"
        d="M26.64 13.309a.75.75 0 0 1 .744.648l.007.102v1.833a.75.75 0 0 1-1.493.101l-.007-.101v-1.833a.75.75 0 0 1 .75-.75M16.065 1.95c3.367.598 5.567 3.264 5.635 7.678l.016 1.234c.024 2.14.026 4.395.014 6.602l-.018 2.307-.012 1.05a.75.75 0 0 1-1.5-.022l.018-1.704c.024-2.706.028-5.554-.002-8.217L20.2 9.651c-.057-3.705-1.747-5.753-4.397-6.224-2.24-.398-4.766.506-5.572 1.854a.75.75 0 1 1-1.288-.77c1.155-1.931 4.312-3.06 7.122-2.56m-.778 11.359a.75.75 0 0 1 .743.648l.007.102v1.833a.75.75 0 0 1-1.493.101l-.007-.101v-1.833a.75.75 0 0 1 .75-.75m-2.03 11.693a.75.75 0 0 1 1.061 0c3.67 3.671 9.622 3.671 13.293 0a.75.75 0 0 1 1.061 1.06c-4.257 4.258-11.159 4.258-15.415 0a.75.75 0 0 1 0-1.06"
      />
    </g>
    <defs>
      <clipPath id="clip0_10198_359">
        <path fill="#fff" d="M0 0h42v42H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SingleSizeIllustrationFeedbackDefaultHappy;
