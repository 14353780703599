import type { Maybe } from '@xing-com/crate-common-graphql-types';
import type {
  JobTeaserVisibleJobFragment,
  JobItemResultFragment,
} from '@xing-com/crate-jobs-graphql';

export type JobItemResultVisibleJob = JobItemResultFragment & {
  jobDetail: JobTeaserVisibleJobFragment;
};

export const isVisibleJob = (
  item: Maybe<JobItemResultFragment>
): item is JobItemResultVisibleJob => {
  return item?.jobDetail?.__typename === 'VisibleJob';
};
