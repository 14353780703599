/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotJobSearch = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotJobSearch"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="m36.63 18.495-.04-4.111a9.022 9.022 0 1 0-18.042.175l.03 3.16-4.49.043-.032-3.236C13.984 7.064 19.974.955 27.436.883 34.9.81 41.009 6.802 41.082 14.265l.04 4.186z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m53.782 55.636-51.585.537-.38-37.321 51.584-.538z"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="m37.514 39.008-19.412.188-.097-9.922 19.413-.19z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M54.773 59.85c-6.308 0-11.421-5.113-11.421-11.42 0-6.309 5.113-11.422 11.42-11.422 6.309 0 11.422 5.113 11.422 11.421S61.08 59.85 54.773 59.85"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="m54.39 17.303.01 1h.001v.076l.18 17.63h.192c6.86 0 12.42 5.56 12.42 12.42s-5.56 12.421-12.42 12.421c-3.07 0-5.88-1.113-8.047-2.958l-8.934 8.933a1 1 0 0 1-1.498-1.32l.084-.094 8.683-8.686-43.854.458-.401-39.32zm.383 20.705c-5.756 0-10.421 4.666-10.421 10.421 0 5.756 4.665 10.421 10.42 10.421 5.757 0 10.422-4.665 10.422-10.42 0-5.756-4.665-10.422-10.421-10.422M52.49 27.103c-2.287 4.521-6.501 7.43-11.951 7.593l-.323.006-24.901.241c-5.603.055-9.989-2.775-12.411-7.318l.281 27.537 40.884-.425a12.37 12.37 0 0 1-1.718-6.308c0-6.113 4.415-11.194 10.23-12.228zm-.104-7.78-49.537.519c.445 7.683 5.148 13.013 12.133 13.1l.314.001 24.9-.24c7.152-.07 11.89-5.563 12.19-13.38"
      />
      <path
        fill="#B7E854"
        d="M59.315 42.474a1 1 0 0 1 1.413 0 8.37 8.37 0 0 1 2.467 5.956 8.38 8.38 0 0 1-2.467 5.955 1 1 0 0 1-1.413-1.413 6.38 6.38 0 0 0 1.881-4.543c0-1.717-.667-3.329-1.88-4.541a1 1 0 0 1-.001-1.414"
      />
    </svg>
  );
};
export default IllustrationSpotJobSearch;
