/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetJobRecommendationsQueryVariables = Types.Exact<{
  consumer: Types.Scalars['String']['input'];
  first: Types.Scalars['Int']['input'];
  exclude?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >;
}>;

export type GetJobRecommendationsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    jobRecommendations?: {
      __typename?: 'JobRecommenderConnection';
      edges: Array<{
        __typename?: 'JobRecommendationEdge';
        node: {
          __typename?: 'JobRecommendation';
          reason: Array<string>;
          position: number;
          trackingToken: string;
          isDeleted?: boolean | null;
          job?:
            | { __typename?: 'UnauthorizedJob' }
            | {
                __typename?: 'VisibleJob';
                id: string;
                slug: string;
                url: string;
                title: string;
                activatedAt?: string | null;
                refreshedAt: string;
                globalId: string;
                userInteractions?: {
                  __typename?: 'JobUserInteractions';
                  bookmark?: {
                    __typename?: 'JobBookmark';
                    state: Types.JobBookmarkState;
                  } | null;
                } | null;
                location?: {
                  __typename?: 'JobLocation';
                  city?: string | null;
                } | null;
                employmentType?: {
                  __typename?: 'EmploymentType';
                  localizationValue: string;
                } | null;
                companyInfo: {
                  __typename?: 'JobCompanyInfo';
                  companyNameOverride: string;
                  company?: {
                    __typename?: 'Company';
                    id: string;
                    logos?: {
                      __typename?: 'CompanyLogos';
                      x1?: string | null;
                    } | null;
                    kununuData?: {
                      __typename?: 'CompanyKununuData';
                      ratingAverage?: number | null;
                    } | null;
                  } | null;
                };
                salary?:
                  | {
                      __typename?: 'Salary';
                      currency?: Types.Currency | null;
                      amount?: number | null;
                    }
                  | {
                      __typename?: 'SalaryEstimate';
                      currency?: Types.Currency | null;
                      minimum?: number | null;
                      maximum?: number | null;
                      median?: number | null;
                    }
                  | {
                      __typename?: 'SalaryRange';
                      currency?: Types.Currency | null;
                      minimum?: number | null;
                      maximum?: number | null;
                    }
                  | { __typename?: 'SalaryUnauthorized' }
                  | null;
              }
            | null;
          matchingHighlightsV2?: {
            __typename?: 'JobMatchingHighlightsV2';
            token: string;
            highlight?: {
              __typename?: 'JobMatchingHighlightsJobHighlightV2';
              type: string;
              localization: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              };
              localizationA11y?: {
                __typename?: 'JobMatchingHighlightsLocalizableV2';
                localizationValue: string;
              } | null;
            } | null;
            matchingFacts: Array<
              | {
                  __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
                  type: string;
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                }
              | {
                  __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
                  type: string;
                  value:
                    | {
                        __typename?: 'Salary';
                        currency?: Types.Currency | null;
                        amount?: number | null;
                      }
                    | {
                        __typename?: 'SalaryEstimate';
                        currency?: Types.Currency | null;
                        minimum?: number | null;
                        maximum?: number | null;
                        median?: number | null;
                      }
                    | {
                        __typename?: 'SalaryRange';
                        currency?: Types.Currency | null;
                        minimum?: number | null;
                        maximum?: number | null;
                      }
                    | { __typename?: 'SalaryUnauthorized' };
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                }
              | {
                  __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
                  type: string;
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                }
            >;
            nonMatchingFacts: Array<
              | {
                  __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
                  type: string;
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                }
              | {
                  __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
                  type: string;
                  value:
                    | {
                        __typename?: 'Salary';
                        currency?: Types.Currency | null;
                        amount?: number | null;
                      }
                    | {
                        __typename?: 'SalaryEstimate';
                        currency?: Types.Currency | null;
                        minimum?: number | null;
                        maximum?: number | null;
                        median?: number | null;
                      }
                    | {
                        __typename?: 'SalaryRange';
                        currency?: Types.Currency | null;
                        minimum?: number | null;
                        maximum?: number | null;
                      }
                    | { __typename?: 'SalaryUnauthorized' };
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                }
              | {
                  __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
                  type: string;
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                }
            >;
          } | null;
        };
      }>;
    } | null;
  } | null;
};

export const GetJobRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consumer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exclude' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobRecommendations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'consumer' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeNew' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'exclude' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'exclude' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'JobRecommendationEdge',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Salary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Salary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryRange' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryRange' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryEstimate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryEstimate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'median' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisibleJobCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameOverride' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'x1' },
                              name: { kind: 'Name', value: 'logo96px' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Salary' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryRange' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryEstimate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'VisibleJobCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userInteractions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmark' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobKeyfactV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsJobKeyfactV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localizationA11y' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'JobMatchingHighlightsJobKeyfactSalaryV2',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Salary' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryRange' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryEstimate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationA11y' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMatchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobRecommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isDeleted' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingHighlightsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobRecommendationEdge' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobRecommendationEdge' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobRecommendation' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobRecommendationsQuery,
  GetJobRecommendationsQueryVariables
>;
