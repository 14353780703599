import {
  ANY,
  CITY,
  COMPANY,
  DISCIPLINE,
  EMPLOYMENT_TYPE,
  ROLE,
  SKILL,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type { SerpsCategoryName } from '@xing-com/crate-jobs-domain-serps-constants';
import type { JobSearchByQueryQueryVariables } from '@xing-com/crate-jobs-graphql';

import {
  companySlugToLocalizedString,
  deslugify,
  disciplineSlugToId,
  employmentTypeSlugToId,
  skillSlugToLocalizedString,
} from './parse-static-serps-data';

const DEFAULT_RADIUS = 20;

export type SerpsRoutePathParams = {
  categoryValue: string;
  subcategoryValue?: string;
};

export type SerpsRouteParams = {
  category: SerpsCategoryName;
  subcategory?: SerpsCategoryName;
} & SerpsRoutePathParams;

export const getJobSerpsSearchQueryVariables = ({
  category,
  categoryValue,
  subcategory,
  subcategoryValue,
}: SerpsRouteParams): JobSearchByQueryQueryVariables['query'] => {
  const filter = {
    ...(category === DISCIPLINE && {
      discipline: { id: [disciplineSlugToId(categoryValue)] },
    }),
    ...(subcategory === DISCIPLINE &&
      subcategoryValue && {
        discipline: { id: [disciplineSlugToId(subcategoryValue)] },
      }),
    ...(category === EMPLOYMENT_TYPE && {
      employmentType: { id: [employmentTypeSlugToId(categoryValue)] },
    }),
    ...(subcategory === EMPLOYMENT_TYPE &&
      subcategoryValue && {
        employmentType: { id: [employmentTypeSlugToId(subcategoryValue)] },
      }),
  };

  return {
    ...(category === CITY && {
      location: { text: deslugify(categoryValue), radius: DEFAULT_RADIUS },
    }),
    ...(subcategory === CITY &&
      subcategoryValue && {
        location: { text: deslugify(subcategoryValue), radius: DEFAULT_RADIUS },
      }),
    ...(category === ROLE && { keywords: deslugify(categoryValue) }),
    ...(category === COMPANY && {
      keywords: companySlugToLocalizedString(categoryValue),
    }),
    ...(category === SKILL && {
      keywords: skillSlugToLocalizedString(categoryValue),
    }),
    ...(category === ANY && { keywords: deslugify(categoryValue) }),
    ...(subcategory === ROLE &&
      subcategoryValue && { keywords: deslugify(subcategoryValue) }),
    ...(Object.values(filter).some((value) => value !== undefined) && {
      filter,
    }),
  };
};
