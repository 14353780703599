import type React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import type { Maybe } from '@xing-com/crate-common-graphql-types';
import {
  useJobsNwtTracking,
  useSearchTracking,
} from '@xing-com/crate-jobs-tracking';
import { useExperiment } from '@xing-com/hub';
import { Headline } from '@xing-com/typography';

import * as S from './projobs-upsell-banner.styles';

const LINK_TO_SURVEY =
  'https://survey2.quantilope.com/f2nYPQqkDQoKqyj4m/cykFfRgvvShv5GaCd';

type RecruiterInfoCardProps = {
  headline: string;
  copy: string;
};

const RecruiterInfoCard: React.FC<RecruiterInfoCardProps> = ({
  headline,
  copy,
}) => {
  return (
    <S.RecruiterInfoCard>
      <Headline size="xxlarge" sizeWide="xxxlarge" noMargin>
        <FormattedMessage id={headline} />
      </Headline>
      <S.RecruiterInfoCardCopy size="small" sizeWide="medium">
        <FormattedMessage id={copy} />
      </S.RecruiterInfoCardCopy>
    </S.RecruiterInfoCard>
  );
};

type ContentBannerBoostMeUpComponentProps = {
  className?: string;
};

const ContentBannerBoostMeUpComponent: React.FC<
  ContentBannerBoostMeUpComponentProps
> = (className) => {
  const { trackClickBoostMeUp } = useSearchTracking();
  const trackNwt = useJobsNwtTracking();

  useEffect(() => {
    trackNwt({
      event: 'entered_viewport',
      element: 'boost_me_up_now',
      eventSchema: 'searchresult',
      page: 'jobs_search/serp',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ContentBannerBoostMeUp
      // @ts-expect-error FIXME: SC6
      className={className}
      variant="proJobs"
      display="inline"
    >
      <S.ContainerBoostMeUp>
        <S.DesktopIllustration size="medium" />
        <S.MobileIllustration size="small" />
        <S.ContainerBoostMeUpText>
          <S.HeadlineBoostMeUp size="xlarge">
            <FormattedMessage id="JOB_SEARCH_BOOST_ME_UP_HEADLINE" />
          </S.HeadlineBoostMeUp>
          <S.ParagraphBoostMeUp size="medium">
            <FormattedMessage id="JOB_SEARCH_BOOST_ME_UP_PARAGRAPH" />
          </S.ParagraphBoostMeUp>
          <S.SentenceBoostMeUp size="small">
            <FormattedMessage id="JOB_SEARCH_BOOST_ME_UP_SENTENCE" />
          </S.SentenceBoostMeUp>
        </S.ContainerBoostMeUpText>
        <S.ActionBoostMeUp
          size="medium"
          variant="secondary"
          href={LINK_TO_SURVEY}
          onClick={trackClickBoostMeUp}
        >
          <FormattedMessage id="JOB_SEARCH_BOOST_ME_UP_CTA" />
        </S.ActionBoostMeUp>
      </S.ContainerBoostMeUp>
    </S.ContentBannerBoostMeUp>
  );
};

type ProJobsUpsellBannerProps = {
  className?: string;
  isBasic?: Maybe<boolean>;
};

export const ProJobsUpsellBanner: React.FC<ProJobsUpsellBannerProps> = ({
  isBasic,
  className,
}) => {
  const isBoostMeUp = useExperiment('ABACUS-452') === 'B' || false;

  return isBoostMeUp ? (
    <ContentBannerBoostMeUpComponent className={className} />
  ) : (
    <S.ContentBanner className={className} variant="proJobs" display="inline">
      <S.ContentContainer>
        <S.InfoContainer>
          <S.Headline sizeWide="large">
            <FormattedMessage id="JOB_SEARCH_PROJOBS_UPSELL_HEADLINE" />
          </S.Headline>
          <S.RecruiterInfoContainer>
            <RecruiterInfoCard
              headline={
                !isBasic
                  ? 'JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_VISITS_FACTOR'
                  : 'JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_BASIC_VISITS_FACTOR'
              }
              copy="JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_VISITS_TEXT"
            />
            <RecruiterInfoCard
              headline={
                !isBasic
                  ? 'JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_MESSAGES_FACTOR'
                  : 'JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_BASIC_MESSAGES_FACTOR'
              }
              copy="JOB_SEARCH_PROJOBS_UPSELL_RECRUITER_MESSAGES_TEXT"
            />
          </S.RecruiterInfoContainer>
        </S.InfoContainer>
        <S.ButtonContainer>
          <Button
            variant="proJobs"
            size="small"
            sizeWide="medium"
            to="/upsell/pro_jobs_offers?reagent=uplt_963"
          >
            <FormattedMessage id="JOB_SEARCH_PROJOBS_UPSELL_CTA" />
          </Button>
        </S.ButtonContainer>
      </S.ContentContainer>
    </S.ContentBanner>
  );
};
