import { useEffect, useRef } from 'react';

import type { PageViewTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';

import type { NwtTrackingProps, OmitFromUnion } from './use-jobs-nwt-tracking';
import { useJobsNwtTracking } from './use-jobs-nwt-tracking';

export type PageviewTrackingProps = {
  channel: string;
  page: string;
  PropJobsSearchIds?: string;
  PropTrackAction?: string;
  PropSearchTerm?: string;
  PropSearchCity?: string;
  PropSearchResultAmount?: number;
  PropSearchListFilter?: string;
  PropSortOrder?: string;
  PropPagination?: number;
  PropContextDimension1?: string;
  PropContextDimension2?: string;
  // JDP
  PropJobsId?: string;
  PropContextDimension3?: string;
  PropContextDimension5?: string;
  PropJobsDiscipline?: string;
  PropModules?: string;
};

type UsePageTrackingResult = {
  trackPageview: (props: PageviewTrackingProps) => void;
};
type UsePageTrackingArgs = {
  nwtOptions?: OmitFromUnion<NwtTrackingProps, 'eventSchema' | 'event'> & {
    eventSchema?: 'aicomponent';
  };
  repeatTracking?: boolean;
};

export const usePageTracking = ({
  nwtOptions,
  repeatTracking = false,
}: UsePageTrackingArgs): UsePageTrackingResult => {
  const isTracked = useRef(false);
  const { track } = useTracking<PageViewTrackingEvent<PageviewTrackingProps>>();
  const trackNwt = useJobsNwtTracking();

  useEffect(() => {
    if (nwtOptions?.page) {
      trackNwt({ eventSchema: 'basic', event: 'viewed_screen', ...nwtOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackNwt]);

  const trackPageview: UsePageTrackingResult['trackPageview'] = (props) => {
    const trackProps = { type: 'pageview', ...props } as const;

    if (repeatTracking) {
      track(trackProps);
      return;
    }

    if (!isTracked.current) {
      track(trackProps);
      isTracked.current = true;
    }
  };

  return { trackPageview };
};
