import { JobTeaserListItemSkeleton } from '@xing-com/crate-jobs-xinglets';

import * as S from './more-results.styles';

export const MoreResultsSkeleton: React.FC = () => {
  return (
    <S.Container>
      <S.HeadlineSkeleton size="small" amountRows={1} />
      {Array.from({ length: 20 }, (_, i) => (
        <JobTeaserListItemSkeleton key={i} />
      ))}
    </S.Container>
  );
};
