import { SEARCH_COUNT_LOCAL_STORAGE } from '@xing-com/crate-jobs-constants';

const getSearchCountEntries = (): Record<string, number> | undefined => {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }

  const entriesAsString = localStorage.getItem(SEARCH_COUNT_LOCAL_STORAGE);
  return entriesAsString ? JSON.parse(entriesAsString) : {};
};

export const increaseSearchCount = (id: string): number => {
  const entries = getSearchCountEntries();
  if (!entries) {
    return 0;
  }

  entries[id] = (entries[id] ?? 0) + 1;
  localStorage.setItem(SEARCH_COUNT_LOCAL_STORAGE, JSON.stringify(entries));

  return entries[id];
};

/**
 * Returns the times a search has been executed given their ID
 *
 * @returns `undefined` if the localStorage is not accessible, the count
 * otherwise
 */
export const getSearchCount = (id: string): number | undefined => {
  const entries = getSearchCountEntries();
  if (!entries) {
    return;
  }

  return entries[id] ?? 0;
};
