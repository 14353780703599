import { FormattedNumber } from 'react-intl';

type Props = {
  count: number;
};

const MAX_JOB_COUNT = 1_000_000;

// This helper hides the count if bigger than 1_000_000,
// to prevent the disclosure of our real job inventory count (product decision)
export const LimitedJobCount: React.FC<Props> = ({ count }) => {
  return count > MAX_JOB_COUNT ? (
    <>
      <FormattedNumber value={MAX_JOB_COUNT} />+
    </>
  ) : (
    <FormattedNumber value={count} />
  );
};
