import {
  SEARCH_STATE_PARAMS,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';
import type {
  JobSearchFilterCollectionSplitBenefitsFragment,
  JobSearchQueryBodySplitBenefitsFragment,
} from '@xing-com/crate-jobs-graphql';
import { unscrambleStringId } from '@xing-com/crate-utils-x1-data';

const trackingFilterConfig = {
  JobFilterEmploymentType: { property: 'employmentType', trackingName: 'type' },
  JobFilterCareerLevel: { property: 'careerLevel', trackingName: 'level' },
  JobFilterDiscipline: { property: 'discipline', trackingName: 'discipline' },
  JobFilterIndustry: { property: 'industry', trackingName: 'industry' },
  JobFilterBenefitEmployeePerk: {
    property: 'benefitEmployeePerk',
    trackingName: 'perks',
  },
  JobFilterBenefitWorkingCulture: {
    property: 'benefitWorkingCulture',
    trackingName: 'atmosphere',
  },
  JobFilterCountry: { property: 'country', trackingName: 'country' },
  JobFilterCity: { property: 'city', trackingName: 'city' },
  JobFilterRemoteOption: { property: 'remoteOption', trackingName: 'remote' },
} as const;

const filterCollectionReducer = (
  acc: string[],
  filter: JobSearchFilterCollectionSplitBenefitsFragment | null
): string[] => {
  if (!filter?.__typename) {
    return acc;
  }

  if (filter.__typename === 'JobFilterCompany') {
    if (!acc.includes('companies')) {
      return [...acc, 'companies'];
    }

    return acc;
  }

  if (
    filter.__typename === 'JobFilterSalary' &&
    typeof filter.min === 'number' &&
    typeof filter.max === 'number'
  ) {
    return [...acc, `salary_${filter.min}`, `salary_${filter.max}`];
  }

  if ('entityId' in filter) {
    const { property, trackingName } = trackingFilterConfig[filter.__typename];

    if (property in filter) {
      // @ts-expect-error Unable to type this correctly, but it is safe
      const filterTrackingCopy = filter[property]?.localizationValue;
      return [...acc, `${trackingName}_${filterTrackingCopy}`];
    }
  }

  return acc;
};

export const getTrackingFilters = (
  collection: JobSearchQueryBodySplitBenefitsFragment['filterCollection']
): string | undefined => {
  const filters = collection?.reduce(filterCollectionReducer, []);

  return filters && filters.length > 0 ? filters.join(';') : undefined;
};

export const getNwtCleanQuery = (query: URLSearchParams): string => {
  const cleanParams = new URLSearchParams();

  query.forEach((value, key) => {
    if (key in SEARCH_STATE_PARAMS) {
      const unscrambledValue = value
        .split(SEARCH_STATE_SEPARATOR)
        .map(unscrambleStringId)
        .join(',');

      cleanParams.set(key, unscrambledValue);
    }
  });

  // can't use cleanParams.size as it's available yet in jsdom
  if (Array.from(cleanParams).length === 0) return '';

  return `?${cleanParams.toString()}`;
};
