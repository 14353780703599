import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { useExperiment } from '@xing-com/hub';
import { IconReturn } from '@xing-com/icons';
import { mediaTopBarHidden } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: ${spaceL};
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;

export const useBackButtonTrack = (): (() => void) => {
  const trackNwt = useJobsNwtTracking();

  const track = (): void => {
    trackNwt({
      event: 'opened',
      eventSchema: 'basic',
      page: 'jobs_search/serp',
      element: 'classic_search',
    });
  };

  return track;
};

export const BackToClassicSearchButton: React.FC = () => {
  const { isLoggedOut } = useLoginState();
  const track = useBackButtonTrack();

  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';
  if (isAiSearchForced) {
    return null;
  }

  const startpage = isLoggedOut ? '/' : ROUTES.findJobs;

  return (
    <ButtonContainer>
      <Button
        variant="tertiary"
        to={`${startpage}?sc_o=jobs_search_serp_classic_search`}
        icon={IconReturn}
        onClick={track}
      >
        <FormattedMessage id="JOBS_SEARCH_AI_BACK_TO_CLASSIC" />
      </Button>
    </ButtonContainer>
  );
};
