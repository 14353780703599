/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const SingleSizeIllustrationFeedbackActiveSad = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 42"
    data-xds="SingleSizeIllustrationFeedbackActiveSad"
    role="presentation"
    {...props}
  >
    <g clipPath="url(#clip0_864_207)">
      <path
        fill="#FE9A95"
        fillRule="evenodd"
        d="M42 21c0 11.598-9.403 21-21 21C9.402 42 0 32.598 0 21S9.402 0 21 0c11.597 0 21 9.402 21 21"
        clipRule="evenodd"
      />
      <path
        fill="#161C24"
        d="M26.44 13.309a.75.75 0 0 1 .742.648l.007.102v1.833a.75.75 0 0 1-1.493.101l-.007-.101v-1.833a.75.75 0 0 1 .75-.75M9.56 3.989c1.508.365 2.758.469 4.572.426l.814-.027 1.266-.055.48-.015.43-.006.385.004c1.032.023 1.584.172 2.206.544 1.236.74 1.772 2.224 1.785 4.776l.01 2.854.001 3.312-.011 5.011a.75.75 0 0 1-1.494.095l-.006-.114.01-3.334.001-3.32q0-.829-.002-1.647l-.009-2.85c-.01-2.081-.374-3.088-1.056-3.496a2 2 0 0 0-.666-.25l-.214-.035-.244-.025-.279-.016-.317-.006-.362.002-.412.01-.722.027-.27.013c-2.675.128-4.245.065-6.249-.421a.75.75 0 0 1 .354-1.458m5.524 9.32a.75.75 0 0 1 .743.648l.007.102v1.833a.75.75 0 0 1-1.493.101l-.007-.101v-1.833a.75.75 0 0 1 .75-.75m13.022 10.905a.75.75 0 1 1-.01 1.5 14.6 14.6 0 0 0-10.387 4.221.75.75 0 1 1-1.053-1.067 16.1 16.1 0 0 1 11.45-4.654"
      />
    </g>
    <defs>
      <clipPath id="clip0_864_207">
        <path fill="#fff" d="M0 0h42v42H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SingleSizeIllustrationFeedbackActiveSad;
