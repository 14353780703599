import { useMutation } from '@apollo/client';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { DeleteSearchAlertMutation } from '@xing-com/crate-jobs-graphql';
import { DeleteSearchAlertDocument } from '@xing-com/crate-jobs-graphql';
import { useViewerData } from '@xing-com/hub';

import { deleteAlertFromCache } from './apollo-helpers';
import type {
  PropTrackActionData,
  UseDeleteSearchAlert,
  SearchAlertHookParams,
} from './types';

const deleteOptimisticUpdate: DeleteSearchAlertMutation = {
  __typename: 'Mutation',
  deleteSearchAlert: {
    __typename: 'Empty',
  },
};

const DEFAULT_PARAMS = {
  disableOptimisticUpdate: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError: (): void => {},
};

export const useDeleteSearchAlert = (
  params?: SearchAlertHookParams
): UseDeleteSearchAlert => {
  const { disableOptimisticUpdate, onError } = {
    ...DEFAULT_PARAMS,
    ...(params ?? {}),
  };

  const [deleteSearchAlertX1] = useMutation(DeleteSearchAlertDocument);
  const userId = useViewerData().data?.user?.id;
  const { track: trackAdobeEvent } =
    useTracking<InteractionTrackingEvent<PropTrackActionData>>();

  return ({ alertId, propTrackActionData, onCompleted, showError = true }) => {
    if (propTrackActionData) {
      trackAdobeEvent({
        type: 'interaction',
        event: 'PropTrackAction',
        ...propTrackActionData,
      });
    }

    deleteSearchAlertX1({
      variables: { id: alertId },
      update: deleteAlertFromCache(alertId, userId),
      optimisticResponse: disableOptimisticUpdate
        ? undefined
        : deleteOptimisticUpdate,
      onCompleted: (response) => {
        if (
          showError &&
          response.deleteSearchAlert?.__typename === 'SearchAlertErrorResponse'
        ) {
          onError();
        }

        onCompleted?.(response);
      },
      onError: () => {
        if (showError) {
          onError();
        }
      },
    });
  };
};
