import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { IconCross, IconWarning } from '@xing-com/icons';

import * as S from './error-banner.styles';

type Props = { show?: boolean; onClose: () => void };

const Base: React.FC<Props> = ({ show, onClose }) =>
  ReactDOM.createPortal(
    <S.Banner $show={show}>
      <S.Content>
        <S.Icon>
          <IconWarning />
        </S.Icon>
        <S.Text size="small">
          <FormattedMessage id="JOBS_SEARCH_AI_SUGGESTION_SERVER_ERROR" />
        </S.Text>
      </S.Content>
      <S.Close
        type="button"
        data-testid="close"
        icon={IconCross}
        onClick={onClose}
        size="medium"
      />
    </S.Banner>,
    document.body
  );

export const ErrorBanner: React.FC<Props> = (props) => (
  <NoSSR>
    <Base {...props} />
  </NoSSR>
);
