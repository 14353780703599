import { useLocation, useNavigate } from '@reach/router';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ContentBanner } from '@xing-com/banner';
import type { LoSearchAlertMessage } from '@xing-com/crate-common-graphql-types';
import { SEARCH_ALERTS_EMAIL_BANNER_STATE } from '@xing-com/crate-jobs-constants';
import { IconNotificationFilled } from '@xing-com/icons';
import { spaceS, spaceXL, spaceXS } from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

const Banner = styled(ContentBanner)`
  margin: ${spaceXL} 0;
  padding: ${spaceXL};
  padding-right: 40px;
`;

const Content = styled.div<{ $titleOnly?: boolean }>`
  display: flex;
  align-items: ${({ $titleOnly }) => ($titleOnly ? 'center' : 'flex-start')};
  gap: ${spaceS};
`;

const TextWrapper = styled.div<{ $titleOnly?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $titleOnly }) => ($titleOnly ? 0 : spaceXS)};
`;

const isSearchAlertsEmailBannerState = (
  state: unknown
): state is { [SEARCH_ALERTS_EMAIL_BANNER_STATE]: LoSearchAlertMessage } => {
  if (!state) {
    return false;
  }

  if (
    typeof state !== 'object' ||
    !(SEARCH_ALERTS_EMAIL_BANNER_STATE in state)
  ) {
    return false;
  }

  return (
    typeof state[SEARCH_ALERTS_EMAIL_BANNER_STATE] === 'object' &&
    !!state[SEARCH_ALERTS_EMAIL_BANNER_STATE] &&
    '__typename' in state[SEARCH_ALERTS_EMAIL_BANNER_STATE] &&
    state[SEARCH_ALERTS_EMAIL_BANNER_STATE].__typename ===
      'LOSearchAlertMessage'
  );
};

export const SearchAlertsEmailBanner: React.FC = () => {
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<LoSearchAlertMessage>();

  useEffect(() => {
    if (!isSearchAlertsEmailBannerState(state)) {
      return;
    }

    if (state[SEARCH_ALERTS_EMAIL_BANNER_STATE]) {
      // We need to save the message to React state, so we can clean the Router state
      // The clean is needed to not keep the state between refreshes
      setMessage(state[SEARCH_ALERTS_EMAIL_BANNER_STATE]);
      navigate(`${pathname}${search}`, {
        state: undefined,
        replace: true,
      });
    }
  }, [state, navigate, pathname, search]);

  const onClose = (): void => {
    setMessage(undefined);
  };

  return (
    <Banner
      show={!!message?.title}
      display="inline"
      handleOnClose={onClose}
      data-testid="search-alerts-email-banner"
    >
      <Content $titleOnly={!message?.body}>
        <IconNotificationFilled width={24} height={24} />
        <TextWrapper $titleOnly={!message?.body}>
          <Headline noMargin>{message?.title}</Headline>
          <BodyCopy as="div" size="small" noMargin>
            {/* BE response contains some HTML elements */
            /* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: message?.body || '' }} />
          </BodyCopy>
        </TextWrapper>
      </Content>
    </Banner>
  );
};
