import { SearchBox } from '@xing-com/crate-jobs-domain-search-components-search-box';
import { useSearchBar } from '@xing-com/crate-jobs-hooks';

export const SearchSearchBar: React.FC = () => {
  const { keywords, location, ...result } = useSearchBar();

  return (
    <SearchBox
      keywordsInput={keywords}
      locationInput={location}
      onSelectKeywords={result.onChangeKeywords}
      condensedOnMobile
      {...result}
    />
  );
};
