import { useCallback, useMemo } from 'react';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFilterSet = (filter: string) => {
  const searchParams = useSearchParameter();
  const { getSearchParameter, setSearchParameter, deleteSearchParameter } =
    searchParams;

  const valueEnabled = getSearchParameter(filter) ?? '';
  const value = useMemo(
    () =>
      new Set(
        valueEnabled.length === 0
          ? []
          : valueEnabled.split(SEARCH_STATE_SEPARATOR).sort()
      ),
    [valueEnabled]
  );

  const valueDisabled =
    getSearchParameter(`${filter}${SEARCH_STATE_DISABLED_SUFFIX}`) ?? '';
  const disabled = useMemo(
    () =>
      new Set(
        valueDisabled.length === 0
          ? []
          : valueDisabled.split(SEARCH_STATE_SEPARATOR).sort()
      ),
    [valueDisabled]
  );

  const handleOnToggleOption = useCallback(
    (option: string): void => {
      const current = getSearchParameter(filter);
      if (!current) {
        setSearchParameter(filter, option, { replace: false });
        return;
      }

      const values = new Set(current.split(SEARCH_STATE_SEPARATOR));
      if (values.has(option)) {
        values.delete(option);
      } else {
        values.add(option);
      }

      if (values.size === 0) {
        deleteSearchParameter(filter, { replace: false });
        return;
      }
      setSearchParameter(filter, [...values].join(SEARCH_STATE_SEPARATOR), {
        replace: false,
      });
    },
    [deleteSearchParameter, filter, getSearchParameter, setSearchParameter]
  );

  return useMemo(
    () => ({
      value,
      disabled,
      onToggleOption: handleOnToggleOption,
    }),
    [value, disabled, handleOnToggleOption]
  );
};
