import { useMutation } from '@apollo/client';

import { TrackAdClickByIntIdDocument } from '../track-ad-click-by-int-id-mutation.gql-types';
import { TrackAdImpressionByIntIdDocument } from '../track-ad-impression-by-int-id-mutation.gql-types';

type AdsTrackType = (
  id: number,
  trackingToken: string,
  scrambledId?: string // only if isLoggedOut
) => void;

export type UseTrackAdType = {
  trackClick: AdsTrackType;
  trackImpression: AdsTrackType;
};

export const useAdsTracking = (): UseTrackAdType => {
  const [trackAdClickByIntId, { error: trackAdClickError }] = useMutation(
    TrackAdClickByIntIdDocument
  );
  const [trackAdImpressionByIntId, { error: trackAdImpressionError }] =
    useMutation(TrackAdImpressionByIntIdDocument);

  const trackClick: AdsTrackType = (id, trackingToken, scrambledId) => {
    if (trackAdClickError) {
      throw new Error(trackAdClickError.message);
    }

    trackAdClickByIntId({
      variables: {
        input: {
          id,
          token: trackingToken,
          scrambledId,
        },
      },
    });
  };

  const trackImpression: AdsTrackType = (id, trackingToken, scrambledId) => {
    if (trackAdImpressionError) {
      throw new Error(trackAdImpressionError.message);
    }

    trackAdImpressionByIntId({
      variables: {
        input: {
          id,
          token: trackingToken,
          scrambledId,
        },
      },
    });
  };

  return {
    trackClick,
    trackImpression,
  };
};
