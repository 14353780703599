import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Carousel as BaseCarousel } from '@xing-com/carousel';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceS } from '@xing-com/tokens';

export const Wrapper = styled.div<{ className?: string }>`
  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const Container = styled.div<{ $isAiSearch: boolean }>`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 0;
  margin-bottom: ${spaceS};

  ${({ $isAiSearch }) =>
    $isAiSearch &&
    css`
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: ${spaceS};
    `}
`;

export const CarouselWrapper = styled.div`
  overflow: hidden;
  /* Using arbitrary values, which look good on expected viewports */
  mask-image: linear-gradient(to right, transparent 4px, black 10px);
`;

export const Carousel = styled(BaseCarousel)`
  padding-left: ${spaceS};
`;

export const ResetButton = styled(TextButton)`
  margin-right: 20px;
`;
