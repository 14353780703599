/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { useTimedCallback } from './use-timed-callback';

const TYPE_SPEED_MS = 38;
const THREE_DOTS_SPEED = 450;

export const useLoadingPlaceholder = (
  ref: React.RefObject<HTMLTextAreaElement>
) => {
  const { formatMessage } = useIntl();
  const indexRef = useRef(0);
  const { run, cancel } = useTimedCallback({ speed: TYPE_SPEED_MS });

  const message = formatMessage({ id: 'JOBS_SEARCH_AI_LOADING_SUGGESTION' });

  const type = (): void => {
    // If there is a value, we cut the setTimeout chain
    if (ref.current?.value) {
      return;
    }

    const newPlaceholder = message.slice(0, indexRef.current);
    ref.current?.setAttribute('placeholder', newPlaceholder);

    if (indexRef.current >= message.length - 3) {
      indexRef.current =
        indexRef.current === message.length
          ? message.length - 3
          : indexRef.current + 1;
      run(type, { delay: THREE_DOTS_SPEED });
    } else {
      ++indexRef.current;
      run(type);
    }
  };

  return { run: type, cancel };
};
