import { useMemo } from 'react';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_STATE_DISABLED_SUFFIX,
  SEARCH_STATE_SEPARATOR,
} from '@xing-com/crate-jobs-constants';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFilter = (filter: string) => {
  const { getSearchParameter, setSearchParameter, deleteSearchParameter } =
    useSearchParameter();
  const value = getSearchParameter(filter);

  const valueDisabled =
    getSearchParameter(`${filter}${SEARCH_STATE_DISABLED_SUFFIX}`) ?? '';
  const disabled = useMemo(
    () =>
      new Set(
        valueDisabled.length === 0
          ? []
          : valueDisabled.split(SEARCH_STATE_SEPARATOR).sort()
      ),
    [valueDisabled]
  );

  const handleOnChange = (nextValue: string): void => {
    if (nextValue === '') {
      deleteSearchParameter(filter, { replace: false });
    } else {
      setSearchParameter(filter, nextValue, { replace: false });
    }
  };

  return { value, disabled, onChange: handleOnChange };
};
